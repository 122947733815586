import React from 'react';
import { useParams, useHistory } from 'react-router';
import '../components/courseView/courseView.css';
import VideoPlayer from '../components/videoPlayer/VideoPlayer';
import ClassInfo from '../components/classInfo/ClassInfo';
import ClassNavContent from '../components/classNavContent/ClassNavContent';
import ReactLoading from 'react-loading';
// import config from '../config';
// import $ from 'jquery';
// import Swal from 'sweetalert2'
import getInfoClass from "../services/getInfoClass.js";
import addLastViewed from "../services/addViewedCourse.js";
import '../assets/css/courseView.css';
import ButtonObtenerCertificado from '../components/ButtonObtenerCertificado';
import ButtonObtenerDenthereum from '../components/ButtonObtenerDenthereum';
import { Helmet } from 'react-helmet';

const CourseView = (props) => {

  let idUsuario = localStorage.getItem('idUsuario');
  const { nombre_curso, id_clase } = useParams()
  const history = useHistory()
  const url = window.location.href;

  const[temario, setTemario] = React.useState([]);
  const[dataClase, setDataClase] = React.useState([]);
  const[urlCurso, setCurso] = React.useState([]);
  const[claseActual, setClaseActual] = React.useState([]);
  const[descripcion, setDescripcion] = React.useState([]);
  const[coments, setComentarios] = React.useState([]);
  const[materiales, setMateriales] = React.useState([]);
  const[image, setImage] = React.useState([]);
  const[loading, setLoading] = React.useState(true);
  const[idCurso, setIdCurso] = React.useState(null)
  
  // Estado donde manejaremos lo de las recompensas del token
  const [derechoRecompensaCurso, setDerechoRecompensa] = React.useState(false)
  const [obtuvoRecompensa, setObtuvoRecompensa] = React.useState(false)
  const [numeroTokensObtenidos, setNumeroTokensObtenidos] = React.useState(false)
  const [numeroCursosFaltantesDeMembresia, setNumeroCursosFaltantesDeMembresia] = React.useState(10);
  const [cursoFinalizado, setCursoFinalizado] = React.useState(false)
  const [esCursoGratis, setEsCursoGratis] = React.useState(false)
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true)
    getClassData();
    setCurso(nombre_curso);
    setClaseActual(id_clase);
  } , [url]);

  function getClassData(){

    let values = new FormData();
    values.append("curso",nombre_curso);
    values.append("clase",id_clase);
    values.append("idUsuario",idUsuario);
    let data = Object.fromEntries(values);

    getInfoClass({values:data}).then((response) => {

      // console.log(response)

      let _response = JSON.parse(response);

      if(_response.response === "error"){
        // Significa que no tiene el curso inscrito y debemos redireccionarlo a sus cursos
        history.push('/mis-cursos')
        // Swal.fire(
        //     'Ocurrió un error',
        //     'Revisar la conexión a internet e inténtalo de nuevo.',
        //     'error'
        // )
      } else {

        // Variables para los tokens
        setDerechoRecompensa(_response.datos.token.tieneDerechoRecompensaTokens)
        setObtuvoRecompensa(_response.datos.token.reclamoRecompensaAnteriormente)
        setNumeroTokensObtenidos(_response.datos.token.numeroTokensReclamadosAnteriormente)
        setNumeroCursosFaltantesDeMembresia(_response.datos.token.numeroCursosFaltantesDeMembresia)
        setCursoFinalizado(_response.datos.curso[0].finalizado)
        setEsCursoGratis(_response.datos.curso[0].esGratis)
        // let titulo = document.getElementById("tituloWeb");
        // titulo.innerHTML = _response.datos.clase[0].curso+" | Cursodontic";

        setTemario(_response.datos.temario);
        setDataClase(_response.datos.clase);
        setDescripcion(_response.datos.curso);
        setComentarios(_response.datos.comentarios);
        setMateriales(_response.datos.productos);
        setIdCurso(_response.datos.curso[0].idCurso);
        setImage(_response.datos.curso[0].imagenCurso);
        setLoading(false);

        addLastViewedCourse(_response.datos.curso[0].idCurso);
      }
    }).catch((error) => {
      // Swal.fire(
      //     'Error del servidor',
      //     'Inténtalo de nuevo en unos momentos.',
      //     'error'
      // )
      console.log({error})
    })

  }

  function addLastViewedCourse(idCurso) {
    let formData = new FormData();

    formData.append('idUsuario', idUsuario);
    formData.append('idCurso', idCurso);
    formData.append("accion", 'insertarCursoVisto');

    addLastViewed(formData)
    .then((responseAdd) => {
      let _respuestaAdd = JSON.parse(responseAdd);
      console.log('_respuestaAdd => ',_respuestaAdd);
    })
  }

  return (
    <div>
      
      { loading ?
        <div className="circularBarProgressIndicator">
          <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem"/>
        </div>
        :
        <div className="row m-0">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`${dataClase[0].curso} | Cursodontic`}</title>
            <meta name="description" content={`Curso de ${dataClase[0].curso}`} />

            <meta property="og:title" content={`${dataClase[0].curso} | Cursodontic`} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={image} />
            <meta property="og:url" content="https://cursodontic.com" />

            <meta property="og:description" content={`Curso de ${dataClase[0].curso}`} />
            <meta property="og:site_name" content={`${dataClase[0].curso} | Cursodontic`} />
            <meta name="twitter:image:alt" content="Cursodontic" />
          </Helmet>

          <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 px-0 shadow-sm">
  
            {
              dataClase && dataClase.map(
                video => (
                  <VideoPlayer key={claseActual} resolutions={video.resoluciones} idClase={video.idClase} idUsuario={idUsuario} thumbnail={image}/>
                )
              )
            }

            {
              dataClase && dataClase.map(
                clases => (
                  <ClassInfo 
                    key={claseActual} 
                    temario={temario.length} 
                    claseActual={clases.claseActual} 
                    curso={clases.curso} 
                    nombreClase={clases.nombre} 
                    url={urlCurso+"/"}
                    numberOfClasses={temario.length}
                  />
                )
              )
            }

            <div className={`d-flex justify-content-around mb-2`}>

              {/* Si ya finalizo el curso, puede obtener su certificado */}
              { cursoFinalizado && !esCursoGratis && <ButtonObtenerCertificado /> }

              {/* Si ya finalizo el curso, tiene derecho a su recompensa y no ha obtenido recompensa 
              anteriormente, mostrar el boton para obtenerlo */}
              { cursoFinalizado && !esCursoGratis &&
                <ButtonObtenerDenthereum 
                  obtuvoRecompensa={obtuvoRecompensa} 
                  derechoRecompensaCurso={derechoRecompensaCurso}
                  numeroCursosFaltantesDeMembresia={numeroCursosFaltantesDeMembresia}
                  numeroTokensObtenidos={numeroTokensObtenidos}
                  esCursoGratis={esCursoGratis}
                  idCurso={idCurso}
                  idUsuario={idUsuario}
                  setIngresoTokens={props.setIngresoTokens}
                  ingresoTokens={props.ingresoTokens}
                /> 
              }

            </div>

          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 class-nav-content">
            <ClassNavContent 
              clase={dataClase.map(clase => (
                clase.idClase
              ))} 
              fotoUsuario={dataClase.map(clase => (
                clase.imgUsuario
              ))} 
              comentarios={coments} 
              materiales={materiales}
              descripcion={descripcion} 
              temario={temario} 
              claseActual={claseActual} 
              urlCurso={urlCurso+"/"}
            />
              
          </div>

        </div>
      }
    </div>
  )
}

export default CourseView;