import React from 'react';
import { Link } from 'react-router-dom';
import './Denthereum.css';

import Logo from '../../assets/images/LogoDenthereumAmarillo.png';
import Logo2 from '../../assets/images/BasicLogoDenthereumAmarillo.png';

import YT from '../../assets/images/YouTube.png';
import IG from '../../assets/images/Instagram.png';
import IN from '../../assets/images/Linkedin.png';
import FB from '../../assets/images/Facebook.png';

function Denthereum(props){
    return(

        <div className={`container ${props.isDarkMode && 'footer-darkMode noPadding-xs'}`}>
            
            <div className="denthereumContent">

                <div>
                    <img src={Logo} alt="Denthereum" height="40" />
                    <span className="denthereumContent_titulo">Denthereum</span>
                </div>

                <p>Denthereum es una criptomoneda desarrollada para <b>recompenzar tu aprendizaje</b> en nuestra plataforma: <b>Cursodontic</b></p>

                <hr />

                <div className="denthereumContent_compra">

                    <div>
                        <img src={Logo2} alt="Denthereum" height="30" />
                        <span className="denthereumContent_usdt">
                            = $1 <small>USD</small>
                        </span>
                    </div>

                    <Link to='/denthereum/comprar'>
                        <button type="button" className="btn btn-light denthereumContent_compraBtn">
                            Comprar Denthereum <i className="fas fa-hand-point-right"></i>
                        </button>
                    </Link>

                </div>

                <hr />

                <div className="denthereumContentInfo">

                    <div className="denthereumContent_aboutUs">
                        <b>Sobre nosotros</b>
                        <Link to='/denthereum'>
                            <p>Denthereum</p>
                        </Link>
                        <p>Términos y condiciones</p>
                    </div>

                    <div className="denthereumContent_help">
                        <b>Ayuda</b>
                        <p>Solución de problemas</p>
                    </div>

                    <div className="denthereumContent_explora">
                        <b>Explora</b>
                        <Link to='/cursos'>
                            <p>Cursos online</p>
                        </Link>
                        <Link to='/marketplace'>
                            <p>Marketplace (tienda en línea)</p>
                        </Link>
                        <Link to='/membresia'>
                            <p>Membresía premium</p>
                        </Link>
                    </div>

                </div>

                <hr />

                <div className="denthereumContent_logos">

                    <a href='https://youtube.com/cursodontic' target="_blank">
                        <img src={YT} alt="Denthereum" height="50" />
                    </a>

                    <a href='https://instagram.com/cursodontic' target="_blank">
                        <img src={IG} alt="Denthereum" height="50" />
                    </a>

                    <a href='https://www.linkedin.com/company/cursodontic/' target="_blank">
                        <img src={IN} alt="Denthereum" height="50" />
                    </a>

                    <a href='https://facebook.com/cursodontic' target="_blank">
                        <img src={FB} alt="Denthereum" height="50" />
                    </a>

                </div>

                <hr />

            </div>

        </div>
    );
}

export {Denthereum};