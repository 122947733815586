import React, { useState, useEffect } from 'react';

import './CardCursoCompra.css';

import Logo from '../../assets/images/LogoDenthereumAmarillo.png';

import {ComprarAhora} from '../ComprarAhora/ComprarAhora.js';

import getLastClass from '../../services/getLastClass';

import VideoPlayer from '../../components/videoPlayer/VideoPlayer';

function CardCursoCompra ({ imagenCurso, precio, descuento, divisa, cursoComprado, nombreCursoUrl, idCurso, nombreCurso, proyectoVidello, setLogin, setModalLogin, accesoMembresia}){
    
    let idUsuario = localStorage.getItem('idUsuario');
    let statusMembresia = localStorage.getItem('statusMembresia');

    const [dentherumAmount, setDentherumAmount] = useState();
    
    let dentherumMxn = 0.082;
    let dentherumUsd = 1.73;

    const [lastClass, setLastClass] = useState(1);
    
    useEffect(() => {
        getLastCourseData();
        if(divisa === 'MXN') {
            setDentherumAmount((dentherumMxn*precio).toFixed(3));
        } else {
            setDentherumAmount((dentherumUsd*precio).toFixed(3));
        }
    })

    function getLastCourseData() {

        let formData = new FormData();

        formData.append('idUsuario', idUsuario);
        formData.append('idCurso', idCurso);
    
        getLastClass(formData).then(response => {
          let _respuesta = JSON.parse(response);
          if(_respuesta.response === 'success'){
            setLastClass(_respuesta.lastClass);
          }
        })
        .catch(error => {
            console.log(error);
        })
    }

    return (
        <div className="cardCursoCompra">

            {/* <img className="img-fluid cardCursoCompra-img" src={ imagenCurso } alt="imagen"/> */}
            <VideoPlayer key={''} resolutions={proyectoVidello} light={imagenCurso}/>

            <div className="bg-white p-3 cardCursoCompra-contenido text-center">

                {   
                    // Significa que es un curso gratis
                    precio === 0 &&
                        <div className="d-flex align-items-center justify-content-center">
                            <span className="cardCursoCompra-moneda">
                                GRATIS
                            </span>
                        </div>
                }

                {
                    precio !== 0 && statusMembresia !== '1' &&
                        <div className="d-flex align-items-center justify-content-center">
                            {/* <img src={Logo} height="30" alt="logo" />
                            <span className="cardCursoCompra-cripto mx-2">
                                { dentherumAmount }
                            </span> */}
                            <span className="cardCursoCompra-moneda">
                                {`$${parseFloat(precio).toFixed(2)}`}
                                <small>
                                    {divisa}
                                </small>
                            </span>
                            {
                                parseFloat(descuento) !== 0 &&
                                    <span className="cardCursoCompra-moneda--anterior d-flex mx-2">
                                        Antes:&nbsp;
                                        <span>
                                            {` $${(parseFloat(precio)/(1 -parseFloat(descuento))).toFixed(0)} ${divisa}`}
                                        </span>
                                    </span>
                            }
                        </div>
                }

                {
                    parseFloat(descuento) !== 0 && statusMembresia !== '1' &&
                        <div className="my-2 cardCursoCompra-descuento">
                            <i className="fa fa-hourglass"></i>
                            <span className="mx-2">{`Con ${parseFloat(descuento)*100}% desc. por tiempo limitado`}</span>
                        </div>
                }

                <ComprarAhora 
                    clase={lastClass}
                    cursoComprado={cursoComprado}
                    nombreCurso={nombreCursoUrl}
                    isCurso={true}
                    idCurso={idCurso}
                    isMixto={false}
                    nombreArticulo={nombreCurso}
                    imagen={imagenCurso}
                    isProduct={false}
                    setLogin={setLogin}
                    precio={precio}
                    setModalLogin={setModalLogin}
                    accesoMembresia={accesoMembresia}
                />

            </div>

        </div>
    );
}

export {CardCursoCompra};