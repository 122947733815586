import React from 'react';
import './HeaderChatbot.css';

import avatarPrometeo from './../../assets/images/prometeo.png'

export default function HeaderChatbot(){

    return(

        <header className="container-fluid headerChatbot sticky-top shadow">

            <div className="headerChatbot__infoChatbot">

                <div className='d-flex align-items-center'>
                    <img src={avatarPrometeo} className="infoChatbot__avatarChatbot" />

                    <div className="infoChatbot__datosChatbot">

                        <span className="datosChatbot__nombreBot">Prometeo</span>

                        <p className="datosChatbot__nombreEmpresa">Tu asistente dental inteligente (si, es un gatito)</p>

                    </div>
                </div>

                <button id="btnClosePrometeo" onClick={()=> window.history.go(-1)}>
                    X
                </button>

            </div>

        </header>
    )
}