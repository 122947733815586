import React, {useEffect } from "react";
import InicioDenthereum from "../components/InicioDenthereum"
import SubtituloDenthereum from "../components/SubtituloDenthereum"
import QueEsperarDenthereum from '../components/QueEsperarDenthereum'
import QuePuedoHacerDenthereum from '../components/QuePuedoHacerDenthereum';
import AprendeYGanaDenthereum from '../components/AprendeYGanaDenthereum';
import GuiaRapidaDenthereum from '../components/GuiaRapidaDenthereum';
import { MetodosPago } from './../components/MetodosPago/MetodosPago'
import { Denthereum } from './../components/Denthereum/Denthereum'

import "./../assets/css/PaginaPrincipal.css"
import { Helmet } from "react-helmet-async";

export default function DenthereumLanding() {

  useEffect(() => {
    // let titulo = document.getElementById("tituloWeb");
    // titulo.innerHTML = 'Cursodontic | Denthereum';
    window.scrollTo(0, 0);
  }, []);


  return (

    <React.Fragment>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Denthereum | Cursodontic</title>
        <meta name="description" content="La economía del conocimiento dental ahora en una criptomoneda: Denthereum" />

        <meta property="og:title" content="Denthereum | Cursodontic" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
        <meta property="og:url" content="https://cursodontic.com" />

        <meta property="og:description" content="La economía del conocimiento dental ahora en una criptomoneda: Denthereum" />
        <meta property="og:site_name" content="Denthereum | Cursodontic" />
        <meta name="twitter:image:alt" content="Cursodontic" />
      </Helmet>

      <InicioDenthereum />

      <QueEsperarDenthereum />

      <QuePuedoHacerDenthereum />

      <AprendeYGanaDenthereum />

      <GuiaRapidaDenthereum />

      <hr />

      <Denthereum />

    </React.Fragment>

  )
}

