import React from 'react';
import { Link } from 'react-router-dom';
import './Descripcion.css';

function Descripcion({ descripcionCurso }){
    return(
        <div className="infoCursoDescripcion">

            <p className="bebas mt-4 titulos">DESCRIPCIÓN</p>

            {/* <p>Aprende como crear protectores bucales personalizados para tus pacientes deportistas <i className="fa fa-futbol"></i>.</p> */}

            {/* <p>En el curso veras tecnicas sobre como hacer los diferentes protectores de bajo, mediano y alto impacto, completamente personalizados para la necesidad de tu cliente.</p> */}

            {/* <p>Te hablamos sobre los materiales a utilizar, donde conseguirlos y tambien como hacer protectores para pacientes con ortodoncia.</p> */}
            <p>{ descripcionCurso }</p>
            
            <p>El curso puedes tomarlo a tu propio ritmo, es 100% online y puedes interactuar con el instructor por medio de la seccion de comentarios.</p>

            <p>
                Al terminar el curso ganarás en criptomonedas <b>+1 Denthereum</b>
            </p>
            <Link to="/denthereum" className="infoCursoDescripcion-textVerde">¿Que es Denthereum?</Link>

        </div>
    );
}

export {Descripcion};