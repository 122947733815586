import React from 'react';

export default function PreguntaFrecuente({ pregunta, respuesta, index }) {
    return(
        <div className="card preguntas__card col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 rounded">
            <div className="card-header border-0" id={`heading${index}`}>
                <button className="btn btn-link text-reset txt-rojo btn-block text-center preguntas__botonCard" type="button" data-toggle="collapse" data-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                    { pregunta }
                    <i className="fas fa-chevron-down"></i>
                </button>
            </div>

            <div id={`collapse${index}`} className="collapse" aria-labelledby={`heading${index}`} data-parent="#preguntas__elementos">
                <div className="card-body">
                    { respuesta }
                </div>
            </div>
        </div>
    )
}