import $ from 'jquery';
import config from '../config';
const URL_API = config.urlApi+'controladores/calificarCurso.app.php';

export default function addCourseReview(values){
    return $.ajax({
        data: values,
        url: URL_API,
        type: "POST",
        contentType: false,
        processData: false,
    })
}