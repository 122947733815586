import pdfCertificado from './../../pdf.pdf';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

export default async function PDFGeneratorCertificate({nombre, curso, horas, dia, codigo }){
    async function drawTextInWidth(page, text, x, y, maxWidth, lineHeight, fontSize, font, color) {
        const words = text.split(' ');
        let currentLine = words[0];
        let nextLine = currentLine;
      
        for (let i = 1; i < words.length; i++) {
          const word = words[i];
          nextLine += ' ' + word;
          const nextLineWidth = font.widthOfTextAtSize(nextLine, fontSize);
          if (nextLineWidth > maxWidth) {
            const currentLineWidth = font.widthOfTextAtSize(currentLine, fontSize);
            const lineX = x + (maxWidth - currentLineWidth) / 2;
            page.drawText(currentLine, { x: lineX, y, size: fontSize, font, color });
            y -= lineHeight;
            currentLine = word;
            nextLine = currentLine;
          } else {
            currentLine = nextLine;
          }
        }
      
        if (currentLine.length > 0) {
          const currentLineWidth = font.widthOfTextAtSize(currentLine, fontSize);
          const lineX = x + (maxWidth - currentLineWidth) / 2;
          page.drawText(currentLine, { x: lineX, y, size: fontSize, font, color });
        }
    }

    async function calculateFirstLineWidth(text, maxWidth, fontSize, font) {
        const words = text.split(' ');
        let currentLine = words[0];
        let nextLine = currentLine;
        let firstLineWidth = 0;
      
        for (let i = 1; i < words.length; i++) {
          const word = words[i];
          nextLine += ' ' + word;
          const nextLineWidth = font.widthOfTextAtSize(nextLine, fontSize);
          if (nextLineWidth > maxWidth) {
            firstLineWidth = font.widthOfTextAtSize(currentLine, fontSize);
            break;
          } else {
            currentLine = nextLine;
          }
        }
      
        if (firstLineWidth === 0 && currentLine.length > 0) {
          firstLineWidth = font.widthOfTextAtSize(currentLine, fontSize);
        }
      
        return firstLineWidth;
    }
      
      
    // OBTENEMOS EL PDF DE LA RECETA
    const existingPdfBytes = await fetch(pdfCertificado).then(res => res.arrayBuffer())
    // CARGAMOS EL PDF EN ARRAYBUFFER
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    // EXTRAEMOS LA FUENTE DEL PDF
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.CourierBold)
    // OBTENEMOS LA PRIMER PAGINA DEL PDF
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const secondPage = pages[1]
    // OBTENEMOS LAS MEDIDAS DEL PDF
    const { width, height } = firstPage.getSize()

    let nombreWidth = helveticaFont.widthOfTextAtSize(nombre, 28)

    // AGREGAMOS EL NOMBRE DEL DOCTOR
    firstPage.drawText(nombre, {
        x: ((width - nombreWidth) + 120) / 2,
        y: height/2 + 80,
        size: 28,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })
    
    // AGREGAMOS LAS HORAS DEL CURSO
    firstPage.drawText(horas, {
        x: (width/2) + 100,
        y: height/2 - 115,
        size: 24,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })
    
    // AGREGAMOS EL DIA QUE TERMINARON EL CURSO
    firstPage.drawText(dia, {
        x: (width/2) + 146,
        y: height/2 - 143,
        size: 20,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })
    
    // AGREGAMOS EL CÓDIGO DE FINALIZACIÓN DEL CURSO
    firstPage.drawText(codigo, {
        x: (width/2) + 50,
        y: 30,
        size: 15,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })
    
    // // AGREGAMOS EL NOMBRE DEL CURSO
    // firstPage.drawText(curso, {
    //     x: ((width - cursoWidth) + 150) / 2,
    //     y: height/2,
    //     size: 40,
    //     font: helveticaFont,
    //     color: rgb(0.1, 0.1, 0.1),
    // })

    const firstLineWidth = await calculateFirstLineWidth(curso, width*0.7, 28, helveticaFont);

    await drawTextInWidth(firstPage, curso, width*0.21, height/2 - 10, width*0.7, 28*1.2, 28, helveticaFont, rgb(0.1, 0.1, 0.1));


    const pdfBytes = await pdfDoc.save()

    var blob = new Blob([pdfBytes], { type: 'application/pdf' });
    var _url = URL.createObjectURL(blob);

    return _url;
    // window.open(_url);
    // download(pdfBytes, "pdf-lib_modification_example.pdf", "application/pdf");
}