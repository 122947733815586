import React from 'react';

import './CursosItem.css';

import Logo from '../../assets/images/LogoDenthereumAmarillo.png';
import { Link } from 'react-router-dom';
import NewCourseflag from '../NewCourseFlag/NewCourseflag';

function CursosItem(props) {
    console.log(props)
    let statusMembresia = localStorage.getItem('statusMembresia');

    let mystyle = {
        backgroundImage: "url('"+ props.url + "')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        backgroundPosition: "center",
        height: "150px",
    };

    let ruta = "/cursos/"+props.urlCorta;

    let txtBoton;

    if(props.precio === 0){
        txtBoton = "INSCRIBIRME";
    }else{
        txtBoton = "COMPRAR"
    }

    return(
        <div>
            <NewCourseflag idCurso={props.idCurso} />
            <div className="cursositem">

                <Link to={ruta}>
                    <div className="cursositem-img" style={mystyle}>
                        <span className="cursositem-img-categoria">{props.categoria}</span>
                    </div>

                    <div className="cursositem-contenido">

                        <div className="cursositem-contenido_titulo">
                            <p>{props.titulo}</p>
                        </div>

                        <p className="cursositem-contenido_ponente my-3">Un curso de {props.ponente}</p>

                        <div className="cursositem-contenido_datos">
                            <small className="cursositem-contenido_datos-star">
                                <i className="fas fa-star"></i>
                                <span>{props.rate} <small>({props.opiniones})</small></span>
                            </small>

                            <small className="cursositem-contenido_datos-students">
                                <i className="fas fa-user-graduate"></i>
                                <span>{props.alumnos}</span>
                            </small>
                        </div>

                        {statusMembresia === '1' && props.accesoMembresia === true ?

                        <Link to={`/cursos/${props.urlCorta}`}>
                                <button type="button" className="btn btn-sm mt-2 w-100 btn-amarillo my-3">VER AHORA</button>
                        </Link>
                        :
                        <>
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <div className="d-flex align-items-center">
                                    <b>Precio:</b>
                                </div>
                                <div className="d-flex align-items-center">
                                    {
                                        (parseFloat(props.precio)) === 0 &&
                                        <span className='text-rojo font-weight-bold'>
                                            GRATIS
                                        </span>
                                    }
                                    {
                                        (parseFloat(props.precio)) !== 0 &&
                                        <span className="cursositem-moneda mx-2 text-rojo font-weight-bold">
                                            $ { parseFloat(props.precio).toFixed(2) }&nbsp;
                                            <small>
                                                { props.divisa }
                                            </small>
                                        </span>
                                    }
                                    {/* <img src={Logo} height="20" alt="logo" />
                                    <span className="cursositem-cripto mx-1">60.653</span> */}
                                </div>
                            </div>

                            <button type="button" className="btn btn-sm mt-2 w-100 btn-rojo"><i className="fas fa-shopping-cart mx-2"></i>{txtBoton}</button>
                        </>
                        }
                    </div>
                </Link>
            </div>
        </div>
    );
}

export {CursosItem};