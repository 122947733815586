import React, { useState, useEffect } from "react";
// import { Carousel } from 'react-responsive-carousel';
// import { Container } from '@mui/material';
import "./recentlyPlayed.css";
import { Grid, Box } from "@mui/material";
import { useHistory } from "react-router";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";

import getCourses from "../../services/getCourses";
import getLastClass from "../../services/getLastClass";
import addViewedCourse from "../../services/addViewedCourse";
// import SistemasCeramicos from '../../assets/coursesImages/sistemas_ceramicos_restauracion_estetica.jpg';
// import Protectores from '../../assets/coursesImages/protectores-personalizados.jpg';
// import Montaje from '../../assets/coursesImages/montaje_porcelana_avanzado.jpg';

// import $ from 'jquery';

import config from "../../config";

import Swal from "sweetalert2";

const RecentlyPlayed = () => {
  let idUsuario = localStorage.getItem("idUsuario");

  const [recentCourses, setRecentCourses] = useState([]);

  const [lastClass, setLastClass] = useState(1);

  const history = useHistory()

  useEffect(() => {
    getRecentlyViwedData(idUsuario);
  }, []);

  const responsive = {
    0: {
      items: 1,
    },
    576: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 2,
    },
    1200: {
      items: 2,
    },
  };

  function getRecentlyViwedData(idUsuario) {
    let formData = new FormData();

    formData.append("accion", "consultarRecientes");
    formData.append("idUsuario", idUsuario);

    let url = config.urlApi + "controladores/vistosRecientemente.app.php";

    getCourses(formData, url)
      .then((response) => {
        let _respuesta = JSON.parse(response);

        if (_respuesta.response === "success") {
          setRecentCourses(_respuesta.datos);
        } else {
          setRecentCourses("error");
        }
      })
      .catch((error) => {
        // Swal.fire(
        //   "Error del servidor",
        //   "Inténtalo de nuevo en unos momento.",
        //   "error"
        // );
      });
  }

  function getLastCourseData(idUsuario,idCurso,urlCurso) {
    let formData = new FormData();
    formData.append("idUsuario", idUsuario);
    formData.append("idCurso", idCurso);
    formData.append("accion", 'insertarCursoVisto');

    getLastClass(formData)
      .then((response) => {
        let _respuesta = JSON.parse(response);
        if (_respuesta.response === "success") {
          addViewedCourse(formData)
          .then((responseAdd) => {
            let _respuestaAdd = JSON.parse(responseAdd);
            // console.log('Recently played => ',_respuesta)

            if(_respuestaAdd.response === 'success') {
              history.push(`/mis-cursos/${urlCurso}/${_respuesta.lastClass}`)
            }
          })
          // <Redirect to={`/mis-cursos/${urlCurso}/${_respuesta.response}`} />
          // setLastClass(_respuesta.lastClass);
        } else {
          history.push(`/mis-cursos/${urlCurso}/1`)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return recentCourses === "error" ? (
    <div className="container">
      <div className="row">
        {/* <div className="col-12">
          <p>Aún no has comenzado a aprender</p>
        </div> */}
      </div>
    </div>
  ) : (
    <Grid container className="recently-viwed">
      <p className='mb-3'>Continua viendo:</p>
      {recentCourses.length >= 1 && (
        <OwlCarousel
          className="owl-theme"
          items="2"
          margin={20}
          autoplay={true}
          dots={true}
          touchDrag={true}
          animateIn={true}
          responsive={responsive}
        >
          {
            recentCourses.map((curso) => (
              <div
                className="carousel-item-recently-player d-flex justify-content-center"
                onClick={() => getLastCourseData(idUsuario, curso.idCurso, curso.urlCurso)}
                key={curso.idCurso}
              >
                {/* <Link to={`/mis-cursos/${curso.urlCurso}/${lastClass}`}> */}
                <Grid className="filter"></Grid>
                <img alt="imagen del curso" className='imagenRecentlyPlayed' src={curso.fotoCurso} key={curso.idCurso} />
                <Grid className="carousel-text-center">
                  <Box>
                    <h2 className="font-light">{curso.tituloCurso}</h2>
                  </Box>
                </Grid>
                {/* </Link> */}
              </div>
            ))
          }
        </OwlCarousel>
      )}
    </Grid>
  );
};

export default RecentlyPlayed;
