import React from "react";
import { Modal } from "react-bootstrap";

export default function ModalVerificarDatosCertificado(props){

    const [nombre, setNombre] = React.useState(props.nombre)

    return(
        <Modal id="modalVerificarDatosCertificado" show={props.modalShow} onHide={props.setModalShow}>
            <form onSubmit={props.onSubmit} id='formAddLesionados' className="modal-content">
                <Modal.Header className="modalInicioSesion_header titulo bebas" closeButton>

                    <h5 className="modal-title" id="modalVerificarDatosCertificadoLabel">
                        Verifica tu información
                    </h5>

                </Modal.Header>

                <Modal.Body className="modal-body">
                    <p>
                        Para generar tu certificado <span className="font-weight-bold">debes verificar que tu nombre completo es el correcto</span>, si no lo es, modifícalo.
                    </p>
                    <div className="row mt-3">
                        <div className="col-12">
                            <label htmlFor="nombre_completo">
                                Nombre completo:
                            </label>
                            <input 
                                type="text" 
                                className="form-control"
                                placeholder="Nombre - Apellido paterno - Apellido materno"
                                id="nombre_completo"
                                name="nombre_completo"
                                value={nombre}
                                onChange={(e) => setNombre(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="alert alert-sm alert-warning mt-2">
                        <small>
                            No podrás cambiar tu nombre después, asegúrate de escribirlo correctamente
                        </small>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button type="submit" className="btn modalCrearCuenta_body-botonCompra" id="btnSubmitModalVerificarDatosCertificado">Verificar</button>
                </Modal.Footer>
            </form>
        </Modal>
        // <div className="modal" id="modalVerificarDatosCertificado" show={props.showModal} onHide={props.setModalShow} tabIndex="-1" role="dialog" aria-labelledby="modalVerificarDatosCertificadoLabel" aria-hidden="true">
        //     <div className="modal-dialog" role="document">
        //         <form onSubmit={props.onSubmit} id='formAddLesionados' className="modal-content">
        //             <div className="modal-header">
                        
        //             </div>
                    
        //             <div className="modal-footer">
                        
        //             </div>
        //         </form>
        //     </div>
        // </div>
    )
}