import React from 'react';

import {CheckoutCarritoStepper} from '../components/CheckoutCarritoStepper/CheckoutCarritoStepper.js';

function CheckoutCarrito(props){
    return (
        <CheckoutCarritoStepper 
            getMyBalanceDTH={props.getMyBalanceDTH} 
            handleLogin={props.handleLogin} 
            saldoTokens={props.saldoTokens} />
    );
}

export {CheckoutCarrito};