import React from 'react';

import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';

function ItemProductoCarrito(props) {

    return (
        <div id={"elemento-" + props.id_carrito} className="cardProductoCarrito row">

            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">

                <img src={props.UrlImagenPrincipal} alt={props.nombreProducto} className="img-fluid" />

            </div>

            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">

                <h5 className="font-weight-bold">
                    <Link to={"/materiales/acetatos/" + props.urlCorta} className="noEstiloEnlace">
                        {props.nombreProducto}
                    </Link>
                </h5>

                <small className="text-muted">NUEVO</small>

                <div className="row mt-3">

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                        <p className="text-danger font-weight-bold txt-grande">$<span id={"precio-" + props.id_carrito}>{props.precioProducto}</span>
                            <small className="txt-pequeno mx-2">
                                {props.divisa}
                                <span className="text-muted ml-1 mx-2"></span>
                            </small>
                        </p>
                    </div>

                    <div className="col-xl-4 col-lg-9 col-md-9 col-sm-12 col-12 text-left">

                        <div className="coloresProductoCarrito">
                            <div className="d-flex">
                                <ul className="listaColoresRecibo listaColoresCarritoMixto">

                                    {props.colores.map(item => (
                                        <li key={item.id_carrito + item.id_color}>
                                            <div className="colorAcetato" data-toggle="tooltip" data-placement="left" title={item.color}
                                                style={{ backgroundColor: item.hexColor }}></div>x
                                            <span className="numeroDeAcetatos">{item.cantidad}</span>
                                        </li>
                                    ))
                                    }

                                </ul>
                            </div>
                        </div>

                    </div>

                    <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 pt-md pt-sm pt-xs">
                        <small className="text-success">
                            <i className="fas fa-truck fa-lg mr-2"></i> <i>Recíbelo entre el {props.llegaEntre} y {props.yEntre} </i>
                        </small>
                    </div>



                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right">
                        <button id={props.id_carrito} onClick={props.handleClick} className="text-danger mt-2 btnEliminar btn noPadding">
                            <i className="fas fa-trash-alt"></i>
                            <span className="text-danger ml-2">Eliminar</span>
                        </button>
                        <div id={"load-" + props.id_carrito} className="d-flex align-items-center mt-2 hidden btnEliminar btn noPadding">
                            <ReactLoading type="spin" color="#F65F5D" height={'3%'} width={'3%'} className="circularBarProgressIndicatorItem" />
                            <span className="text-danger mx-2">Eliminando</span>
                        </div>
                    </div>

                </div>

            </div>

            <hr />

        </div>
    );

}

export { ItemProductoCarrito };