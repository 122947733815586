import React, {useState} from 'react';
import "./LoginCursodontic.css";
import { Link } from 'react-router-dom';
import WhatsAppIcon from './../WhatsAppIcon';
import ButtonConnectWallet from './../ButtonConnectWallet'
import {RecuperarPassModal} from '../RecuperarPassModal/RecuperarPassModal.js';
import Swal from 'sweetalert2';
import loginSocialMedia from './../../services/loginSocialMedia';
import SignUpSocialMedia from './../../services/SignUpSocialMedia';
import loginEmail from './../../services/loginEmail';
import {sendCompleteRegistration} from './../../utils/facebookPixelEvents';
import $ from 'jquery'


//FIREBASE
import  firebase  from '../../utils/Firebase/firebase.js';

export default function LoginCursodontic({login, handleLogin}){

    const auth = firebase.auth
    const provider = new firebase.auth.GoogleAuthProvider();
    const providerFB = new firebase.auth.FacebookAuthProvider();

    let values = new FormData();

    const loginFacebook = () => {
        Swal.showLoading();
        auth().signInWithPopup(providerFB)
          .then(({ user }) => {

            values.append("uid",user.uid);
            values.append("tipoLogin", "facebook");
            values.append("fotoPerfilRedes", user.photoURL);

            let objectValues = Object.fromEntries(values);

            loginSocialMedia({values:objectValues}).then((response) => {
                let _response = JSON.parse(response);
                if(_response.response === "error"){

                    values.append("nombreUsuario", user.displayName);
                    values.append("correo", user.email);
                    values.append("especialidad", "sin especialidad");
                    values.append("medioRegistro", "web")
                    values.append("fotoPerfilRedes", user.photoURL);

                    objectValues = Object.fromEntries(values);

                    SignUpSocialMedia({values:objectValues}).then((response) => {
                        let _response = JSON.parse(response);
                        if(_response.response === "error"){
        
                            Swal.fire({
                                icon: 'error',
                                title: 'La cuenta ha sido desactivada o hubo un error',
                                text: 'Inténtalo de nuevo, si el problema persiste contáctanos',
                                showConfirmButton: false,
                                timer: 1500
                            })
        
                        } else {
                            try{
                                _response.email = user.email
                                _response.fotoPerfil = user.photoURL
                                handleLogin(_response);
                                sendCompleteRegistration(_response.nombre,_response.correo,null);
                            } catch (err){
                
                            }
        
                        }
                    }).catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error del servidor',
                            text: 'Inténtalo de nuevo en unos momentos.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })

                } else {
                    try{
                        _response.email = user.email
                        _response.fotoPerfil = user.photoURL
                        handleLogin(_response)
                    } catch (err){
        
                    }

                }
                // Swal.hideLoading();
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error del servidor',
                    text: 'Inténtalo de nuevo en unos momentos.',
                })
            })
            // deactivate()
        }).catch(err=>{
            Swal.fire({
                icon: "info",
                title: "Permisos rechazados",
                text: "Puedes intentarlo de nuevo y conectarte",
                showConfirmButton: false,
                timer: 2000,
            })
        })
    }

    const loginGoogle = () => {
        Swal.showLoading();
        auth().signInWithPopup(provider)
          .then(({ user }) => {

            values.append("uid",user.uid);
            values.append("tipoLogin", "google");
            values.append("fotoPerfilRedes", user.photoURL);

            let objectValues = Object.fromEntries(values);

            loginSocialMedia({values:objectValues}).then((response) => {
                let _response = JSON.parse(response);
                if(_response.response === "error"){

                    values.append("nombreUsuario", user.displayName);
                    values.append("correo", user.email);
                    values.append("especialidad", "sin especialidad");
                    values.append("medioRegistro", "web")
                    values.append("fotoPerfilRedes", user.photoURL);

                    objectValues = Object.fromEntries(values);

                    SignUpSocialMedia({values:objectValues}).then((response) => {
                        let _response = JSON.parse(response);
                        if(_response.response === "error"){
        
                            Swal.fire({
                                icon: 'error',
                                title: 'La cuenta ha sido desactivada o hubo un error',
                                text: 'Inténtalo de nuevo, si el problema persiste contáctanos'
                            })
        
                        } else {
                            try{
                                // Swal.close()
                                _response.email = user.email
                                _response.fotoPerfil = user.photoURL
                                handleLogin(_response)
                                sendCompleteRegistration(_response.nombre,_response.correo,null);
                            } catch (err){
                
                            }
        
                        }
                    }).catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error del servidor',
                            text: 'Inténtalo de nuevo en unos momentos.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })

                } else {
                    try{
                        _response.email = user.email
                        _response.fotoPerfil = user.photoURL
                        handleLogin(_response)
                    } catch (err){
        
                    }

                }
                // Swal.hideLoading();
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error del servidor',
                    text: 'Inténtalo de nuevo en unos momentos.',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            // deactivate()
        }).catch(err=>{
            Swal.fire({
                icon: "info",
                title: "Permisos rechazados",
                text: "Puedes intentarlo de nuevo y conectarte",
                showConfirmButton: false,
                timer: 2000,
            })
        })
    }

    const loginByEmail = (e) => {

        e.preventDefault();

        Swal.showLoading();

        let values = new FormData();

        let correo = $("#correoInicioSesion").val();
        let pass = $("#passInicioSesion").val();

        values.append("correo", correo);
        values.append("password", pass);

        let objectValues = Object.fromEntries(values);

        loginEmail({values:objectValues}).then((response) => {
            let _response = JSON.parse(response);
            if(_response.response === "error"){
                Swal.fire(
                    'Ocurrió un error',
                    'Revisa tus datos e inténtalo nuevamente.',
                    'error'
                )
            } else {
                try{
                    _response.email = correo
                    handleLogin(_response)
                    Swal.hideLoading();
                } catch (err){
    
                }
            }
            // deactivate()
        }).catch((error) => {
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    

return (

    <div className="container-fluid loginCursodontic">

        <div className="container">

            <div className="loginCursodontic__cardLogin">

                <div className="cardLogin__header">

                    <span className="cardLoginHeader__text">REGISTRATE PARA CONTINUAR</span>

                </div>

                <div className="cardLogin__body">

                    <div className="cardLoginBody__metodosInicioSesion">

                        <button type="button" className="metodosInicioSesion btnSesion__correo">

                            <i className="fa-solid fa-envelope mr-2"></i>

                            Continuar con mi Correo

                        </button>

                        <button 
                            onClick={loginGoogle}
                            type="button" 
                            className="metodosInicioSesion btnSesion__google">

                            <i className="fa-brands fa-google"></i>

                            Continuar con Google

                        </button>

                        <button 
                            onClick={loginFacebook}
                            type="button" 
                            className="metodosInicioSesion btnSesion__facebook"
                        >

                            <i className="fa-brands fa-facebook-f"></i>

                            Continuar con Facebook

                        </button>

                        <ButtonConnectWallet 
                            handleLogin={handleLogin}
                        />

                        <Link to="/denthereum" className="metodosInicioSesion__link">

                            <p className="metodosInicioSesion__text">

                                ¿Que es una wallet?

                            </p>

                        </Link>

                    </div>

                    <div className="cardLoginBody__infoFormulario">

                        <span className="infoFormulario__textInicio">O inicia sesión con tu email y contraseña</span>

                        <form action="/login.php" method="GET" className="infoFormulario__formularioLoginCursodontic" onSubmit={loginByEmail}>

                            <div className="formularioLoginCursodontic">

                                <input 
                                type="email" 
                                name="user_mail" 
                                id="correoInicioSesion" 
                                placeholder="Email o celular" className="formularioLoginCursodontic__input"
                                required />

                            </div>

                            <div className="formularioLoginCursodontic">

                                <input 
                                type="password"
                                name="userPassword" 
                                id="passInicioSesion"
                                placeholder="Contraseña" className="formularioLoginCursodontic__input" 
                                required />

                            </div>

                            <RecuperarPassModal />

                            <button className="formularioLoginCursodontic__button">

                                Entrar

                            </button>

                        </form>

                    </div>

                </div>

            </div>

        </div>

    </div>
    
    )
}