import getCookie from '../validators/getCookie';
import $ from 'jquery';
import config from '../config';
const URL_API = config.urlApi+'controladores/fbpixel.app.php';
let idUsuario = localStorage.getItem('idUsuario');

var datos = {};

function sendPageView() {
    window.fbq('track', 'PageView');
    var urlView = new URL(window.location.href);
    var _fbclid = urlView.searchParams.get("fbclid");

    if(getCookie("_fbp") !== undefined) {
        datos.event = "PageView";
        datos.fbp =getCookie("_fbp").toString();
        datos.fbc =getCookie("_fbc") ? getCookie("_fbc").toString() : _fbclid;
        datos.url = window.location.href.toString();
        datos.idUsuario = idUsuario;
    } else {
        datos.event = "PageView";
        datos.url = window.location.href.toString();
        datos.idUsuario = idUsuario;
    }
    // console.log(datos);
    $.ajax({
        data:datos,
        url:URL_API,
        type:"GET",
        success:(res)=>{
            console.log(res)
        }
    })
    
}

function sendCompleteRegistration(nombre,correo,numero) {
    window.fbq('track', 'CompleteRegistration');

    if(getCookie("_fbp") !== undefined) {
        datos.event = "CompleteRegistration";
        datos.fbp = getCookie("_fbp").toString();
        datos.fbc = getCookie("_fbc") ? getCookie("_fbc").toString() : null;
        datos.url =  window.location.href.toString();
        datos.nombre = nombre;
        datos.correo =  correo;
        datos.numero =  numero;
        datos.idUsuario = idUsuario;
    } else {
        datos.event = "CompleteRegistration";
        datos.url =  window.location.href.toString();
        datos.nombre = nombre;
        datos.correo = correo;
        datos.numero = numero;
        datos.idUsuario = idUsuario;
    }
    $.ajax({
        data:datos,
        url:URL_API,
        type:"GET",
        success:(res)=>{
            console.log(res)
        }
    })
}

function sendLead(currency, value, nombre, correo, numero) {
    window.fbq('track', 'Lead',
        {
            currency: currency,
            value: value
        }
    );

    var urlView = new URL(window.location.href);
    var _fbclid = urlView.searchParams.get("fbclid");

    if(nombre !== null && correo !== null && numero !== null){
        // SON LOS DATOS CUANDO EL USUARIO APENAS SE REGISTRÓ PARA HACER UN PROCESO DE PAGO
        if(getCookie("_fbp") !== undefined) {
            datos.event = "Lead";
            datos.fbp =  getCookie("_fbp").toString();
            datos.fbc =  getCookie("_fbc") ? getCookie("_fbc").toString() : _fbclid;
            datos.url =  window.location.href.toString();
            datos.nombre = nombre;
            datos.correo =  correo;
            datos.numero =  numero;
            datos.currency =  currency;
            datos.value =  value;
        } else {
            datos.event = "Lead";
            datos.url =  window.location.href.toString();
            datos.nombre = nombre;
            datos.correo =  correo;
            datos.numero =  numero;
            datos.currency =  currency;
            datos.value =  value;
        }
    } else {
        // SON LOS DATOS CUANDO EL USUARIO TIENE LA SESIÓN INICIADA
        if(getCookie("_fbp") !== undefined) {
            datos.event = "Lead";
            datos.fbp =  getCookie("_fbp").toString();
            datos.fbc =  getCookie("_fbc") ? getCookie("_fbc").toString() : _fbclid;
            datos.url =  window.location.href.toString();
            datos.currency =  currency;
            datos.value =  value;
        } else {
            datos.event = "Lead";
            datos.url =  window.location.href.toString();
            datos.currency =  currency;
            datos.value =  value;
        }
    }
    $.ajax({
        data:datos,
        url:URL_API,
        type:"GET",
        success:(res)=>{
            console.log(res)
        }
    })
}

function sendPurchase(currency, value, idCurso, content_name) {
    window.fbq('track', 'Purchase', 
        {
            currency: currency,
            value: value,
            content_ids: [idCurso],
            content_name: content_name,
            content_type: "product"
        }
    );

    var urlView = new URL(window.location.href);
    var _fbclid = urlView.searchParams.get("fbclid");
    
    if(getCookie("_fbp") !== undefined) {
        datos.event = "Purchase";
        datos.fbp =  getCookie("_fbp").toString();
        datos.fbc =  getCookie("_fbc") ? getCookie("_fbc").toString() : _fbclid;
        datos.url =  window.location.href.toString();
        datos.currency =  currency;
        datos.value =  value;
        datos.idCurso =  idCurso;
        datos.idUsuario = idUsuario;
    } else {
        datos.event = "Purchase";
        datos.url =  window.location.href.toString();
        datos.currency =  currency;
        datos.value =  value;
        datos.idCurso =  idCurso;
        datos.idUsuario = idUsuario;
    }
    $.ajax({
        data:datos,
        url:URL_API,
        type:"GET",
        success:(res)=>{
            console.log(res)
        }
    })
}

function sendSubscribe(currency, value) {
    // window.fbq('track', 'Subscribe');
    //PIXEL COMPLETO
    window.fbq('track', 'Subscribe', 
        {
            currency: currency,
            value: value
        }
    );

    var urlView = new URL(window.location.href);
    var _fbclid = urlView.searchParams.get("fbclid");

    if(getCookie("_fbp") !== undefined) {
        datos.event = "Subscribe";
        datos.fbp =  getCookie("_fbp").toString();
        datos.fbc =  getCookie("_fbc") ? getCookie("_fbc").toString() : _fbclid;
        datos.url =  window.location.href.toString();
        datos.idUsuario = idUsuario;
        datos.currency =  currency;
        datos.value =  value;
    } else {
        datos.event = "Subscribe";
        datos.url =  window.location.href.toString();
        datos.idUsuario = idUsuario;
        datos.currency =  currency;
        datos.value =  value;
    }
    $.ajax({
        data:datos,
        url:URL_API,
        type:"GET",
        success:(res)=>{
            console.log(res)
        }
    })

}

function sendInitiateCheckout(currency, value, idCurso, content_name) {
    window.fbq('track', 'InitiateCheckout',
        {
            currency: currency,
            value: value,
            content_ids: [idCurso],
            content_type: "product",
        }
    );

    var urlView = new URL(window.location.href);
    var _fbclid = urlView.searchParams.get("fbclid");

    // if(nombre !== null && correo !== null && numero !== null){
        // SON LOS DATOS CUANDO EL USUARIO APENAS SE REGISTRÓ PARA HACER UN PROCESO DE PAGO 
        if(getCookie("_fbp") !== undefined) {
            datos.event = "InitiateCheckout";
            datos.fbp =  getCookie("_fbp").toString();
            datos.fbc =  getCookie("_fbc") ? getCookie("_fbc").toString() : _fbclid;
            datos.url =  window.location.href.toString();
            // datos.nombre =  nombre;
            // datos.correo =  correo;
            // datos.numero =  numero;
            datos.currency =  currency;
            datos.value =  value;
            datos.content_name =  content_name;
            datos.content_id =  idCurso;
        } else {
            datos.event = "InitiateCheckout";
            datos.url =  window.location.href.toString();
            // datos.nombre =  nombre;
            // datos.correo =  correo;
            // datos.numero =  numero;
            datos.currency =  currency;
            datos.value =  value;
            datos.content_name =  content_name;
            datos.content_id =  idCurso;
        }

    // } else {
        // SON LOS DATOS CUANDO EL USUARIO TIENE LA SESIÓN INICIADA
        if(getCookie("_fbp") !== undefined) {
            datos.event = "InitiateCheckout";
            datos.fbp =  getCookie("_fbp").toString();
            datos.fbc =  getCookie("_fbc") ? getCookie("_fbc").toString() : _fbclid;
            datos.url =  window.location.href.toString();
            datos.currency =  currency;
            datos.value =  value;
            datos.content_name =  content_name;
            datos.content_id =  idCurso;
        } else {
            datos.event = "InitiateCheckout";
            datos.url =  window.location.href.toString();
            datos.currency =  currency;
            datos.value =  value;
            datos.content_name =  content_name;
            datos.content_id = idCurso;
        }
    // }

    $.ajax({
        data:datos,
        url:URL_API,
        type:"GET",
        success:(res)=>{
            console.log(res)
        }
    })
}

function sendAddToCart(id, content_type, content_name) {
    window.fbq('track', 'AddToCart',
        {
            // currency: currency,
            // value: value,
            content_ids: [id],
            content_name: content_name,
            content_category: content_type,
            content_type: "product"
        }
    );

    var urlView = new URL(window.location.href);
    var _fbclid = urlView.searchParams.get("fbclid");

    // if(nombre !== null && correo !== null && numero !== null){
    //     // SON LOS DATOS CUANDO EL USUARIO APENAS SE REGISTRÓ PARA HACER UN PROCESO DE PAGO
    //     if(getCookie("_fbp") !== undefined) {
    //         datos.event = "AddToCart";
    //         datos.fbp =  getCookie("_fbp").toString();
    //         datos.fbc =  getCookie("_fbc") ? getCookie("_fbc").toString() : _fbclid;
    //         datos.url =  window.location.href.toString();
    //         // datos.currency =  currency;
    //         // datos.value =  value;
    //         datos.content_name =  content_name;
    //         datos.content_id =  id;
    //     } else {
    //         datos.event = "AddToCart";
    //         datos.url =  window.location.href.toString();
    //         // datos.currency =  currency;
    //         // datos.value =  value;
    //         datos.content_name =  content_name;
    //         datos.content_id =  id;
    //     } 

    // } else {
        // SON LOS DATOS CUANDO EL USUARIO TIENE LA SESIÓN INICIADA
        if(getCookie("_fbp") !== undefined) {
            datos.event = "AddToCart";
            datos.fbp =  getCookie("_fbp").toString();
            datos.fbc =  getCookie("_fbc") ? getCookie("_fbc").toString() : _fbclid;
            datos.url =  window.location.href.toString();
            // datos.currency =  currency;
            // datos.value =  value;
            datos.content_name =  content_name;
            datos.content_id =  id;
            datos.idUsuario = idUsuario;
        } else {
            datos.event = "AddToCart";
            datos.url =  window.location.href.toString();
            // datos.currency =  currency;
            // datos.value =  value;
            datos.content_name =  content_name;
            datos.content_id =  id;
            datos.idUsuario = idUsuario;
        } 
    // }

    $.ajax({
        data:datos,
        url:URL_API,
        type:"GET",
        success:(res)=>{
            console.log(res)
        }
    })
}

function sendViewContent(content_name, content_id, currency, value) {
    window.fbq('track','ViewContent',
        {content_name: content_name, currency: currency, value:value}
    );

    var urlView = new URL(window.location.href);
    var _fbclid = urlView.searchParams.get("fbclid");

    if(getCookie("_fbp") !== undefined) {
        datos.event = "ViewContent";
        datos.fbp =  getCookie("_fbp").toString();
        datos.fbc =  getCookie("_fbc") ? getCookie("_fbc").toString() : _fbclid;
        datos.url =  window.location.href.toString();
        datos.currency =  currency;
        datos.value =  value;
        datos.content_name =  content_name;
        datos.content_id =  content_id;
    } else {
        datos.event = "ViewContent";
        datos.url =  window.location.href.toString();
        datos.currency =  currency;
        datos.value =  value;
        datos.content_name =  content_name;
        datos.content_id =  content_id;
    }

    $.ajax({
        data:datos,
        url:URL_API,
        type:"GET",
        success:(res)=>{
            console.log(res)
        }
    })
}

export {
    sendPageView,
    sendCompleteRegistration,
    sendLead,
    sendPurchase,
    sendSubscribe,
    sendInitiateCheckout,
    sendAddToCart,
    sendViewContent
} 