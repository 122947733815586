import React from 'react';

import './MaterialItem.css';

import Logo from '../../assets/images/LogoDenthereumAmarillo.png';
import { Link } from 'react-router-dom';

function MaterialItem(props) {
    const mystyle = {
        backgroundImage: "url('"+props.imagen+"')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        backgroundPosition: "center",
        height: "200px",
    };
    return(
        <div className="materialitem mb-2">

            <Link to={"/materiales/acetatos/"+props.url}>
                <div className="materialitem-img" style={mystyle}>
                    <span className="materialitem-img-categoria">Equipo de trabajo</span>
                </div>

                <div className="p-3 materialitem-contenido">

                    <div className="materialitem-contenido_titulo">
                        <p>Acetato: {props.nombre}</p>
                    </div>

                    <p className="materialitem-contenido_proveedor my-3">Enviado por {props.tienda}</p>

                    <div className="materialitem-contenido_datos">
                        <div className="materialitem-contenido_datos-star">
                            <i className="fas fa-star"></i>
                            <span>{props.rate} <small>({props.calificaciones})</small></span>
                        </div>

                        {/* <div className="materialitem-contenido_datos-students">
                            <i className="fas fa-user-graduate"></i>
                            <span>667</span>
                        </div> */}
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-1">
                        <div className="d-flex align-items-center">
                            <b>Precio:</b>
                        </div>

                        <div className="d-flex align-items-center text-rojo font-weight-bold">
                            $ { parseFloat(props.precio).toFixed(2) }&nbsp;
                            <small>
                                { props.divisa }
                            </small>
                        </div>
                    </div>

                    <button type="button" className="btn btn-sm mt-2 w-100 btn-rojo"><i className="fas fa-shopping-cart mx-2"></i>COMPRAR</button>

                </div>
            </Link>

        </div>
    );
}

export {MaterialItem};