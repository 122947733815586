import React, { useState, useEffect } from 'react';

import './Resenas.css';

import {ResenaItem} from '../ResenaItem/ResenaItem.js';
import {ResenasBoton} from '../ResenasBoton/ResenasBoton.js';

function Resenas({ texto, resenas}) {

    const [activeComments, setActiveComments ] = useState(false);
    
    const [buttonStatus, setButtonStatus ] = useState('MÁS');

    const [showButton, setShowButton ] = useState('');

    useEffect(() => {
        
        if(resenas ==! '') {
            setShowButton('hidden');
        }
        showComments(resenas, activeComments);
    }, []);

    function showComments(resenas, activeComments) {

        if(resenas === '') {
            return(
                <p>Aún no hemos recibido comentarios ...</p>
            )
        } else{
            switch (activeComments) {
                case true:
                    return (
                        <div>
                            {resenas.map(comentario => (
                                <div key={comentario.id_comentarioResena}>
                                    <ResenaItem 
                                    idKey={comentario.id_comentarioResena} 
                                    comentario={comentario.contenido}
                                    nombre={comentario.nombre}
                                    fotoPerfil={comentario.fotoPerfil}
                                    calificacion={comentario.calificacion}
                                    fecha={comentario.fecha}
                                    />
                                </div>
                            ))}

                            <div className={`resenaBoton ${showButton}`}>
                                <button type="button" className="btn btn-light btn-lg w-100 py-2 resenaBoton-boton my-2 py-3" onClick={ onClickMoreComments }>VER {buttonStatus} RESEÑAS</button>
                            </div>
                        </div>
                    )
                break;
                
                case false:
                    return (
                        <div>
                            {resenas.map(comentario => (
                                (comentario.iterador < 5) ?
                                <div key={comentario.id_comentarioResena}>
                                    <ResenaItem 
                                    idKey={comentario.id_comentarioResena} 
                                    comentario={comentario.contenido}
                                    nombre={comentario.nombre}
                                    fotoPerfil={comentario.fotoPerfil}
                                    calificacion={comentario.calificacion}
                                    fecha={comentario.fecha}
                                    />
                                </div>
                                :
                                <div key={comentario.id_comentarioResena}></div>
                            ))}

                            <div className={`resenaBoton ${showButton}`}>
                                <button type="button" className="btn btn-light btn-lg w-100 py-2 resenaBoton-boton my-2 py-3" onClick={ onClickMoreComments }>VER {buttonStatus} RESEÑAS</button>
                            </div>
                        </div>
                    )
                break;
            }
        }
    }

    function onClickMoreComments() {

        setActiveComments(!activeComments);
        if(!activeComments) {
            setButtonStatus('MENOS');
        } else {
            setButtonStatus('MÁS');
        }

    }

    return(
        <div className="infoCursoResenas" id="comments">

            <p className="bebas my-4 titulos">RESEÑAS DEL {texto}</p>

            { showComments(resenas, activeComments) }

            {/* <ResenasBoton /> */}

        </div>
    );
}

export {Resenas};