import React, { useState, useEffect } from 'react';
import '../components/myProfile/myProfile.css';
import { ProgressBar } from 'react-bootstrap';
import { Membresia } from '../components/Membresia/Membresia';
import { validateNoNumbers } from './../validators/validateNoNumbers';
import $ from 'jquery';
import Swal from 'sweetalert2';
import config from '../config';
import membershipStatusHandle from '../services/handleMembershipStatus';
import ReactLoading from 'react-loading';
import { Helmet } from 'react-helmet-async';
import cancelSuscription from '../services/cancelSuscription';
import reactivateSuscription from '../services/reactivateSuscription';
import UnlockUsersReports from '../components/unlockUsersReports/UnlockUsersReports';

const MyProfile = (props) => {
    
    let idUsuario = localStorage.getItem('idUsuario');
    let usuarioMembresia = localStorage.getItem('statusMembresia');

    useEffect(() => {
        // let titulo = document.getElementById("tituloWeb");
        // titulo.innerHTML = 'Cursodontic | Mi perfil';
        window.scrollTo(0, 0);

        getProfileData(idUsuario);
        handleMembershipStatus(idUsuario);

    } , []);

    const [showRecoverPswrd, setShowRecoverPswrd] = useState(false);

    const[perfil__fotoPerfil, setPerfil__fotoPerfil] = useState('');
    
    const[perfil__correo, setPerfil__correo] = useState('');

    const [perfil__contrasenaAnterior, setPerfil__contrasenaAnterior] = useState('');

    const [perfil__nuevaContrasena, setPerfil__nuevaContrasena] = useState('');
    
    const [perfil__confirmarNuevaContrasena, setPerfil__confirmarNuevaContrasena] = useState('');
    
    const [perfil__nombreCompleto, setPerfil__nombreCompleto] = useState('');
    
    const [perfil__telefonoMovil, setPerfil__telefonoMovil] = useState('');
    
    const [perfil__especialidad, setPerfil__especialidad] = useState('');
    
    const [perfil__cedula, setPerfil__cedula] = useState('');
    
    const [perfil__cumpleanos, setPerfil__cumpleanos] = useState('');
    
    const [perfil__biografia, setPerfil__biografia] = useState('');

    const [perfil__progressBar, setPerfil__progressBar] = useState('');

    const [membershipStatus, setmembershipStatus] = useState('');

    const [loading, setLoading] = useState(false);

    function onChangeContrasenaAnterior(e) {
        setPerfil__contrasenaAnterior(e.target.value);
    }
    
    function onChangeNuevaContrasena(e) {
        setPerfil__nuevaContrasena(e.target.value);
    }
    
    function onChangeConfirmarNuevaContrasena(e) {
        setPerfil__confirmarNuevaContrasena(e.target.value);
    }
    
    function onChangeNombreCompleto(e) {
        setPerfil__nombreCompleto(e.target.value);
    }
    
    function onChangeTelefonoMovil(e) {
        setPerfil__telefonoMovil(e.target.value);
    }
    
    function onChangeEspecialidad(e) {
        setPerfil__especialidad(e.target.value);
    }
    
    function onChangeCedula(e) {
        setPerfil__cedula(e.target.value);
    }
    
    function onChangeCumpleanos(e) {
        setPerfil__cumpleanos(e.target.value);
    }
    
    function onChangeBiografia(e) {
        setPerfil__biografia(e.target.value);
    }

    function previewProfileImage(e, imagePreview) {
        let img = e.target.files[0];
        console.log('img => ',img);
        document.getElementById(imagePreview).src = URL.createObjectURL(img);

        var formData = new FormData();

        formData.append('idUsuario', idUsuario);
        formData.append('image', img);

        $.ajax({
            data:formData,
            url: config.urlApi+'controladores/actualizarFotoPerfil.app.php',
            type:"POST",
            contentType: false,
            processData: false,
            success: function(response) {
                let _respuesta = JSON.parse(response);

                if(_respuesta.response === 'error') {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Ha ocurrido un error, inténtalo nuevamente',
                        showConfirmButton: false,
                        timer: 1000
                    })
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Foto de perfil actualizada',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    let imageURL = config.urlApi+'asset/fotosPerfil/'+_respuesta.response;
                    localStorage.setItem('fotoPerfil', imageURL);
                    window.location.reload();
                }
            }
        })
    }

    const handleShowRecoverPswrd = (e) => {
        e.preventDefault();
        if(showRecoverPswrd === true) {
            document.querySelector('.perfil__formCambiarPassword').classList.add('hidden')
            setShowRecoverPswrd(!showRecoverPswrd);
        } else {
            document.querySelector('.perfil__formCambiarPassword').classList.remove('hidden')
            setShowRecoverPswrd(!showRecoverPswrd);
            window.location = '#perfil__iniciarSesion';
        }
    }

    function onSubmitPerfilUpdate(e) {
        e.preventDefault();

        var formData = new FormData();

        formData.append('idUsuario', idUsuario);
        formData.append('accion', 'actualizarInformacionPersonal');
        formData.append('nombre', perfil__nombreCompleto);
        formData.append('fechaNacimiento', perfil__cumpleanos);
        formData.append('especialidad', perfil__especialidad);
        formData.append('cedula', perfil__cedula);
        formData.append('telefono', perfil__telefonoMovil);
        formData.append('descripcion', perfil__biografia);

        $.ajax({
            data:formData,
            url: config.urlApi+'controladores/actualizarInformacionPersonal.app.php',
            type:"POST",
            contentType: false,
            processData: false,
            success: function(response) {

                if(response  === 'error') {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Ha ocurrido un error, por favor recarga la página',
                        showConfirmButton: true,
                    })
                } else{
                    getProfileData(idUsuario);
                }
            }
        })
    }

    function getProfileData(idUsuario) {
        $.ajax({
            data:{idUsuario:idUsuario},
            url: config.urlApi+'controladores/perfilDatos.app.php',
            type:"POST",
            success: function(response) {
                let _respuesta = JSON.parse(response);
                console.log(_respuesta)
                if(_respuesta.response === 'error') {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Ha ocurrido un error, por favor recarga la página',
                        showConfirmButton: true,
                    })
                } else{

                    setPerfil__correo(_respuesta.correo);
                    setPerfil__fotoPerfil(_respuesta.fotoPerfil);
                    setPerfil__nombreCompleto(_respuesta.nombre);
                    setPerfil__telefonoMovil(_respuesta.numero);
                    setPerfil__especialidad(_respuesta.especialidad);
                    setPerfil__cedula(_respuesta.cedulaProfesional);
                    setPerfil__cumpleanos(_respuesta.fechaNacimiento);
                    setPerfil__biografia(_respuesta.descripcionPersonal);
                    setPerfil__progressBar(_respuesta.perfilCompletado);
                    if(_respuesta.obtuvo_certificado !== null){
                        $("#perfil__nombreCompleto").prop("readOnly",true);
                    }
                }
            }
        })
    }

    function onSubmitNuevaContrasena(e) {
        e.preventDefault();

        if(perfil__nuevaContrasena === perfil__confirmarNuevaContrasena) {

            var formData = new FormData();
    
            formData.append('idUsuario', idUsuario);
            formData.append('passAnterior', perfil__contrasenaAnterior);
            formData.append('passActual', perfil__confirmarNuevaContrasena);

            $.ajax({
                data:formData,
                url: config.urlApi+'controladores/cambiarPassActual.app.php',
                type:"POST",
                contentType: false,
                processData: false,
                success: function(response) {
                    let _respuesta = JSON.parse(response);
                    if(_respuesta.response  === 'error') {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Verifica que tu contraseña anterior esté correcta',
                            showConfirmButton: true,
                        })
                    } else{
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Contraseña actualizada',
                            showConfirmButton: false,
                            timer: 1000
                        })
                        setPerfil__contrasenaAnterior('');
                        setPerfil__nuevaContrasena('');
                        setPerfil__confirmarNuevaContrasena('');
                    }
                }
            })

        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Tu nueva contraseña no coincide',
                showConfirmButton: true,
            })
        }


    }

    function showMembership(usuarioMembresia) {
        if(usuarioMembresia === '0') {
            return (
                <div className="mb-5">
                    <Membresia login={props.login} />
                </div>
            )
        }
    }

    function handleMembershipStatus(idUsuario) {
        
        setmembershipStatus('');
        setLoading(true);

        var formData = new FormData();

        formData.append('idUsuario', idUsuario);

        membershipStatusHandle(formData)
        .then( response => {
            console.log('response => ',response);
            let _respuesta = JSON.parse(response);
            setmembershipStatus(_respuesta);
            setLoading(false)
        })
        .catch((error) => {
            console.log(error);
        })
    }

    function membershipStat(membershipStatus) {

        if(membershipStatus.response === 'cursodontic') {
            return (
                <div className="col-12">
                    <div>
                        <h5>Tu membresía está gestionada por el equipo de Cursodontic.</h5>
                        <p>Podrás seguir viendo todos los cursos exclusivos hasta que los administradores de Cursodontic finalicen el período de uso de ésta membresía.</p>
                    </div>
                    {/* <button type="submit" className="btn perfil__boton" id="btnCancelarMembresia">
                        Cancelar suscripción
                    </button> */}
                </div>
            )
        }

        if(membershipStatus.response === 'stripe' && membershipStatus.status === 'active') {
            return (
                <div className="col-12">
                    <div>
                        <h5>Tu próximo pago será el: <span className="perfil__label">{membershipStatus.date}</span></h5>
                    </div>
                    <button type="submit" className="btn perfil__boton" id="btnCancelarMembresia" onClick={() => handleCancelSuscription(idUsuario)} >
                        {!loading && 'Cancelar suscripción'}
                        {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                    </button>
                </div>
            )
        }

        if(membershipStatus.response === 'stripe' && membershipStatus.status === 'pending') {
            return <div className="col-12">
                <div>
                    <h5>Tu suscripción terminará el <span className="perfil__label">{membershipStatus.date}</span></h5>
                </div>
                <button type="submit" className="btn perfil__boton" id="btnRenovarMembresia" onClick={() => handleReactivateSubscription(idUsuario)}>
                    {!loading && 'Renovar suscripción'}
                    {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                </button>
            </div>
        }

    }

    function handleCancelSuscription(idUsuario) {
        setLoading(true);

        document.querySelector('#btnCancelarMembresia').setAttribute('disabled', true);

        var formData = new FormData();

        formData.append('idUsuario', idUsuario);

        cancelSuscription(formData)
        .then( response => {
            console.log('response => ',response);
            let _respuesta = JSON.parse(response);
            if(_respuesta.response === 'success') {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Tu suscripción ha sido cancelada',
                    showConfirmButton: false,
                    timer: 1000
                })
                document.querySelector('#btnCancelarMembresia').setAttribute('disabled', false);
                handleMembershipStatus(idUsuario);
            }
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    function handleReactivateSubscription(idUsuario) {

        setLoading(true);

        document.querySelector('#btnRenovarMembresia').setAttribute('disabled', true);

        var formData = new FormData();

        formData.append('idUsuario', idUsuario);

        reactivateSuscription(formData)
        .then( response => {
            console.log('response => ',response);
            let _respuesta = JSON.parse(response);
            if(_respuesta.response === 'success') {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Tu suscripción ha sido reactivada',
                    showConfirmButton: false,
                    timer: 1000
                })
                document.querySelector('#btnRenovarMembresia').setAttribute('disabled', false);
                handleMembershipStatus(idUsuario);
            }
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        })

    }

  return (!perfil__correo ?
    <div className="circularBarProgressIndicator">
        <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem"/>
    </div>
    :
    <div className="container py-4">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Mi perfil | Cursodontic</title>
            <link rel="canonical" href="https://cursodontic.com" />
            <meta name="description" content="Mi perfil en el sitio web de cursodontic." />

            <meta property="og:title" content="Mi perfil | Cursodontic" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
            <meta property="og:url" content="https://cursodontic.com" />

            <meta property="og:description" content="Mi perfil en el sitio web de cursodontic." />
            <meta property="og:site_name" content="Cursodontic | Educación Dental Profesional" />
            <meta name="twitter:image:alt" content="Cursodontic Educación Dental Profesional" />
        </Helmet>
        <div id="perfil" className="perfil">
            <h2 className="font-bebas text-strong-gray-color">Mi perfil</h2>
        </div>

        <div className="perfil__barraProgreso col-md-0 col-lg-0 col-xl-0">
            <div>
                <ProgressBar now={ perfil__progressBar } animated />
            </div>
            <div className="d-flex justify-content-end">
                <p className>Completa tu perfil al 100%</p>
            </div>
        </div>

        <div className="row">

            <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12">

                <div className="perfil__fotoPerfil">
                    <div className="perfil__miniatura">
                        <img src={`${perfil__fotoPerfil}`} id="profileImg" alt="Foto de perfil" className="perfil__imagen img-fluid" />
                    </div>
                    <input type="file" id="fotoPerfil" className="input-profile-img" accept="image/png, image/jpeg" onChange={(e) => previewProfileImage(e, 'profileImg')} />
                    <div>
                        <label htmlFor="fotoPerfil" className="perfil__cambiarFoto">
                            Cambia tu foto de perfil
                        </label>
                    </div>
                </div>

                <div className="perfil__iniciarSesion" id="perfil__iniciarSesion">
                    <h1 className="font-bebas text-strong-gray-color">
                        INICIAR SESIÓN
                    </h1>

                    <div className="perfil__correo">
                        <label className="perfil__label">
                            Email y contraseña
                        </label>
                        <input type="text" defaultValue={ perfil__correo } className="form-control" readOnly={true} />

                        <div className="mt-3">
                            <p className="perfil__anchor" onClick={handleShowRecoverPswrd}>
                                Cambiar contraseña
                            </p>
                        </div>

                        <form className="perfil__formCambiarPassword mt-2 hidden" id="perfil__formCambiarPassword" onSubmit={ (e) => onSubmitNuevaContrasena(e)}>
                            <label htmlFor="perfil__contrasenaAnterior" className="perfil__label">
                                Contraseña anterior
                            </label>
                            <input type="text" id="perfil__contrasenaAnterior" className="form-control perfil__input" defaultValue={ perfil__contrasenaAnterior } onChange={ onChangeContrasenaAnterior } />
                            <label htmlFor="nuevaContrasena" className="perfil__label">
                                Nueva contraseña
                            </label>
                            <input type="text" id="perfil__nuevaContrasena" className="form-control perfil__input" defaultValue={ perfil__nuevaContrasena } onChange={ onChangeNuevaContrasena } />
                            <label htmlFor="perfil__confirmarNuevaContrasena" className="perfil__label">
                                Confirmar nueva contraseña
                            </label>
                            <input type="text" id="perfil__confirmarNuevaContrasena" className="form-control perfil__input" defaultValue={ perfil__confirmarNuevaContrasena } onChange={ onChangeConfirmarNuevaContrasena } />
                            <button type="submit" className="btn perfil__boton" id="btnGuardarNuevaContrasena">
                                Guardar
                            </button>
                        </form>

                        <div className="mt-5">
                            <p>Toma el control de la interacción en nuestra plataforma al desbloquear usuarios bloqueados y revisar comentarios reportados. Mantén un ambiente seguro y positivo para todos los usuarios.</p>
                            <button type="button" className="btn perfil__boton" data-toggle="modal" data-target=".bd-example-modal-lg">
                                Administrar usuarios y comentarios
                            </button>
                        </div>

                    </div>

                </div>

            </div>

            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12 mt-4">
                <div className="perfil__datos">

                    <div className="perfil__barraProgreso col-sm-0 col-xs-0">
                        <div>
                            <ProgressBar now={ perfil__progressBar } animated />
                        </div>
                        <div className="d-flex justify-content-end">
                            <p className>Completa tu perfil al 100%</p>
                        </div>
                    </div>

                    <div className="perfil__editarDatos">
                        <h1 className="font-bebas text-strong-gray-color">
                            TUS DATOS
                        </h1>
                        <form id="perfil__formEditarDatos" className="perfil__formEditarDatos" onSubmit={ (e) => onSubmitPerfilUpdate(e)}>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                    <label htmlFor="perfil__nombreCompleto" className="perfil__label">
                                        Nombre completo
                                    </label>
                                    <input type="text" id="perfil__nombreCompleto" className="form-control perfil__input" defaultValue={ perfil__nombreCompleto } onChange={ onChangeNombreCompleto } />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                    <label htmlFor="perfil__telefonoMovil" className="perfil__label">
                                        Teléfono móvil
                                    </label>
                                    <input type="tel" onKeyPress={ validateNoNumbers } id="perfil__telefonoMovil" className="form-control perfil__input" defaultValue={ perfil__telefonoMovil } onChange={ onChangeTelefonoMovil } />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                    <label htmlFor="perfil__especialidad" className="perfil__label">
                                        Especialidad
                                    </label>
                                    <input type="text" id="perfil__especialidad" className="form-control perfil__input" defaultValue={ perfil__especialidad } onChange={ onChangeEspecialidad }/>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                    <label htmlFor="perfil__cedula" className="perfil__label">
                                        Cédula profesional
                                    </label>
                                    <input type="text" id="perfil__cedula" className="form-control perfil__input" defaultValue={ perfil__cedula } onChange={ onChangeCedula }/>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                    <label htmlFor="perfil__cumpleanos" className="perfil__label">
                                        Cumpleaños
                                    </label>
                                    <input type="date" id="perfil__cumpleanos" className="form-control perfil__input" defaultValue={ perfil__cumpleanos } onChange={ onChangeCumpleanos } />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="perfil__biografia" className="perfil__label">
                                        Biografía
                                    </label>
                                    <input type="text" id="perfil__biografia" className="form-control perfil__input" defaultValue={ perfil__biografia } onChange={ onChangeBiografia }/>
                                </div>
                            </div>
                            <button type="submit" className="btn perfil__boton" id="btnGuardarCambiosPerfil">
                                Guardar cambios
                            </button>
                        </form>
                    </div>
                    
                    <div className="perfil__statusMembresia mt-5">
                        <h1 className="font-bebas text-strong-gray-color">
                            MEMBRESÍA
                        </h1>
                        {!membershipStatus && 
                                <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem"/>
                        }
                        {membershipStatus && membershipStat(membershipStatus)}
                    </div>
                    
                </div>
            </div>

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-5">
                {showMembership(usuarioMembresia)}
            </div>

        </div>

        {/* Modal */}
        <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="handleBlockedUsers" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content p-3">
                    <div class="modal-header">
                        <h2 class="modal-title font-bebas color-azul" id="exampleModalLongTitle">ADMINISTRAR USUARIOS Y COMENTARIOS BLOQUEADOS/REPORTADOS</h2>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <UnlockUsersReports />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MyProfile;
