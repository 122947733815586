import $ from 'jquery';
import config from '../config';
const URL_API = config.urlApi+'controladores/estatusMembresia.app.php';

export default function membershipStatusHandle(values){
    return $.ajax({
        url: URL_API,
        type: 'POST',
        data: values,
        contentType: false,
        processData: false
    })
}