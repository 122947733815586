import $ from 'jquery';
import config from '../config';
const URL_API = config.urlApi+'controladores/membresia.app.php';

export default function getMembresias(values){
    return $.ajax({
        data: values,
        url: URL_API,
        type: 'POST',
        contentType: false,
        processData: false
    })
}