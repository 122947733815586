import React, { useState, useEffect } from 'react';
import '../components/purchaseReceipt/purcahseReceipt.css';

import { BsCheckCircle } from 'react-icons/bs';
import { GoChevronRight } from 'react-icons/go';
import { AiOutlineClose } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import { sendPurchase, sendSubscribe } from '../utils/facebookPixelEvents';
import { Helmet } from 'react-helmet-async';
import getMembresias from '../services/getMembresias';
import { analyticsCompraRealizada } from '../utils/firebaseAnalytics';

const PurchaseReceipt = () => {

    // let idUsuario = localStorage.getItem('idUsuario');
    // let usuarioMembresia = localStorage.getItem('statusMembresia');
    const { tipo_membresia, checkout_session_id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        sendEventsAnalytics();
        localStorage.setItem('statusMembresia','1');
    } , []);

    const sendEventsAnalytics = () => {
        getMembresias()
            .then(res => {
                let _res = JSON.parse(res)
                if(_res.response === "success"){
                    let divisa = _res.mensual.divisa;
                    let precioMembresiaMensual = _res.mensual.precioMembresiaMensual
                    let precioMembresiaSemestral = _res.semestral.precioMembresiaSemestral
                    let precioMembresiaAnual = _res.anual.precioMembresiaAnual
                    let precioFinal = 489;
                    if(tipo_membresia === "mensual")
                        precioFinal = precioMembresiaMensual;
                    if(tipo_membresia === "semestral")
                        precioFinal = precioMembresiaSemestral;
                    if(tipo_membresia === "anual")
                        precioFinal = precioMembresiaAnual;
                        
                    sendSubscribe(divisa, precioFinal);
                    sendPurchase(divisa, precioFinal, `membresia_${tipo_membresia}`, `Membresia ${tipo_membresia}`);
                    analyticsCompraRealizada(
                        divisa,
                        checkout_session_id,
                        `membresia_${tipo_membresia}`,
                        `Membresia ${tipo_membresia}`,
                        precioFinal,
                        null
                    )
                }
            })
    }

  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Recibo de compra | Cursodontic</title>
            <link rel="canonical" href="https://cursodontic.com" />
            <meta name="description" content="Recibo de compra en el sitio web de cursodontic." />

            <meta property="og:title" content="Recibo de compra | Cursodontic" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
            <meta property="og:url" content="https://cursodontic.com" />

            <meta property="og:description" content="Recibo de compra en el sitio web de cursodontic." />
            <meta property="og:site_name" content="Cursodontic | Educación Dental Profesional" />
            <meta name="twitter:image:alt" content="Cursodontic Educación Dental Profesional" />
        </Helmet>
        <div className="container-fluid compra-realizada-container p-0">
            <Link to="/" >
                <div className="exit-icon p-2 d-flex justify-content-end">
                    <AiOutlineClose />
                </div>
            </Link>
            <section className="container text-center py-2">
                <div className="check-icon d-flex justify-content-center">
                    <BsCheckCircle />
                </div>
                <div className="col-12 my-3">
                    <p className="text-white-color font-semibold fs-4">Tu suscripción ha sido activada con éxito</p>
                </div>
            </section>
        </div>
        <div className="container py-4">
            <section className="container card-receipt recipt-container">
                <div className="col-12 my-2">
                    <p className="font-semibold fs-5">Tipo de membresía:</p>
                </div>
                <div className="row pb-2">

                    <hr className="my-2"/>
                    <div className="col-3 text-center font-lg">
                        🎖
                    </div>
                    <div className="col-7 d-flex align-items-center">
                        <p>Suscripción {tipo_membresia}</p>
                    </div>
                    <div className="col-2 right-icon d-flex align-items-center justify-content-center">
                        <GoChevronRight className="align-middle"/>
                    </div>

                </div>
            </section>

        </div>
    </>
  )
}

export default PurchaseReceipt;