import React from 'react';
import './order.css';

import { FaCalendarCheck, FaSpinner, FaWpforms, FaTruck, FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Order = ({ order }) => {

    function redenStatus() {
        if(!(order.numeroRastreo)){
            if(parseInt(order.statusPedido) === 0){
                return (
                    <span className="estadoOrdenes">
                        <FaWpforms className="mr-2 my-3 order-icons"/>
                        &nbsp;Pendiente de pago
                    </span>
                )
            } else {
                return (
                    <span className="estadoOrdenes">
                        <FaSpinner className="fa-spin mr-2 my-3 order-icons"/>
                        &nbsp;Pendiente de envío
                    </span>
                )
            }
        } else {
            if(parseInt(order.statusPedido) === 1){
                return (
                    <span className="estadoOrdenes">
                        <FaTruck className="mr-2 my-3 order-icons"/>
                        &nbsp;En camino
                    </span>
                )
            } else {
                return (
                    <span className="estadoOrdenes">
                        <FaCheckCircle className="mr-2 my-3 order-icons"/>
                        &nbsp;Entregado
                    </span>
                )
            }
        }
    }

  return (
    <div className="cardPedido col-sm-12 col-12" key={order.idProductoPrincipal}>
        <div className="row order-small-devices">
            <div className="col-sm-12 col-12 mb-3">
                <div className="d-flex align-items-center">
                    <FaCalendarCheck className="order-icons mx-2 fs-5" />
                    <span className="font-semibold mx-2">{order.txtComprado}</span>
                </div>
                <p className="mt-3">
                    <Link to={`/mis-pedidos/${order.idPedido}`}>
                        <span className="font-semibold"># Pedido: { order.idPedido } | </span>
                        <span className="font-weight-bold">{ order.nombre }</span>
                    </Link>
                </p>
                {/* <span className="px-3 font-weight-bold">|</span>
                <small className="text-dark">
                    Total: <span className="font-weight-bold">$ 500</span>
                </small> */}
            </div>

            <div className="col-sm-3 col-3 form-inline pr-3 d-flex align-items-center">
                <Link to={`/mis-pedidos/${order.idPedido}`}>
                    <img 
                    src={order.UrlImagenPrincipal}
                    className="img-fluid img mr-1"
                    />
                </Link>
            </div>

            <div className="col-sm-9 col-9 mt-2">
                <div className="">
                    <span
                    className="font-light fs-5 order-name"
                    >
                    { order.nombreProducto }
                    </span>
                </div>
                <br />
                <div className="">
                    {redenStatus()}
                </div>
            </div>

            <div className="col-12">
                <Link to={`/mis-pedidos/${order.idPedido}`} className="btn-ver-pedido py-2 mt-3 font-medium d-block text-center">
                Ver pedido
                </Link>
                    {/* <button className="btn-ver-pedido py-2 mt-3 font-medium" type="button">Ver pedido</button> */}
            </div>
        </div>
        {/* Big devices */}
        <div className="row order-big-devices">
            <div className="col-12 mb-3">
                <div className="d-flex align-items-center">
                    <FaCalendarCheck className="order-icons mx-2 fs-5" />
                    <span className="font-semibold mx-2">{order.txtComprado}</span>
                    {/* Comprado hoy */}
                </div>
                <p className="mt-3">
                    <Link to={`/mis-pedidos/${order.idPedido}`}>
                        <span className="font-semibold"># Pedido: { order.idPedido } | </span>
                        <span className="font-weight-bold">{ order.nombre }</span>
                    </Link>
                </p>
                {/* <span className="px-3 font-weight-bold">|</span>
                <small className="text-dark">
                    Total: <span className="font-weight-bold">$ 500</span>
                </small> */}
            </div>

            <div className="col-sm-3 col-3 form-inline pr-3 d-flex align-items-center">
                <Link to={`/mis-pedidos/${order.idPedido}`}>
                    <img 
                    src={order.UrlImagenPrincipal}
                    className="img-fluid img mr-1"
                    />
                </Link>
            </div>

            <div className="order-info-container col-sm-9 col-9 mt-2">
                <div className="">

                    <span
                    className="font-light fs-5 order-name"
                    >
                    { order.nombreProducto }
                    </span>
                </div>
                <br />
                <div className="">
                    {redenStatus()}
                </div>
                <Link to={`/mis-pedidos/${order.idPedido}`}>
                    <button className="btn-ver-pedido py-2 mt-3 font-medium" type="button">Ver pedido</button>
                </Link>
            </div>

        </div>
    </div>
  )
}

export default Order;