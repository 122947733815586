import React, { useEffect, useState } from 'react';
import validateCourseDate from '../../services/verifyCourseDate';
import './newCourseflag.css';

const NewCourseflag = ({idCurso}) => {

    const [newCourse, setNewCourse] = useState(false);

    useEffect(() => {
        verifyNewCourseDate();
    }, [idCurso]);

    function verifyNewCourseDate(){

        let formData = new FormData();

        formData.append("idCurso", idCurso);

        validateCourseDate(formData).then(response => {
            let _respuesta = JSON.parse(response);

            if(_respuesta.response === "success") {
                let fechaActual = new Date();
                let fechaCurso = new Date(_respuesta.date);
                let diferencia = fechaActual - fechaCurso;
                let dias = diferencia / (1000 * 3600 * 24);
                if(dias < 15){
                    setNewCourse(true);
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

  return (
    <div className={`newCourse__tag ${(!newCourse)&& 'invisible'}`}>
        <span className="newCourse__tag--texto fw-bold">NUEVO</span>
    </div>
  )
}

export default NewCourseflag;