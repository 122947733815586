import $ from 'jquery';
import config from '../config';
const URL_API = config.urlApi+'controladores/inscribirCursoMembresia.app.php';

export default function inscribirCursoMembresia({values}){
    return $.ajax({
        url: URL_API,
        type: 'POST',
        data: values
    })
}