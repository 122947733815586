import React from 'react';

import './leaveComment.css';

import { Avatar } from '@mui/material';

function LeaveComment (props){

  return (
    <div className="d-flex justify-content-start class-comments-container py-3 px-2">
        <div className="d-flex">
          <Avatar 
              src={"https://cursodontic.com/asset/img/fotosPerfil/"+props.foto}
              sx={{ width: 40, height: 40 }} />

          <form id={(props.showBtnCancel) ? "formularioRespuestaClase-"+props.comentario : "formularioComentarioClase"} onSubmit={ props.onSubmitComment } className="w-100">

            <textarea className="commets-text-control ml-2 font-medium" placeholder={(props.showBtnCancel) ? "Escribe tu respuesta a "+props.nombre : "Escribe tu aporte o pregunta"} id={(props.showBtnCancel) ? "textAreaRespuestaClase-"+ props.comentario : "textAreaComentarioClase"}></textarea>

          </form>
        </div>
        <div className="d-flex justify-content-end mt-2">

          {props.showBtnCancel && <button type="button" className="btn btn-secondary mx-2" data-dismiss="modal" data-target={"#modalResponderComentario-"+props.comentario}>Close</button>}

          <button type="submit" form={(props.showBtnCancel) ? "formularioRespuestaClase-"+props.comentario : "formularioComentarioClase"} className="text-end p-2 send-comment-button">{(props.showBtnCancel) ? "Enviar respuesta" : "Enviar comentario"}</button>

        </div>
    </div>
  )
}

export default LeaveComment;