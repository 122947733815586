import React from 'react';
import './unlockUser.css';
import unlockUser from '../../services/unlockUser';
import Swal from 'sweetalert2';
import { BsUnlock } from 'react-icons/bs';

const UnlockUser = ({ complainUser }) => {

  const handleUnlockUser = async (e, blockedUserId, idUsuarioBloqueador) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append('idBlockedUsers', blockedUserId);
    formData.append('idUsuario', idUsuarioBloqueador);

    try {
      const response = await unlockUser(formData);
      let _respuesta = JSON.parse(response);
      if (_respuesta.response === 'success') {
        Swal.fire({
            icon: 'success',
            title: 'Usuario desbloqueado',
            text: 'El usuario ha sido desbloqueado.',
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            deleteUserUnlocked(blockedUserId, idUsuarioBloqueador);
        });
      } else {
        Swal.fire({
            title: "Error",
            text: "Ha ocurrido un error al desbloquear al usuario, inténtalo nuevamente.",
            icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  function deleteUserUnlocked(blockedUserId, idUsuarioBloqueador) {
    let userId = 'div-' + blockedUserId + idUsuarioBloqueador;
    const div = document.getElementById(userId);
    div.classList.add('fadeOut');
    setTimeout(() => {
      div.remove();
      const closeUnlockUserButton = document.querySelector("#unlockUser__modal-close"+blockedUserId+idUsuarioBloqueador);
      closeUnlockUserButton.click();
    }, 500);
  }


  return (
    <>
        <div className="row my-3 d-flex text-center align-items-center animatedDiv" id={"div-"+complainUser.id_blockedUsers+complainUser.blockingUserId}>
            <div className="col-12 col-xl-2 col-lg-2 col-md-2 col-sm-12 my-3">
                <img src={"https://cursodontic.com/asset/img/fotosPerfil/" + complainUser.fotoPerfil} className="unlockUsersReports__userImg" alt={`Foto de ${complainUser.nombre}`}/>
            </div>
            <div className="col-12 col-xl-7 col-lg-7 col-md-7 col-sm-12">
                <p className="font-bold mb-2">{complainUser.nombre}</p>
                <p><span className="fw-bold color-azul">Fecha de bloqueo: </span> {complainUser.blockDate}</p>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <button className="btn certificate-continue-button my-3" data-toggle="modal" data-target={"#unlockUser-"+complainUser.id_blockedUsers+complainUser.blockedUserId}>Desbloquear <BsUnlock /></button>
            </div>
            <hr className="mt-3"/>
        </div>

        {/* Modal */}
        <div className="modal fade" id={'unlockUser-' + complainUser.id_blockedUsers + complainUser.blockedUserId} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-bs-dismiss="offcanvas">            
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Desbloquear a {complainUser.nombre}</h5>
                        <button id={"unlockUser__modal-close"+complainUser.id_blockedUsers + complainUser.blockingUserId} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h2 className='font-bebas color-azul text-center'>DESBLOQUEAR USUARIO</h2>
                        <p className='m-3 text-center'>Al dar click en confirmar aceptas ver nuevamente todo tipo de contenido generado por el usuario.</p>
                        <button type="button" className="cursositem__buttonInscribir btnPrimario mt-5" onClick={(e) => handleUnlockUser(e, complainUser.id_blockedUsers, complainUser.blockingUserId)}>
                            Aceptar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default UnlockUser;