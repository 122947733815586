import React from 'react';

import './Ponente.css';

function Ponente({nombrePonente, urlImagenPonente}) {
    return(
        <div className="ponenteInfoCurso d-flex align-items-center my-4">
            <img className="ponenteInfoCurso-imagen" src={`https://cursodontic.com/asset/img/fotosPerfil/${urlImagenPonente}`} alt="Imagen del ponente" />

            <div className="mx-3 ponenteInfoCurso-texto">

                <span className="text-white">Un curso de </span>
                <span className="ponenteInfoCurso-nombre">{ nombrePonente }</span>

            </div>
        </div>
    );
}

export {Ponente};