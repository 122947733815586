import React from 'react';

import { ResultadosBusqueda } from '../components/ResultadosBusqueda/ResultadosBusqueda';


function Resultados() {

    return(
        <div>
            <ResultadosBusqueda/>
        </div>
    );
}


export {Resultados};