import React from 'react';
import './replyComment.css';

import { Avatar } from '@mui/material';
import { HiDotsHorizontal } from 'react-icons/hi';
import ComplaintUserStepper from '../ComplaintUserStepper/ComplaintUserStepper';
import ComplaintCommentStepper from '../complaintCommentStepper/ComplaintCommentStepper';

function ReplyComment (props) {

  return (
    <div className="reply-comment-container pt-3">
      <div className="row">
          <div className="col-2 d-flex justify-content-center">
              <Avatar 
                  src={"https://cursodontic.com/asset/img/fotosPerfil/"+props.foto}
                  sx={{ width: 50, height: 50 }} />
          </div>
          <div className="col-10 name-time-reply">
              <p className="font-bold">{props.nombre}
                <span className="mx-1 complaint__modal-bullets" data-toggle="modal" data-target={"#optionsCommentReply-" + props.usuario}>
                  <HiDotsHorizontal />
                </span>
              </p>
              <p className="text-gray-color font-light my-3">Hace {props.fecha}</p>
              <div className="reply-comment-text">
                  <p className="font-size-s">
                      {props.respuesta}
                      <span className="mx-1 complaint__modal-bullets" data-toggle="modal" data-target={"#optionsCommentReply-" + props.idRespuesta}>
                          <HiDotsHorizontal />
                      </span>
                  </p>
              </div>
          </div>
      </div>

      {/* Block/Hide user modal */}
      <div className="modal fade complaint__modal" id={"optionsCommentReply-" + props.usuario} tabIndex="-1" role="dialog" aria-labelledby="optionsUserTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Reportar un problema con {props.nombre}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ComplaintUserStepper idReporting={props.idUsuario} idReported={props.usuario}/>
            </div>
          </div>
        </div>
      </div>
      
      {/* Block/Hide comment modal */}
      <div className="modal fade complaint__modal" id={"optionsCommentReply-" + props.idRespuesta} tabIndex="-1" role="dialog" aria-labelledby="optionsUserTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Reportar el comentario de {props.nombre}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ComplaintCommentStepper idReporting={props.idUsuario} idComentario={props.idRespuesta}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReplyComment;