import React, { useState, useEffect } from 'react';

import './CalificacionMaterial.css';

function CalificacionMaterial({calificacion, fechaEntrega}) {

    const [totalCalificacion, setTotalCalificacion] = useState('5');

    const [totalReviews, setTotalReviews] = useState('0');

    function stars(promedioCurso) {
        switch (true){
            case (promedioCurso >= 0 && promedioCurso < 1):
                return (
                    <div className="infoCursoCalificacion-estrellas">
                        <i className="fa fa-thin fa-star"></i>                    
                    </div>
                    )
            break;

            case (promedioCurso >= 1 && promedioCurso < 2):
                return (
                    <div className="infoCursoCalificacion-estrellas">
                        <i className="fa fa-star"></i>
                    </div>
                )
            break;

            case (promedioCurso >= 2 && promedioCurso < 3):
                return (
                    <div className="infoCursoCalificacion-estrellas">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                    </div>
                    )
            break;

            case (promedioCurso >= 3 && promedioCurso < 4):
                return (
                    <div className="infoCursoCalificacion-estrellas">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <i className="fa fa-star"></i>
                    </div>
                    )
            break;

            case (promedioCurso >= 4 && promedioCurso < 5):
                return (
                    <div className="infoCursoCalificacion-estrellas">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                    </div>
                    )
            break;

            case (promedioCurso === 5):
                return (
                    <div className="infoCursoCalificacion-estrellas">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <i className="fa fa-star"></i>
                    </div>
                    )
            break;

        }
    }
    useEffect(() => {
        if(calificacion) {
            setTotalCalificacion(calificacion.calificacionTotal);
            setTotalReviews(calificacion.numeroReviews);
        }
    })
    return(
        <div className="d-flex infoMaterialCalificacion my-4 justify-content-between">
            
            <div className="infoMaterialCalificacion__reputacion">

                {stars(totalCalificacion) ? stars(totalCalificacion) : stars(1)}

                <div className="text-white mb-2">
                    <span className="infoMaterialCalificacion-total">{totalCalificacion}</span>
                    <span className="infoMaterialCalificacion-texto mx-1">({totalReviews} Calificaciones)</span>
                </div>
            </div>

            <div className="infoMaterialCalificacion__claseDeEnvio">

                <div className="text-white">

                    <div>
                        <span className="infoMaterialCalificacion_envio"><i className="fas fa-truck"></i>&nbsp;&nbsp;Envío GRATIS</span>
                        {/* <span className="infoMaterialCalificacion_envio"><i className="fas fa-truck"></i>&nbsp;&nbsp;Envío GRATIS <span className="infoMaterialCalificacion_fecha">({fechaEntrega.tipoEnvio})</span></span> */}
                    </div>

                    <div>
                        <span className="infoMaterialCalificacion_fecha">Llega entre el <b>{fechaEntrega.llegaEntre} y {fechaEntrega.yEntre}</b></span>
                    </div>

                </div>

            </div>
            
        </div>
    );
}

export {CalificacionMaterial};