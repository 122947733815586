import React, { useEffect, useState } from "react";
import "./teachers.css";

import Swal from "sweetalert2";

import getTeachers from '../../services/getTeachers';

const Teachers = () => {

    const [ teachers, setTeachers ] = useState([]);

    useEffect(() => {
        getTeachersData();
    }, []);

    function getTeachersData() {

        getTeachers()
        .then(response => {
            let _respuesta = JSON.parse(response);
            if(_respuesta.response === 'success') {

                setTeachers(_respuesta.teachers);

            } else {
                setTeachers([]);
            }
        })
        .catch(error => {
            console.log(error);
            Swal.fire(
                "Error del servidor",
                "Inténtalo de nuevo en uno momentos.",
                "error"
            );
        });

    }

  return (
    <div className="expertos__detalles">
      <div className="row">
        {teachers.length >= 1 && 
            teachers.map(teacher => (
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4" key={teacher.id_usuario}>
                    <div className="expertos__avatar">
                        <img
                        src={`https://cursodontic.com/asset/img/fotosPerfil/${teacher.fotoPerfil}`}
                        // src="https://cursodontic.com/asset/img/fotosPerfil/daniel.lopez.jpg"
                        // src={`${config.urlApi}/asset/img/fotosPerfil/${teacher.fotoPerfil}`}
                        alt={teacher.nombre}
                        className="expertos__imagen"
                        />
                        <p className="expertos__nombre">{teacher.nombre}</p>
                    </div>
                </div>
            ))
        }
      </div>
    </div>
  );
};

export default Teachers;
