import React from 'react';

import './InputCantidad.css';

function InputCantidad(){

    return (
        <div className="inputCantidad align-items-center">
            <i className="fas fa-box"></i>
            <select id="selectColores" className="form-select" aria-label="Default select example">

                <option selected="" value="1">1 Paquete (20 piezas)</option>
                <option value="2">2 Paquetes (40 piezas)</option>
                <option value="3">3 Paquetes (60 piezas)</option>
                <option value="4">4 Paquetes (80 piezas)</option>
                <option value="5">5 Paquetes (100 piezas)</option>
                <option value="6">6 Paquetes (120 piezas)</option>
                <option value="7">7 Paquetes (140 piezas)</option>
                <option value="8">8 Paquetes (160 piezas)</option>
                <option value="9">9 Paquetes (180 piezas)</option>
                <option value="10">10 Paquetes (200 piezas)</option>

            </select>
        </div>
    );
}

export {InputCantidad};