import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from './web3/config'
import { HelmetProvider } from 'react-helmet-async';

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Web3ReactProvider>,
  document.getElementById('root')
);