import React from "react";

import './YoutubeVideo.css';

function YoutubeEmbed (props) {
    return(
        <div className="video-responsive">
            <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/`+props.id}
            frameBorder="0"
            allowFullScreen
            title="Embedded youtube"
            />
        </div>
    );
};

export {YoutubeEmbed};
