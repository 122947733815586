import React from 'react';
import './classDescription.css';

import {Materiales} from '../Materiales/Materiales';

import { Avatar } from '@mui/material';

function ClassDescription(props) {
  return (
    <div className="class-description-container container">
        <div className="d-flex flex-row align-items-center pb-3">
            <div className="col-2">
                <Avatar 
                    src={props.descripcion.map(desc => (
                        "https://cursodontic.com/asset/img/fotosPerfil/"+desc.fotoPonente
                    ))}
                    sx={{ width: 50, height: 50 }} />
            </div>
            <p className="font-bold text-black-colo mt-3">Un curso de <span className="speaker-name-description">{props.descripcion.map(desc => (
                desc.ponente
            ))}</span></p>
        </div>
        <div className="class-description-content">
            {props.descripcion.map(desc => (
                <p key={desc.descripcion}>{desc.descripcion}</p>
            ))}
        </div>
        {props.materiales !== undefined && props.materiales.length >= 1 && <div>
            <Materiales mats={props.materiales} isDescripcion={true}/>
        </div>
        }
    </div>
  )
}

export default ClassDescription;