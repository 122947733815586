import React from 'react';

import Logo from '../../assets/images/LogoDenthereumAmarillo.png';

import './PrecioDenth.css';

function PrecioDenth({precioProducto, pais}){
    return(
        <div className="d-flex align-items-center">
            <span className="precioDenth-cripto mx-2">$ {precioProducto}{pais}</span>
        </div>
    );
}

export {PrecioDenth};