import React from 'react';
import { useState } from 'react';

import './RecuperarPass.css';

import validator from 'validator';

import $ from 'jquery';
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';

import config from '../../config.js';
import updatePassword from "../../services/updatePassword.js";
import verifyPassCode from "../../services/verifyPassCode.js";
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';

function RecuperarPass() {

    const [long, setLong] = useState('');
    const [lowerC, setLowc] = useState('');
    const [upperC, setUpperc] = useState('');
    const [number, setNumber] = useState('');
    const [symbol, setSymbol] = useState('');

    const [isEqual, setEqual] = useState('');


    const [isValidCode, setValidCode] = useState(true);
    const [data, setData] = useState(false);

    let { aleatorio, id_usuario } = useParams()

    let objectValues;
    let objectValores;

    React.useEffect(() => {

        window.scrollTo(0, 0);

        let values = new FormData();

        values.append("aleatorio", aleatorio);
        values.append('idUsuario', id_usuario);

        objectValues = Object.fromEntries(values);

        verificarCodigo();

        $("#btnResetPass").prop('disabled', true);

    }, []);

    function validate() {

        let value = $("#newPass").val();

        (validator.isStrongPassword(value, { minLength: 8, minLowercase: 0, minUppercase: 0, minNumbers: 0, minSymbols: 0 })) ? setLong(true) : setLong(false);

        (validator.isStrongPassword(value, { minLength: 0, minLowercase: 1, minUppercase: 0, minNumbers: 0, minSymbols: 0 })) ? setLowc(true) : setLowc(false);

        (validator.isStrongPassword(value, { minLength: 0, minLowercase: 0, minUppercase: 1, minNumbers: 0, minSymbols: 0 })) ? setUpperc(true) : setUpperc(false);

        (validator.isStrongPassword(value, { minLength: 0, minLowercase: 0, minUppercase: 0, minNumbers: 1, minSymbols: 0 })) ? setNumber(true) : setNumber(false);

        (validator.isStrongPassword(value, { minLength: 0, minLowercase: 0, minUppercase: 0, minNumbers: 0, minSymbols: 1 })) ? setSymbol(true) : setSymbol(false);
    }

    function compare() {

        let value = $("#newPass").val();
        let valueRep = $("#newPassRep").val();

        if (valueRep === value) {
            $("#btnResetPass").prop('disabled', false);
            setEqual(true);
        } else {
            $("#btnResetPass").prop('disabled', true);
            setEqual(false);
        }

    }

    function verificarCodigo() {

        verifyPassCode({ values: objectValues }).then((response) => {
            // console.log(response)
            let _response = JSON.parse(response);
            if (_response.response === "error") {

                setValidCode(false);
                setData(true);

            } else {

                setData(true);

            }
        }).catch((error) => {
            setValidCode(false);
            setData(true);
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }


    const updatePass = (e) => {

        e.preventDefault();

        Swal.showLoading();

        let pass = $("#newPass").val();

        let values = new FormData();

        values.append("codigoAleatorio", aleatorio);
        values.append('idUsuario', id_usuario);
        values.append('pass', pass);

        objectValores = Object.fromEntries(values);

        updatePassword({ values: objectValores }).then((response) => {
            let _response = JSON.parse(response);
            // console.log("//////RESPUESTA DE ACTUALIZAR PASS////");
            // console.log(_response)
            if (_response.response === "error") {
                Swal.fire(
                    'Ocurrió un error',
                    'Algo ha salido mal, intenta de nuevo recargando la página',
                    'error'
                )
            } else {

                Swal.fire(
                    'Realizado',
                    'Tu contraseña ha sido actualizada',
                    'success'
                );

            }
        }).catch((error) => {
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    return <>

        {data && data === false &&

            <div className="circularBarProgressIndicator">
                <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem" />
            </div>
        
        }
        
        {isValidCode && isValidCode === true && data && data === true?

            <div className="p-4 divRecuperarPass shadow">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Recuperar password | Cursodontic</title>
                </Helmet>

                <div className="text-center mt-4 mb-5">
                    <h4>Recuperar contraseña</h4>

                    <p>Ingresa tu nueva contraseña con la que accederás a Cursodontic</p>
                </div>

                <form className="text-left" onSubmit={updatePass}>
                    <div className="col-auto">
                        <label className="mb-2" for="newPass">Nueva contraseña</label>
                        <span className="input-group mb-4 d-flex align-items-center">
                            <i className="fas fa-lock iconoInputCandado"></i>
                            <input type="password" className="form-control" id="newPass" placeholder="*******" onChange={validate} />
                        </span>
                    </div>

                    <div className="mb-4">
                        <p>Debe contener:</p>
                        <ul className="listaShall">

                            <li className={(long === '') ? null : (long) ? "completedItem" : "uncompletedItem"}>
                                <i className={(lowerC) ? "fas fa-check-circle" : "far fa-check-circle"}></i>
                                Mínimo 8 caracteres
                            </li>
                            <li className={(lowerC === '') ? null : (lowerC) ? "completedItem" : "uncompletedItem"}>
                                <i className={(lowerC) ? "fas fa-check-circle" : "far fa-check-circle"}></i>
                                Una letra minúscula
                            </li>
                            <li className={(upperC === '') ? null : (upperC) ? "completedItem" : "uncompletedItem"}>
                                <i className={(lowerC) ? "fas fa-check-circle" : "far fa-check-circle"}></i>
                                Una letra mayúscula
                            </li>
                            <li className={(number === '') ? null : (number) ? "completedItem" : "uncompletedItem"}>
                                <i className={(lowerC) ? "fas fa-check-circle" : "far fa-check-circle"}></i>
                                Un número
                            </li>
                            <li className={(symbol === '') ? null : (symbol) ? "completedItem" : "uncompletedItem"}>
                                <i className={(lowerC) ? "fas fa-check-circle" : "far fa-check-circle"}></i>
                                Un caracter especial
                            </li>
                        </ul>
                    </div>

                    <div className="col-auto">
                        <label className="mb-2" for="newPassRep">Repite la contraseña</label>
                        <div className="input-group mb-2 d-flex align-items-center">
                            <i className="fas fa-lock iconoInputCandado"></i>
                            <input type="password" className="form-control" id="newPassRep" placeholder="*******" onChange={compare} />
                        </div>
                        {isEqual === false ?
                            <p className="notequal">Las contraseñas no coinciden</p>
                            : null
                        }
                    </div>

                    <button type="submit" id="btnResetPass" className="btnConfirm mt-4">CAMBIAR CONTRASEÑA</button>

                </form>

            </div>

            : 

            <div className="p-4 divRecuperarPass shadow">

                <div className="text-center mt-4 mb-4">
                    <h4>Recuperar contraseña</h4>

                    <p>Este enlace ya ha sido utilizado o ya ha expirado</p>

                    <i className="fas fa-times mt-5 iconNoValidCode"></i>
                </div>

            </div>

        }

    </>

};

export { RecuperarPass };