import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import DenthereumArtifact from "./contracts/artifacts/Denthereum";

const { address, abi } = DenthereumArtifact;

const useDenthereum = () => {
    const { active, library, chainId } = useWeb3React();

    const denthereum = useMemo(()=>{
        if(active){
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            return new ethers.Contract(address[56], abi, provider)
        }
    },[active, library])

    return denthereum;
}

export default useDenthereum;