import $ from 'jquery';
import config from '../config';
const URL_API = config.urlApi+'controladores/mdlObtenerOrdenClaseId.app.php';

export default function getOrderClassById(values){
    return $.ajax({
        url: URL_API,
        type: 'POST',
        data: values,
        contentType: false,
        processData: false,
    })
}