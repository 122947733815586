import React,{ useState } from 'react';
import { Grid, Box, LinearProgress } from '@mui/material';
// import { height } from '@mui/system';

import './courseProgress.css';

const CourseProgress = ({progress}) => {
    const [progressNumber, setProgressNumber] = useState(progress*100);
  return (
      <Grid container className="course-progress-component">
          <Grid item xs={12} >
              <Box>
                  <p className="font-regular">Continua viendo: <span className="font-semibold">{`${progressNumber}%`}</span></p>
              </Box>
          </Grid>
          <Grid item xs={12} >
              <Box  sx={{ width: '100%' }}>
                  <LinearProgress className="course-progress-bar" variant="determinate" value={progressNumber} sx={{ height: '14px', borderRadius: 5 }}/>
              </Box>
          </Grid>
      </Grid>
  )
}

export default CourseProgress;