import { Web3Provider } from "@ethersproject/providers"
// import Web3 from 'web3'
import { InjectedConnector } from '@web3-react/injected-connector'

const connector = new InjectedConnector({ 
    supportedChainIds: [
        56 // bsc test
    ] 
})

const getLibrary = (provider, connector) => {
    return new Web3Provider(provider)
}

export { connector, getLibrary } 