import React from 'react';
import logo from './../../assets/images/BasicLogoDenthereumAmarillo.png';
import './DesplieguePerfil.css';
import { Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

export default function DesplieguePerfil({ showUsuarioMenu, handleLogout, ingresoTokens, saldoTokens}){

    const formatBlanaces = Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2
    });

    const { active } = useWeb3React()
    let nombreUsuario = localStorage.getItem("nombre");

    const renderInformacionTokens = ()=>{
        if(active){

            return(
                <>
                    <div className='d-flex align-items-end'>
        
                        <div className='w-100'>
                            <p className='textInfoTokens d-flex'>
                                <img src={logo} alt="" className="precioDenthereum__img" />
                                Saldo:
                            </p>
                            <div className="desplieguePerfil__precioDenthereum mt-2">
                                <p className="precioDenthereum__text">
                                    {formatBlanaces.format(saldoTokens)} <small>DTH</small>
                                </p>
                            </div>
                        </div>

                        <div className='w-50'>
                            <p className='textInfoTokens'>
                                Ingresos:
                            </p>
                            <div className="desplieguePerfil__precioDenthereum mt-2">
                                <p className="precioDenthereum__text">
                                    {formatBlanaces.format(ingresoTokens)} <small>DTH</small>
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return(
                <div className='d-flex'>
                    <div className='w-100'>
                        <p className='textInfoTokens'>
                            Ingresos:
                        </p>
                        <div className="desplieguePerfil__precioDenthereum mt-2">
                            <img src={logo} alt="" className="precioDenthereum__img" />
                            <p className="precioDenthereum__text">
                                {formatBlanaces.format(ingresoTokens)} <small>DTH</small>
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="hidden desplieguePerfil">

            <p className="desplieguePerfil__saludo">

                ¡Hola, {(nombreUsuario !== '' && nombreUsuario !== null) ? nombreUsuario : 'Anónimo'}!
            </p>
            
            {
                renderInformacionTokens()
            }
            <hr />
            <Link to="/mi-perfil" className="desplieguePerfil__pagina my-3" onClick={ showUsuarioMenu }>
                <p className="dashboard__text">
                    Mi perfil
                </p>
                <i className="fa-solid fa-user dashboard__icono"></i>
            </Link>
            <Link to="/dashboard" className="desplieguePerfil__pagina my-3" onClick={ showUsuarioMenu }>
                <p className="dashboard__text">
                    Dashboard
                </p>
                <i className="fa-solid fa-square-poll-vertical dashboard__icono"></i>
            </Link>
            <Link to="/mis-cursos" className="desplieguePerfil__pagina my-3" onClick={ showUsuarioMenu }>
                <p className="notificaciones__text">
                    Mis cursos
                </p>
                <i className="fa-solid fa-video notificaciones__icono"></i>
            </Link>
            <Link to="/mis-pedidos" className="desplieguePerfil__pagina my-3" onClick={ showUsuarioMenu }>
                <p className="notificaciones__text">
                    Mis pedidos
                </p>
                <i className="fa-solid fa-boxes-packing notificaciones__icono"></i>
            </Link>
            <Link to="/pago-carrito" className="desplieguePerfil__pagina my-3" onClick={ showUsuarioMenu }>
                <p className="notificaciones__text">
                    Carrito
                </p>
                <i className="fas fa-shopping-cart notificaciones__icono"></i>
            </Link>
            <Link to="/notificaciones" className="desplieguePerfil__pagina my-3" onClick={ showUsuarioMenu }>
                <p className="notificaciones__text">
                    Notificaciones
                </p>
                <i className="fa-solid fa-bell notificaciones__icono"></i>
            </Link>
            
            <hr/>
            <button onClick={handleLogout} className="desplieguePerfil__buttonDesconectar noPadding">
                <p className="buttonDesconectar__text">
                    Desconectarme
                </p>
                <i className="fa-solid fa-arrow-right-from-bracket buttonDesconectar__icono"></i>
            </button>
        </div>
    )
}