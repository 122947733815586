import React from 'react';
import './classNavContent.css';

import ClassComments from '../classComments/ClassComments';
import Temary from '../temary/Temary';
import ClassDescription from '../classDescription/ClassDescription';

function ClassNavContent({clase,fotoUsuario,comentarios,materiales,descripcion,temario,claseActual,urlCurso}) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    } , []);

    return (
        <div className="nav-content-container pt-3">
            <ul className="nav nav-pills mb-3 d-flex justify-content-evenly" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <a className="nav-link px-1" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Temario</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link px-1 active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Comentarios</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link px-1" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Descripción</a>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <Temary temario={temario} claseActual={parseInt(claseActual)} urlCurso={urlCurso}/>
                </div>
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <ClassComments foto={fotoUsuario} comentarios={comentarios} clase={clase}/>
                </div>
                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <ClassDescription materiales={materiales} descripcion={descripcion}/>
                </div>
            </div>
        </div>
    )
}

export default ClassNavContent;