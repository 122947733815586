import React from 'react'
import { Helmet } from 'react-helmet-async';
import Chatbot from '../components/Chatbot';
import LateralMenu from '../components/LateralMenu';

export default function Prometeo(props) {

    return (
        <div id="body" className="body_move">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Prometeo: Tu Asistente Dental con Inteligencia Artificial</title>
                <meta name="description" content="Conoce a Prometeo, el asistente dental inteligente de Cursodontic. Aprende más sobre cómo puede ayudarte en tu práctica dental diaria." />
                <meta name="keywords" content={"chatgpt, prometeo IA, IA, cursodontic prometeo,  Prometeo, Cursodontic, asistente dental inteligente, educación dental, IA en odontología"} />
                <meta name="author" content="Cursodontic" />

                <meta property="og:title" content="Prometeo: Tu Asistente Dental con Inteligencia Artificial" />
                <meta property="og:type" content="chat" />

                <meta property="og:description" content="Conoce a Prometeo, el asistente dental inteligente de Cursodontic. Aprende más sobre cómo puede ayudarte en tu práctica dental diaria." />
                <meta property="og:site_name" content="Cursodontic | Educación Dental Profesional" />
            </Helmet>

            <LateralMenu />

            <Chatbot />
        </div>
    );
}