import $ from 'jquery';
import config from '../config';
const URL_API = config.urlApi+'controladores/web3/getInformacionTokenUsuario.php';

export default function getInformacionTokenUsuario({values}){
    return $.ajax({
        data: values,
        url: URL_API,
        type: 'POST'
    })
}