import React, { useState, useEffect } from "react";
import "../components/myOrders/myOrders.css";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";

import getOrders from "../services/getOrders";

import Order from "../components/order/Order";

import config from "../config";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const MyOrders = () => {
  let idUsuario = localStorage.getItem("idUsuario");

  let tipoUsuario = localStorage.getItem("tipoUsuario");

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {
    // let titulo = document.getElementById("tituloWeb");
    // titulo.innerHTML = 'Mis pedidos | Cursodontic';
    getUserOrders(idUsuario, tipoUsuario);
  }, []);

  function getUserOrders(idUsuario, tipoUsuario) {
    let formData = new FormData();

    formData.append("idUsuario", idUsuario);
    formData.append("tipoUsuario", tipoUsuario);

    let url = config.urlApi + "controladores/misPedidosUsuario.php";

    getOrders(formData, url)
      .then((response) => {
        setLoading(false)
        let _respuesta = JSON.parse(response);
        // console.log(_respuesta.datos);
        if (_respuesta.response === "success") {
          setOrders(_respuesta.datos);
        }
      })
      .catch((error) => {
        Swal.fire(
          "Error del servidor",
          "Inténtalo de nuevo en uno momentos.",
          "error"
        );
      });
  }

  function noOrders() {
    return (
      <div className="text-center mt-4 divPrincipal" id="noExistenProductos">
          <span className="iconoCarritoCompra">
              🧐
          </span>
          <h2 className="my-3">No tienes pedidos</h2>
          <p className="text-muted my-4">Todas tus compras aparecerán aquí</p>
          <Link to="/marketplace" className="btn btn-rojo btn-lg my-3">
              Explorar ahora
          </Link>
      </div>
    );
  }

  return(
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Mis pedidos | Cursodontic</title>
          <meta name="description" content="Pedidos comprados en cursodontic" />
          <meta name="keywords" content="pedidos comprados cursodontic" />
          <meta name="author" content="Cursodontic" />

          <meta property="og:title" content="Mis pedidos| Cursodontic" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
          <meta property="og:url" content="https://cursodontic.com" />
          <meta name="twitter:card" content="Cursodontic" />

          <meta property="og:description" content="Pedidos comprados en cursodontic" />
          <meta property="og:site_name" content="Cursodontic | Educación Dental Profesional" />
      </Helmet>
    {
      (orders.length === 0 && loading) &&
        <div className="circularBarProgressIndicator">
            <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem" />
        </div>
    }

    {
      (orders.length > 0 && !loading) &&
        <div className="container py-3 titulo">
          <div className="pt-3">
            <h3 className="font-bebas text-strong-gray-color">Mis compras</h3>
          </div>
          <div className="row">
            {orders.map((order) => (
              <div
                className="col-sm-12 col-md-12 col-lg-6 col-xl-6 my-4"
                key={order.idPedido}
              >
                <Order order={order} />
              </div>
            ))}
          </div>
        </div>
    }

    {
      (!loading && orders.length === 0) &&
        noOrders()
    }
    </>
  )
};

export default MyOrders;
