import React from 'react';

import './Entrevista.css';

import {YoutubeEmbed} from '../YoutubeVideo/YoutubeVideo.js';

function Entrevista({ nombrePonente, idYoutube}){

    return(
        <div className="infoCursoEntrevista">

            <p className="bebas mt-4 titulos">ENTREVISTA</p>

            <p className="my-3">{`Nuestro director hace una breve entrevista al ponente ${nombrePonente}, para complementar la informacion sobre el curso.`}</p>

            <div className="infoCursoEntrevista-video"><YoutubeEmbed id={ idYoutube } /></div>

        </div>
    );
}

export {Entrevista};