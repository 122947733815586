import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';

import RecentlyPlayed from '../components/recentlyPlayedCard/RecentlyPlayed';
import DentherumEarned from '../components/dentherumEarned/DentherumEarned';
import WeeklyProgress from '../components/weeklyProgress/WeeklyProgress';
import UserCertificates from '../components/userCertificates/UserCertificates';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../components/dashboard/dashboard.css';
import { Helmet } from 'react-helmet';

const Dashboard = (props) => {

    let nombre = localStorage.getItem('nombre')

    useEffect(() => {
        // let titulo = document.getElementById("tituloWeb");
        // titulo.innerHTML = 'Cursodontic | Dashboard';
        window.scrollTo(0, 0);
    } , []);

    return (
        <div className="container my-5 py-2">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard | Cursodontic</title>
                <meta name="description" content="Dashboard de administración" />

                <meta property="og:title" content="Dashboard | Cursodontic" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
                <meta property="og:url" content="https://cursodontic.com" />

                <meta property="og:description" content="Dashboard de administración" />
                <meta property="og:site_name" content="Dashboard | Cursodontic" />
                <meta name="twitter:image:alt" content="Cursodontic" />
            </Helmet>

            <div className="col-12">
                <h1 className="font-bebas dashboard-name">{`Bienvenido, ${nombre ? nombre.toUpperCase() : 'Anónimo'}`}</h1>
            </div>

            <Grid item xs={12} mt={3} className="d-flex justify-content-center align-items-center">
                <RecentlyPlayed />
            </Grid>

            <hr className='mb-4'/>

            <div className="row">

                <div className="d-flex col-sm-12 col-md-6 col-lg-6 col-xl-6 weekly-progress-container">
                    {/* <WeeklyProgress/> */}
                    <DentherumEarned 
                        handleLogin={props.handleLogin}
                        saldoTokens={props.saldoTokens}
                        ingresoTokens={props.ingresoTokens}
                    />
                </div>
                <div className="d-flex col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-sm mt-xs">
                    <UserCertificates />
                </div>
            </div>
        </div>
    )
}

export default Dashboard;