import React from 'react'
import "./ImagenesQueEsperarDenthereum.css"

export default function ImagenesQueEsperarDenthereum (){
    return(

        <div className="infoDenthereum__imgCursos">

            <div className="imgCursos__curso1">

                <div className="curso1__ilustracion1">
                                
                    <div className="ilustracion1__circulo">
                    </div>

                    <img src="https://cursodontic.com/asset/img/imagenesCurso/dosificacion_con_alginato.jpg"alt="" className="ilustracion1__img" />
                                    
                </div>

            </div>

            <div className="imgCursos__curso2">

                <div className="curso2__ilustracion2">

                    <img src="https://cursodontic.com/asset/img/imagenesCurso/incrustaciones_ceromero.jpg" alt="" className="ilustracion2__img" />
                                    
                    <span className="ilustracion2__circulo">
                                        
                    </span>

                </div>

            </div>  

            <div className="imgCursos__curso3">

                <div className="curso3__ilustracion3">

                    <img src="https://cursodontic.com/asset/img/imagenesCurso/preparacion-de-cavidades-incrustaciones.jpg" alt="" className="ilustracion3__img" />

                    <span className="ilustracion3__circulo">
                    </span>

                </div>

            </div> 

        </div>
    )
}