import React, { useState, useEffect } from "react";
import "./notificacion.css";
import { Link } from "react-router-dom";

import getDynamicLink from "../../services/getDynamicLink";
import getOrderClassById from "../../services/getOrderClassById";
import updateNotificationStatus from "../../services/updateNotificationStatus";

import Swal from "sweetalert2";

const Notificacion = ({ notification }) => {
  const [dynamicLinck, setDynamicLink] = useState([]);
  const [lastClass, setLastClass] = useState(1);

  useEffect(() => {
    if (notification.auxiliar.idCurso >= 1) {
      getLink(notification.auxiliar.idCurso);
    }

    if (notification.auxiliar.idClase >= 1) {
      getOrderClass(notification.auxiliar.idClase);
    }
  }, []);

  function handleClickNotification(idNotificacion, idUsuario) {

    let formData = new FormData();

    formData.append("idNotificacion", idNotificacion);

    formData.append("idUsuario", localStorage.getItem('idUsuario'));

    updateNotificationStatus(formData)
      .then((response) => {
        console.log(response)
        let _respuesta = JSON.parse(response);
      })
      .catch((error) => {
        Swal.fire(
          "Error del servidor",
          "Inténtalo de nuevo en uno momentos.",
          "error"
        );
      });
  }

  function getOrderClass(idClase) {
    let formData = new FormData();

    formData.append("idClase", idClase);

    getOrderClassById(formData)
      .then((response) => {
        let _respuesta = JSON.parse(response);
        if (_respuesta.response === "success" && _respuesta.ordenClase >= 1) {
          setLastClass(_respuesta.ordenClase);
        }
      })
      .catch((error) => {
        Swal.fire(
          "Error del servidor",
          "Inténtalo de nuevo en uno momentos.",
          "error"
        );
      });
  }

  function getLink(idCurso) {
    let formData = new FormData();

    formData.append("idCurso", idCurso);

    getDynamicLink(formData)
      .then((response) => {
        let _respuesta = JSON.parse(response);
        if (_respuesta.response === "success" && _respuesta.dynamicLink) {
          setDynamicLink(_respuesta.dynamicLink.split("/"));
        }
      })
      .catch((error) => {
        Swal.fire(
          "Error del servidor",
          "Inténtalo de nuevo en uno momentos.",
          "error"
        );
      });
  }

  function notificationType() {
    if (notification.visto === "0") {
      switch (notification.tipoNotificacion) {
        case "curso":
          return (
            <div className="notification-main-container">
              <div className="row">
                <div className="col-3 text-center d-flex align-items-center justify-content-center">
                  <img
                    src={notification.auxiliar.imagenCurso}
                    // src="https://cursodontic.com/asset/img/cursodontic_logo.png"
                    className="notifications-avatar"
                    alt="Cursodontic"
                  />
                </div>
                {!dynamicLinck ? (
                  <div
                    className="col-9"
                    onClick={() =>
                      handleClickNotification(
                        notification.id_notificacion,
                        notification.id_usuario_receptor
                      )
                    }
                  >
                    <h5>{notification.tituloNotificacion}</h5>
                    <p>{notification.cuerpoNotificacion}.</p>
                    <small className="text-gray-color">
                      {notification.fecha}
                    </small>
                  </div>
                ) : (
                  <div
                    className="col-9"
                    onClick={() =>
                      handleClickNotification(
                        notification.id_notificacion,
                        notification.id_usuario_receptor
                      )
                    }
                  >
                    <Link to={`/mis-cursos/${dynamicLinck[3]}/${lastClass}`}>
                      <h5>{notification.tituloNotificacion}</h5>
                      <p>{notification.cuerpoNotificacion}.</p>
                      <small className="text-gray-color">
                        {notification.fecha}
                      </small>
                    </Link>
                  </div>
                )}
              </div>
              <hr></hr>
            </div>
          );
        break;

        case "general":
          return (
            <div className="notification-main-container">
              <div className="row">
                <div className="col-3 text-center d-flex align-items-center justify-content-center">
                  <img
                    src="https://cursodontic.com/asset/img/cursodontic_logo.png"
                    className="img-fluid notifications-avatar"
                    alt="Cursodontic"
                  />
                </div>
                <div
                  className="col-9"
                  onClick={() =>
                    handleClickNotification(
                      notification.id_notificacion,
                      notification.id_usuario_receptor
                    )
                  }
                >
                  <h5>{notification.tituloNotificacion}</h5>
                  <p>{notification.cuerpoNotificacion}.</p>
                  <small className="text-gray-color">
                    {notification.fecha}
                  </small>
                </div>
              </div>
              <hr></hr>
              
            </div>
          );
          break;

        case "meGustaComentario":
          return (
            <div className="notification-main-container">
              <div className="row">
                <div className="col-3 text-center d-flex align-items-center justify-content-center">
                  <img
                    src={notification.auxiliar.imagenCurso}
                    // src="https://cursodontic.com/asset/img/cursodontic_logo.png"
                    className="notifications-avatar"
                    alt="Cursodontic"
                  />
                </div>
                {!dynamicLinck ? (
                  <div
                    className="col-9"
                    onClick={() =>
                      handleClickNotification(
                        notification.id_notificacion,
                        notification.id_usuario_receptor
                      )
                    }
                  >
                    <h5>{notification.tituloNotificacion}</h5>
                    <p>{notification.cuerpoNotificacion}.</p>
                    <small className="text-gray-color">
                      {notification.fecha}
                    </small>
                  </div>
                ) : (
                  <div
                    className="col-9"
                    onClick={() =>
                      handleClickNotification(
                        notification.id_notificacion,
                        notification.id_usuario_receptor
                      )
                    }
                  >
                    <Link to={`/mis-cursos/${dynamicLinck[3]}/${lastClass}`}>
                      <h5>{notification.tituloNotificacion}</h5>
                      <p>{notification.cuerpoNotificacion}.</p>
                      <small className="text-gray-color">
                        {notification.fecha}
                      </small>
                    </Link>
                  </div>
                )}
              </div>
              <hr></hr>
            </div>
          );
          break;

        case "nuevaCompra":
          return (
            <div className="notification-main-container">
              <div className="row">
                <div className="col-3 text-center d-flex align-items-center justify-content-center">
                  <img
                    src={notification.auxiliar.imagenCurso}
                    // src="https://cursodontic.com/asset/img/cursodontic_logo.png"
                    className="notifications-avatar"
                    alt="Cursodontic"
                  />
                </div>
                {!dynamicLinck ? (
                  <div
                    className="col-9"
                    onClick={() =>
                      handleClickNotification(
                        notification.id_notificacion,
                        notification.id_usuario_receptor
                      )
                    }
                  >
                    <h5>{notification.tituloNotificacion}</h5>
                    <p>{notification.cuerpoNotificacion}.</p>
                    <small className="text-gray-color">
                      {notification.fecha}
                    </small>
                  </div>
                ) : (
                  <div
                    className="col-9"
                    onClick={() =>
                      handleClickNotification(
                        notification.id_notificacion,
                        notification.id_usuario_receptor
                      )
                    }
                  >
                    <Link to={`/mis-cursos/${dynamicLinck[3]}/${lastClass}`}>
                      <h5>{notification.tituloNotificacion}</h5>
                      <p>{notification.cuerpoNotificacion}.</p>
                      <small className="text-gray-color">
                        {notification.fecha}
                      </small>
                    </Link>
                  </div>
                )}
              </div>
              <hr></hr>
            </div>
          );
          break;

        case "nuevoCurso":
          return (
            <div className="notification-main-container">
              <div className="row">
                <div className="col-3 text-center d-flex align-items-center justify-content-center">
                  <img
                    src={notification.auxiliar.imagenCurso}
                    // src="https://cursodontic.com/asset/img/cursodontic_logo.png"
                    className="notifications-avatar"
                    alt="Cursodontic"
                  />
                </div>
                {!dynamicLinck ? (
                  <div
                    className="col-9"
                    onClick={() =>
                      handleClickNotification(
                        notification.id_notificacion,
                        notification.id_usuario_receptor
                      )
                    }
                  >
                    <h5>{notification.tituloNotificacion}</h5>
                    <p>{notification.cuerpoNotificacion}.</p>
                    <small className="text-gray-color">
                      {notification.fecha}
                    </small>
                  </div>
                ) : (
                  <div
                    className="col-9"
                    onClick={() =>
                      handleClickNotification(
                        notification.id_notificacion,
                        notification.id_usuario_receptor
                      )
                    }
                  >
                    <Link to={`/mis-cursos/${dynamicLinck[3]}/${lastClass}`}>
                      <h5>{notification.tituloNotificacion}</h5>
                      <p>{notification.cuerpoNotificacion}.</p>
                      <small className="text-gray-color">
                        {notification.fecha}
                      </small>
                    </Link>
                  </div>
                )}
              </div>
              <hr></hr>
            </div>
          );
          break;

        case "respuestaComentario":
          return (
            <div className="notification-main-container">
              <div className="row">
                <div className="col-3 text-center d-flex align-items-center justify-content-center">
                  <img
                    src={notification.auxiliar.imagenCurso}
                    // src="https://cursodontic.com/asset/img/cursodontic_logo.png"
                    className="notifications-avatar"
                    alt="Cursodontic"
                  />
                </div>
                {!dynamicLinck ? (
                  <div
                    className="col-9"
                    onClick={() =>
                      handleClickNotification(
                        notification.id_notificacion,
                        notification.id_usuario_receptor
                      )
                    }
                  >
                    <h5>{notification.tituloNotificacion}</h5>
                    <p>{notification.cuerpoNotificacion}.</p>
                    <small className="text-gray-color">
                      {notification.fecha}
                    </small>
                  </div>
                ) : (
                  <div
                    className="col-9"
                    onClick={() =>
                      handleClickNotification(
                        notification.id_notificacion,
                        notification.id_usuario_receptor
                      )
                    }
                  >
                    <Link to={`/mis-cursos/${dynamicLinck[3]}/${lastClass}`}>
                      <h5>{notification.tituloNotificacion}</h5>
                      <p>{notification.cuerpoNotificacion}.</p>
                      <small className="text-gray-color">
                        {notification.fecha}
                      </small>
                    </Link>
                  </div>
                )}
              </div>
              <hr></hr>
            </div>
          );
          break;

        default:
          break;
      }
    }
  }

  return <div>{notificationType()}</div>;
};

export default Notificacion;
