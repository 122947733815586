import React from 'react';

import './CheckoutStepper.css';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {CrearCuenta} from '../CrearCuenta/CrearCuenta.js';
import {Wrapper} from '../Pago/Pago.js';

import config from '../../config.js';
import getInfoCourse from "../../services/getInfoCourse.js";

import Swal from 'sweetalert2';

import ReactLoading from 'react-loading';
import { sendInitiateCheckout } from '../../utils/facebookPixelEvents';
import { analyticsInicioPago } from '../../utils/firebaseAnalytics';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

const steps = [
    {
      label: 'Curso',
      completed: false
    },
    {
      label: 'Crear cuenta',
      completed: false
    },
    {
      label: 'Pago',
      completed: false
    },
];
// localhost:3000/checkout/porcelana-dental-principiantes?app=true&userID=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2OTM0MjA0ODQsImV4cCI6MTY5NjAxMjQ4NCwiaWRVc3VhcmlvIjoiMzQifQ.kL6q1z7ojTjWzJL8ocPFgEj5F6rbw6Zr7PJSQq9BgHQ
function CheckoutStepper({login, setLogin, getMyBalanceDTH, handleLogin, saldoTokens}){

    const [activeStep, setActiveStep] = React.useState(1);

    let idUsuario = localStorage.getItem('idUsuario');

    const handleNext = () => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [usuario, setUsuario] = React.useState(idUsuario);

    let { nombreCurso } = useParams();

    const[dataCurso, setDatosCurso] = React.useState([]);
    const[dataDetallesCurso, setDetallesCurso] = React.useState([]);

    const[urlCursoCorta, setUrlCurso] = React.useState([]);

    let curso = nombreCurso;
    let objectValues;

    React.useEffect(() => {

        window.scrollTo(0, 0);

        setUrlCurso(curso);

        let values = new FormData();
    
        values.append("nombreCurso",curso);
        values.append('idUsuario', usuario);
    
        objectValues = Object.fromEntries(values);

        // let titulo = document.getElementById("tituloWeb");
        // titulo.innerHTML = 'Pago | '+curso;

        getDataCourse();

    }, []);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        try{

            if(login){
                setActiveStep(2);
                setUsuario(idUsuario);
            }else{
                setActiveStep(1);
            }
        } catch (err){

        }

    }, [login]);


    function getDataCourse(){

        getInfoCourse({values:objectValues}).then((response) => {
            // console.log(response)
          let _response = JSON.parse(response);
          if(_response.response === "error"){
              Swal.fire(
                  'Ocurrió un error',
                  'No se pudo cargar la información de tu carrito',
                  'warning'
              )
          } else {
            setDatosCurso(_response.datos);
            setDetallesCurso(_response.datos.detallesGenerales);
            sendInitiateCheckout(_response.datos.divisa, _response.datos.precio, _response.datos.id_curso, _response.datos.tituloCurso);
            analyticsInicioPago(_response.datos.id_curso, _response.datos.tituloCurso, _response.datos.precio);

          }
        }).catch((error) => {
            console.log(error)
        //   Swal.fire(
        //       'Error del servidor',
        //       'Inténtalo de nuevo en unos momentos.',
        //       'error'
        //   )
        })
      }


    return (
        <div>

            { dataDetallesCurso.length !== 0 ?

            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Pago | {dataCurso.tituloCurso}</title>
                    <meta name="description" content={`Realiza el pago del curso: ${dataCurso.tituloCurso}`} />

                    <meta property="og:title" content={`Pago | ${dataCurso.tituloCurso}`} />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content={dataDetallesCurso.imagenCurso} />
                    <meta property="og:url" content="https://cursodontic.com" />

                    <meta property="og:description" content={`Realiza el pago del curso: ${dataCurso.tituloCurso}`} />
                    <meta property="og:site_name" content={`Pago | ${dataCurso.tituloCurso}`} />
                    <meta name="twitter:image:alt" content="Cursodontic" />
                </Helmet>
                
                <div className="container-fluid informacion">

                    <div className="row">
                        <div className="col-0 col-sm-0 col-md-1 col-lg-3 col-xl-3"></div>
                        <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                            <div className="row informacionCurso">
                                <div className="col-8 my-auto">
                                    <div className="informacionCurso_texto">
                                        <span className="informacionCurso_texto-seleccionado">Curso seleccionado</span>
                                        <p className="informacionCurso_texto-tituloCurso my-3">
                                            {
                                                dataCurso.tituloCurso
                                            }
                                        </p>
                                        <p className="informacionCurso_texto-precioCurso mb-0">$ {dataDetallesCurso.precio} {dataCurso.divisa}</p>
                                    </div>
                                </div>
                                <div className="col-4 d-flex align-items-center mx-auto">
                                    <div className="informacionCurso_imagenCurso float-right">
                                        <img src={dataDetallesCurso.imagenCurso} alt="Imagen de Curso" className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-0 col-sm-0 col-md-1 col-lg-3 col-xl-3"></div>
                    </div>

                </div>

                <div className="container-fluid">

                    <div className="row">

                        <div className="col-1 col-sm-1 col-md-1 col-lg-3 col-xl-3"></div>

                        <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 pl-0 divPrincipal my-5">

                            <Stepper nonLinear={true} activeStep={activeStep} orientation="horizontal">
                                {steps.map((step, index) => (
                                <Step completed={step.completed} className="step-number font-bold" key={step.label}>
                                    <StepLabel >
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span>
                                        {step.label}
                                        </span>
                                    </div>
                                    </StepLabel>
                                </Step>
                                ))}
                            </Stepper>
                            
                            <React.Fragment>

                                <Typography>
                                    {  
                                        (login) 
                                        ?
                                            <Wrapper 
                                                precioUSD={dataDetallesCurso.precioUSD} 
                                                priceCourse={dataDetallesCurso.precio} 
                                                proceso={''} 
                                                curso={dataCurso.id_curso} 
                                                idUsuario={usuario} 
                                                urlCurso={urlCursoCorta} 
                                                divisa={dataCurso.divisa} 
                                                getMyBalanceDTH={getMyBalanceDTH} 
                                                handleLogin={handleLogin} 
                                                saldoTokens={saldoTokens} 
                                                tituloCurso={dataCurso.tituloCurso}
                                            />
                                        :
                                            <CrearCuenta siguientePaso={handleNext} setLogin={setLogin} handleLogin={handleLogin} />
                                    }
                                </Typography>

                            </React.Fragment>

                        </div>

                        <div className="col-1 col-sm-1 col-md-1 col-lg-3 col-xl-3"></div>

                    </div>

                </div>

            </div> 
            
            :

            <div className="circularBarProgressIndicator">
                <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem"/>
            </div>

            }

            <div className="container-fluid px-0 bg-black">
                <div className="py-5 footerCheckout px-5 container">
                    <p className="text-center txtAdquirir mb-5">¿Qué estás a punto de adquirir?</p>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div>
                                <div className="row mb-4">
                                    <div className="col-2 my-auto text-center"><i className="fas fa-desktop iconoFooterCheckout"></i></div>
                                    <div className="col pl-0">
                                        <span>Curso completo en HD, para que no pierdas ningún detalle.</span>
                                    </div>
                                </div>                    
                            </div>
                            <div>
                                <div className="row mb-4">
                                    <div className="col-2 my-auto text-center"><i className="far fa-play-circle iconoFooterCheckout"></i></div>
                                    <div className="col pl-0">
                                        <span>Pdrás acceder al curso de manera ilimitada, así que podrás verlo las veces que tu quieras cuando quieras.</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row mb-4">
                                    <div className="col-2 my-auto text-center"><i className="fas fa-question-circle iconoFooterCheckout"></i></div>
                                    <div className="col pl-0">
                                        <span>Pregunta tus dudas dentro del curso y nuestro ponente la resolverá.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div>
                                <div className="row mb-4">
                                    <div className="col-2 my-auto text-center"><i className="fas fa-award iconoFooterCheckout"></i></div>
                                    <div className="col pl-0">
                                        <span>Envío de un certificado de finalización cuando completes las clases del curso al 100% y dejes una calificación del curso.</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row mb-4">
                                    <div className="col-2 my-auto text-center"><i className="fas fa-mobile-alt iconoFooterCheckout"></i></div>
                                    <div className="col pl-0">
                                        <span>Puedes ver el curso desde nuestra App.</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row mb-4">
                                    <div className="col-2 my-auto text-center"><i className="fas fa-users iconoFooterCheckout"></i></div>
                                    <div className="col pl-0">
                                        <span>Acceso a la comunidad de aprendizaje en línea para dentistas y técnicos dentales más grandes de América Latina.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export {CheckoutStepper};