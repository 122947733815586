import React, { useState, useEffect } from "react";
import "./complaintUserStepper.css";
import { GoReport } from "react-icons/go";
import { SiAdblock } from "react-icons/si";
import getReportReasons from "../../services/getReportReasons";
import blockUser from "../../services/blockUser";
import Swal from "sweetalert2";
import reportUser from "../../services/reportUser";

const ComplaintUserStepper = ({idReporting,idReported}) => {

  const [reportUserVisible, setReportUserVisible] = useState(false);
  const [blockUserVisible, setBlockUserVisible] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [reportOption, setReportOption] = useState();
  const [reportReason, setReportReason] = useState();

  useEffect(() => {
    getReasons();
  }, []);

  function getReasons() {
    getReportReasons().then(response => {
      let _respuesta = JSON.parse(response);
      if(_respuesta.response === 'success') {
        setReasons(_respuesta.data);
      }
    });
  }

  const handleReportUser = () => {
    setReportUserVisible(!reportUserVisible);
    if(blockUserVisible) {
      setBlockUserVisible(false);
    }
  };
  
  const handleBlockUser = () => {
    setBlockUserVisible(!blockUserVisible);
    if(reportUserVisible) {
      setReportUserVisible(false);
    }
  };

  function handleUserBlocking(idReporting,idReported, e) {
    e.preventDefault();
    let values = new FormData();
    values.append('idReportingUser',idReporting);
    values.append('idReportedUser',idReported);
    console.log('idReporting => ',idReporting);
    console.log('idReported => ',idReported);
    blockUser(values).then(response => {
      let _respuesta = JSON.parse(response);

      if(_respuesta.response === 'success') {
        Swal.fire({
          title: "Usuario bloqueado",
          text: 'El usuario se ha bloqueado exitosamente.',
          icon: "success"
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: "Error",
          text: 'Ha ocurrido un error al bloquear el usuario, inténtalo nuevamente.',
          icon: "error"
        });
      }
    });
  }

  function handleReportOptions(e) {
    setReportOption(e.target.value);
  }

  function handleReportReason(e) {
    setReportReason(e.target.value);
  }

  function handleReportUserSubmit(idReporting,idReported,e) {
    e.preventDefault();

    if(reportOption === undefined || reportReason === undefined) {
      Swal.fire({
        title: "Campos incompletos",
        text: 'Por favor verifica que todos los campos estén debidamente llenados.',
        icon: "warning"
      });
    } else {  
      let values = new FormData();

      values.append('idReportingUser',idReporting);
      values.append('idReportedUser',idReported);
      values.append('reason',reportReason);
      values.append('idReportReason',reportOption);
      console.log('idReporting comment => ',idReporting);
      console.log('idReported comment => ',idReported);
      reportUser(values).then(response => {
        let _respuesta = JSON.parse(response);

        if(_respuesta.response === 'success') {
          Swal.fire({
            title: "Usuario reportado",
            text: 'El usuario ha sido reportado exitosamente.',
            icon: "success"
          }).then(() => {
            window.location.reload();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: 'Ha ocurrido un error al reportar el usuario, inténtalo nuevamente.',
            icon: "error"
          });
        }
      });
    }
  }

  return (
    <div className="complaintUserStepper">
      <div className="row gx-5">
        <div className="col d-flex justify-content-center" id="reportUserButton">
          <button
            className="cursositem__buttonInscribir btnPrimario"
            onClick={handleReportUser}
          >
            Reportar usuario
            <GoReport className="mx-2" />
          </button>
        </div>
        <div className="col d-flex justify-content-center" id="blockUserButton">
          <button className="cursositem__buttonInscribir btnPrimario" onClick={handleBlockUser}>
            Bloquear usuario
            <SiAdblock className="mx-2" />
          </button>
        </div>
        <div
          className={`col-12 my-3 d-flex flex-column justify-content-center complaintUserStepper__reportUser ${
            reportUserVisible ? "visible" : ""
          }`}
          id="reportUser"
        >
          <h2 className="font-bebas color-azul text-center">Reportar usuario</h2>
          <p className="m-3">
            Tu reporte nos ayuda a mantener una comunidad segura y respetuosa. Tomamos los reportes en serio y investigaremos el asunto de manera rápida.
          </p>
          <form className="mx-3" onSubmit={(e) => handleReportUserSubmit(idReporting,idReported,e)}>
            <label htmlFor="reason" className="form-label">
              Selecciona una opción de las siguientes:
            </label>
            <select className="form-select" aria-label="Report options" onChange={handleReportOptions} required>
              <option selected disabled>Selecciona una opción</option>
              {reasons.map(reason => (
                <option key={`reason-${reason.id_reportReason}`} value={reason.id_reportReason}>{reason.reason}</option>
              ))}
            </select>
            <label htmlFor="reasonAditional" className="form-label mt-2">
              Información adicional:
            </label>
            <textarea className="form-control" id="reasonAditional" rows="3" onChange={handleReportReason} required></textarea>
            <button type="submit" className="cursositem__buttonInscribir btnPrimario mt-5">
              Reportar
            </button>
          </form>
        </div>
        <div
          className={`col-12 d-flex my-3 flex-column justify-content-center complaintUserStepper__blockUser ${
            blockUserVisible ? "visible" : ""
          }`}
          id="blockUser"
        >
          <h2 className="font-bebas color-azul text-center">Bloquear usuario</h2>
          <p className="m-3">
            Al dar click en confirmar aceptas que ya no podrás ver ningún tipo
            de contenido generado por el usuario.
          </p>
          <button className="cursositem__buttonInscribir btnPrimario mt-5" onClick={(e) => handleUserBlocking(idReporting,idReported,e)}>
            Bloquear
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComplaintUserStepper;
