import React, { useState, useEffect } from "react";
import "./complaintCommentStepper.css";
import { GoReport } from "react-icons/go";
import { BiHide } from "react-icons/bi";
import getReportReasons from "../../services/getReportReasons";
import Swal from "sweetalert2";
import reportComment from "../../services/reportComment";
import hideComment from "../../services/hideComment";

const ComplaintCommentStepper = ({ idReporting, idComentario }) => {

  const [reportCommentVisible, setReportCommentVisible] = useState(false);
  const [hideCommentVisible, setHideCommentVisible] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [reportOption, setReportOption] = useState();
  const [reportReason, setReportReason] = useState();

  useEffect(() => {
    getReasons();
  }, []);

  function getReasons() {
    getReportReasons().then((response) => {
      let _respuesta = JSON.parse(response);
      if (_respuesta.response === "success") {
        setReasons(_respuesta.data);
      }
    });
  }

  const handleReportComment = () => {
    setReportCommentVisible(!reportCommentVisible);
    if (hideCommentVisible) {
      setHideCommentVisible(false);
    }
  };

  const handleBlockUser = () => {
    setHideCommentVisible(!hideCommentVisible);
    if (reportCommentVisible) {
      setReportCommentVisible(false);
    }
  };

  function handleReportOptions(e) {
    setReportOption(e.target.value);
  }

  function handleReportReason(e) {
    setReportReason(e.target.value);
  }

  function handleCommentHidding(idReporting, idComentario, e) {
    e.preventDefault();
    let values = new FormData();
    values.append("idUsuario", idReporting);
    values.append("idComentarioClase", idComentario);

    hideComment(values).then((response) => {
      let _respuesta = JSON.parse(response);

      if (_respuesta.response === "success") {
        Swal.fire({
          title: "Comentario ocultado",
          text: "El comentario se ha ocultado exitosamente.",
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido un error al ocultar el comentario, inténtalo nuevamente.",
          icon: "error",
        });
      }
    });
  }

  function handleReportCommentSubmit(idReporting, idComentario, e) {
    e.preventDefault();

    if (reportOption === undefined || reportReason === undefined) {
      Swal.fire({
        title: "Campos incompletos",
        text: "Por favor verifica que todos los campos estén debidamente llenados.",
        icon: "warning",
      });
    } else {
      let values = new FormData();

      values.append("idComentarioClase", idComentario);
      values.append("idUsuario", idReporting);
      values.append("reason", reportReason);
      values.append("idReportReason", reportOption);

      reportComment(values).then((response) => {
        let _respuesta = JSON.parse(response);

        if (_respuesta.response === "success") {
          Swal.fire({
            title: "Comentario reportado",
            text: "El comentario ha sido reportado exitosamente.",
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido un error al reportar el comentario, inténtalo nuevamente.",
            icon: "error",
          });
        }
      });
    }
  }

  return (
    <div className="complaintCommentStepper">
      <div className="row gx-5">
        <div
          className="col d-flex justify-content-center"
          id="reportCommentButton"
        >
          <button
            className="cursositem__buttonInscribir btnPrimario"
            onClick={handleReportComment}
          >
            Reportar comentario
            <GoReport className="mx-2" />
          </button>
        </div>
        <div className="col d-flex justify-content-center" id="blockCommentButton">
          <button
            className="cursositem__buttonInscribir btnPrimario"
            onClick={handleBlockUser}
          >
            Ocultar comentario
            <BiHide className="mx-2" />
          </button>
        </div>
        <div
          className={`col-12 my-3 d-flex flex-column justify-content-center complaintUserStepper__reportUser ${
            reportCommentVisible ? "visible" : ""
          }`}
          id="reportUser"
        >
          <h2 className="font-bebas color-azul text-center">
            Reportar comentario
          </h2>
          <p className="m-3">
            Tu reporte nos ayuda a mantener una comunidad segura y respetuosa.
            Tomamos los reportes en serio y investigaremos el asunto de manera
            rápida.
          </p>
          <form
            className="mx-3"
            onSubmit={(e) => handleReportCommentSubmit(idReporting, idComentario, e)}
          >
            <label htmlFor="reason" className="form-label">
              Selecciona una opción de las siguientes:
            </label>
            <select
              className="form-select"
              aria-label="Report options"
              onChange={handleReportOptions}
              required
            >
              <option selected disabled>
                Selecciona una opción
              </option>
              {reasons.map((reason) => (
                <option
                  key={`reason-${reason.id_reportReason}`}
                  value={reason.id_reportReason}
                >
                  {reason.reason}
                </option>
              ))}
            </select>
            <label htmlFor="reasonAditional" className="form-label mt-2">
              Información adicional:
            </label>
            <textarea
              className="form-control"
              id="reasonAditional"
              rows="3"
              onChange={handleReportReason}
              required
            ></textarea>
            <button
              type="submit"
              className="cursositem__buttonInscribir btnPrimario mt-5"
            >
              Reportar
            </button>
          </form>
        </div>
        <div
          className={`col-12 d-flex my-3 flex-column justify-content-center complaintUserStepper__blockUser ${
            hideCommentVisible ? "visible" : ""
          }`}
          id="blockUser"
        >
          <h2 className="font-bebas color-azul text-center">
            Ocultar comentario
          </h2>
          <p className="m-3">
            Al dar click en confirmar aceptas que ya no verás este comentario.
          </p>
          <button
            className="cursositem__buttonInscribir btnPrimario mt-5"
            onClick={(e) => handleCommentHidding(idReporting, idComentario, e)}
          >
            Ocultar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComplaintCommentStepper;
