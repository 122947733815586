import React from 'react';

import './Calificacion.css';

function Calificacion({promedioCurso, numOpiniones, totalAlumnos}) {
    
    function stars(promedioCurso) {
        switch (true){
            case (promedioCurso >= 0 && promedioCurso < 1):
                return (
                    <div className="infoCursoCalificacion-estrellas">
                        <i className="fa fa-thin fa-star"></i>                    
                    </div>
                    )
            break;

            case (promedioCurso >= 1 && promedioCurso < 2):
                return (
                    <div className="infoCursoCalificacion-estrellas">
                        <i className="fa fa-star"></i>
                    </div>
                )
            break;

            case (promedioCurso >= 2 && promedioCurso < 3):
                return (
                    <div className="infoCursoCalificacion-estrellas">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                    </div>
                    )
            break;

            case (promedioCurso >= 3 && promedioCurso < 4):
                return (
                    <div className="infoCursoCalificacion-estrellas">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <i className="fa fa-star"></i>
                    </div>
                    )
            break;

            case (promedioCurso >= 4 && promedioCurso < 5):
                return (
                    <div className="infoCursoCalificacion-estrellas">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                    </div>
                    )
            break;

            case (promedioCurso === 5):
                return (
                    <div className="infoCursoCalificacion-estrellas">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <i className="fa fa-star"></i>
                    </div>
                    )
            break;

        }
    }
    
    return(
        <div className="d-flex infoCursoCalificacion mb-4 justify-content-between">
            
            <div>
                <div className="text-white mb-2">
                    <span className="infoCursoCalificacion-total">{ promedioCurso }</span>
                    <span className="infoCursoCalificacion-texto mx-1">{`(${numOpiniones} Calificaciones)`}</span>
                </div>
                {stars(promedioCurso)}
            </div>

            <div className="infoCursoCalificacionEstudiantes">
                <div className="text-white">
                    <div className="mb-2">
                        <span className="infoCursoCalificacion-total">Estudiantes matriculados</span>
                    </div>
                    <div className="infoCursoCalificacionEstudiantesDato">
                        <i className="fa fa-user-graduate infoCursoCalificacion-alumno"></i>
                        <span className="infoCursoCalificacion-total mx-2">{ totalAlumnos }</span>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export {Calificacion};