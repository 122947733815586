import $ from 'jquery';

export default function getCourses(formData,url) {
  return $.ajax({
    data: formData,
    url: url,
    type: "POST",
    contentType: false,
    processData: false,
  })
}