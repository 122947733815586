import React from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import './CarruselImgMaterial.css';

// const imagenesMaterial = [
//     { id: 1, url: 'https://cursodontic.com/asset/img/tiendas/proplay/VERDE_ELECTRICO1.jpg'},
//     { id: 2, url: 'https://cursodontic.com/asset/img/tiendas/proplay/PROTECTORES_MIXTO.jpg'},
//     { id: 3, url: 'https://cursodontic.com/asset/img/tiendas/proplay/UVA.jpg'},
//     { id: 4, url: 'https://cursodontic.com/asset/img/tiendas/proplay/AZUL_TRASLUCIDO1.jpg'},
// ];

function CarruselImgMaterial({imagenesMaterial}){

    const state= {
        responsive:{
            0: {
                items: 1,
            },
            576: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 1,
            },
            1200: {
                items: 1,
            },
        },
    }
    
    return(
        <div className="carruselInfoMaterial">
            {imagenesMaterial.length >= 1 &&
                <OwlCarousel className="owl-theme" 
                    items="1"
                    margin={50}
                    autoplay={true}
                    loop
                    nav={true}
                    dots={false}
                    touchDrag={true}
                    animateIn={true}
                    responsive={state.responsive}
                    center={false}
                    //NO CAMBIAR A className, SI SE CAMBIA NO SALEN LAS FLECHAS DE NAVEGACIÓN PARA LAS PÁGINAS
                    navText={[
                        '<div className="carruselInfoMaterial_arrowIcon1"><i className="fa fa-angle-left"></i></div>',
                        '<div className="carruselInfoMaterial_arrowIcon2"><i className="fa fa-angle-right"></i></div>'
                    ]}
                    //  
                    >
                    
                        {imagenesMaterial.map(mat => (
                            <div key={mat.id_imagenProducto} className="item">
                                <img className="carruselInfoMaterial_img" src={mat.ruta} alt="ImagenProducto" />
                            </div>
                        ))}
                </OwlCarousel>
            }
        </div>
    );
}

export {CarruselImgMaterial};