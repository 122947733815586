import React from 'react';

import './DetallesCurso.css';

function DetallesCurso({numeroClases, totalMinutos, visitas}) {
    return(
        <div className="text-center detallesCurso my-5 row">
            <div className='col-xl-6 col-lg-6 col-md-12 col-12 noPadding'>
                <div className="mb-3 detallesCurso-icono_video">
                    <i className="fa fa-video"></i>
                    <span className="mx-3">{`${numeroClases} clases`}</span>
                </div>
                <div className="mb-3 detallesCurso-icono_ojo">
                    <i className="fas fa-eye"></i>
                    <span className="mx-3">{`${visitas} vistas`}</span>
                </div>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-12 col-12 noPadding'>
                <div className="mb-3 detallesCurso-icono_award">
                    <i className="fa fa-award"></i>
                    <span className="mx-3">Certificado disponible</span>
                </div>
                <div className="detallesCurso-icono_clock">
                    <i className="fa fa-clock"></i>
                    <span className="mx-3">{`${totalMinutos} minutos en total`}</span>
                </div>
            </div>
        </div>
    );
}

export {DetallesCurso};