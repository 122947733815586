import React, { useState, useEffect } from "react";
import "../components/notifications/notifications.css";
import { Denthereum } from "../components/Denthereum/Denthereum";
import Notificacion from "../components/notification/Notificacion";
import Swal from "sweetalert2";
import getNotifications from "../services/notifications";
import ReactLoading from "react-loading";
import { Helmet } from "react-helmet-async";

const Notifications = () => {
  let idUsuario = localStorage.getItem("idUsuario");

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // let titulo = document.getElementById("tituloWeb");
    // titulo.innerHTML = 'Cursodontic | Notificaciones';
    window.scrollTo(0, 0);
    getUserNotifications(idUsuario);
  }, []);

  function getUserNotifications(idUsuario) {
    let formData = new FormData();

    formData.append("idUsuario", idUsuario);
    formData.append("accion", "getNotifications");

    getNotifications(formData)
      .then((response) => {
        let _respuesta = JSON.parse(response);
        if (_respuesta.response === "success") {
          // console.log(_respuesta.notificaciones);
          setNotifications(_respuesta.notificaciones);
        }
      })
      .catch((error) => {
        Swal.fire(
          "Error del servidor",
          "Inténtalo de nuevo en uno momento.",
          "error"
        );
      });
  }

  return (
    <div className="container notifications-main-container py-4">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Notificaciones | Cursodontic</title>
        <link rel="canonical" href="https://cursodontic.com" />
        <meta name="description" content="Notificaciones en el sitio web de cursodontic." />

        <meta property="og:title" content="Notificaciones | Cursodontic" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
        <meta property="og:url" content="https://cursodontic.com" />

        <meta property="og:description" content="Notificaciones en el sitio web de cursodontic." />
        <meta property="og:site_name" content="Cursodontic | Educación Dental Profesional" />
        <meta name="twitter:image:alt" content="Cursodontic Educación Dental Profesional" />
      </Helmet>
      <div id="notifications" className="notifications">
        <h2 className="font-bebas text-strong-gray-color">NOTIFICACIONES</h2>
      </div>
      {notifications.length === 0 ? (
        <div className="container my-4">
          <div className="row">
            <div className="col-12">
              <h3 className="fs-4">Oooops... No hay nada nuevo por el momento</h3>
            </div>
          </div>
        </div>
      ) : (
        <div className="notifications-container">
          {notifications.map((notification) => (
            <div key={notification.id_notificacion}>
              <Notificacion notification={notification} />
            </div>
          ))}
        </div>
      )}

      <Denthereum />
    </div>
  );
};

export default Notifications;