import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {

    apiKey: "AIzaSyBXeIYPcuFa6rAlfPrXQ5hs1AkqS3cb9-w",
  
    authDomain: "cursodontic-832e4.firebaseapp.com",
  
    databaseURL: "https://cursodontic-832e4.firebaseio.com",
  
    projectId: "cursodontic-832e4",
  
    storageBucket: "cursodontic-832e4.appspot.com",
  
    messagingSenderId: "42497849146",
  
    appId: "1:42497849146:web:66a1ffc6acf5a99fe08203",
  
    measurementId: "G-ED33CXZB8G"
  
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;