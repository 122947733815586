import React from 'react';

import {CheckoutStepper} from '../components/CheckoutStepper/CheckoutStepper.js';

function Checkout({login, setLogin, getMyBalanceDTH, handleLogin, saldoTokens}){
    return (
        <CheckoutStepper login={login} setLogin={setLogin} getMyBalanceDTH={getMyBalanceDTH} handleLogin={handleLogin} saldoTokens={saldoTokens} />
    );
}

export {Checkout};