import React, { useState, useEffect } from "react";
import './unlockUsersReports.css';
import getComplaints from '../../services/getComplaints';
import UnlockUser from "../UnlockUser/UnlockUser";
import UnhideComments from "../UnhideComments/UnhideComments";

const UnlockUsersReports = () => {
    const idUsuario = localStorage.getItem('idUsuario');

    const [complainComments, setComplainComments] = useState("");
    const [complainUsers, setComplainUsers] = useState("");

    useEffect(() => {
        getAllComplaints(idUsuario);
    }, []);

    function getAllComplaints(idUsuario) {
        let formData = new FormData();

        formData.append('idUsuario', idUsuario);

        getComplaints(formData).then((response) => {
            let _respuesta = JSON.parse(response);

            setComplainComments(_respuesta.hiddenComments);
            setComplainUsers(_respuesta.blockedUsers);
        });
    }

  return (
    <div className="unlockUsersReports">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
                <a className="nav-link active" id="users-tab" data-toggle="tab" href="#users" role="tab" aria-controls="users" aria-selected="true">Usuarios</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="comments-tab" data-toggle="tab" href="#comments" role="tab" aria-controls="comments" aria-selected="false">Comentarios</a>
            </li>
        </ul>
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="users" role="tabpanel" aria-labelledby="users-tab">
                <div className="users__blocked">
                    {complainUsers.length > 0 ? (
                        complainUsers.map((complainUser) => (
                            <UnlockUser key={complainUser.id_comentariosClases+complainUser.nombre} complainUser={complainUser} />
                        ))
                    ) : (
                        <h3 className="fs-4 my-5">Oooops... No hay nada nuevo por el momento</h3>
                    )}
                </div>
            </div>
            <div className="tab-pane fade" id="comments" role="tabpanel" aria-labelledby="comments-tab">
                {complainComments.length > 0 ? (
                    complainComments.map((complainComment) => (
                        <UnhideComments key={complainComment.id_comentariosClases+complainComment.nombre} complainComment={complainComment} />
                    ))
                ) : (
                    <h3 className="fs-4">Oooops... No hay nada nuevo por el momento</h3>
                )}
            </div>
        </div>
    </div>
  )
}

export default UnlockUsersReports;