import React, { useState,useEffect }from 'react';
import './certificate.css';

import CourseProgress from "../courseProgress/CourseProgress";
import { MdDownloadForOffline } from 'react-icons/md';
import getLastClass from '../../services/getLastClass';
import getUserCertificates from '../../services/getCertificate';
import addViewedCourse from "../../services/addViewedCourse";
import { Link } from "react-router-dom";
import { BlobProvider } from "@react-pdf/renderer";
import Swal from 'sweetalert2'
import PDFGeneratorCertificate from '../PDFGeneratorCertificate';
import getProfileData from '../../services/getProfileData';
import UserCertificates from '../userCertificates/UserCertificates';
import ModalVerificarDatosCertificado from '../ModalVerificarDatosCertificado';
import $ from 'jquery'
import updateNombreUsuario from '../../services/updateNombreUsuario';
import addCertificadoAgregado from '../../services/addCertificadoAgregado';
// import config from '../config';

const Certificate = ({idCurso, fotoCurso, promedioVisto, tituloCurso, urlCurso, totalHorasCurso, fechaUltimaClase, usuario}) => {

    let idUsuario = localStorage.getItem('idUsuario');

    const [nombreCursoUrl, setNombreCursoUrl] = useState();
    const [lastClass, setLastClass] = useState(1);
    const [obtuvoCertificado, setObtuvoCertificado] = useState(usuario.obtuvo_certificado);
    const [modalShow, setModalShow] = React.useState(false);

    useEffect(() => {
        getLastCourseData(idUsuario,idCurso);

        let url = urlCurso.split('/');
        setNombreCursoUrl(url[3]);
    },[]);

    function getLastCourseData(idUsuario,idCurso) {

        let formData = new FormData();

        formData.append('idUsuario', idUsuario);
        formData.append('idCurso', idCurso);
    
        getLastClass(formData)
        .then(response => {
          let _respuesta = JSON.parse(response);

          if(_respuesta.response === 'success'){
            setLastClass(_respuesta.lastClass);
          }
        })
        .catch(error => {
            console.log(error);
        })
    }

    function addLastViewedCourse(idCurso) {
        let formData = new FormData();
    
        formData.append('idUsuario', idUsuario);
        formData.append('idCurso', idCurso);
        formData.append("accion", 'insertarCursoVisto');
    
        addViewedCourse(formData)
        .then((responseAdd) => {
          let _respuestaAdd = JSON.parse(responseAdd);
        })
    }

    const generateCertificate = () => {

        // Si ya ha generado un certificado en el pasado, significa que no debe solicitarse
        // su nombre completo, porque ya lo registró y simplemente generamos el certificado
        generarPDF(
            usuario.nombre,
            `${usuario.id_usuario}-${idCurso}`,
            tituloCurso,
            `${fechaUltimaClase}`,
            `${totalHorasCurso}`
        )
    };

    const onSubmitVerificarNombre = async (e)=>{
        e.preventDefault()
        let formData = new FormData(e.target)
        let object = Object.fromEntries(formData)
        object.idUsuario = localStorage.getItem("idUsuario");

        console.log(object)

        Swal.showLoading();

        updateNombreUsuario({values:object})
            .then(res =>{
                console.log(res)
                let _res = JSON.parse(res)
                if(_res.response === "success"){
                    setModalShow(false)
                    Swal.close()
                    setObtuvoCertificado(true)
                    generarPDF(
                        object.nombre_completo,
                        `${usuario.id_usuario}-${idCurso}`,
                        tituloCurso,
                        `${fechaUltimaClase}`,
                        `${totalHorasCurso}`
                    )
                }
            })
        // let url = await PDFGeneratorCertificate({
        //     nombre: usuario.nombre,
        //     codigo: `${usuario.id_usuario}-${idCurso}`,
        //     curso: tituloCurso,
        //     dia: "23 de Mayo del 2023",
        //     horas: "6"
        // })
        // window.open(url)

    }

    const generarPDF = async (nombre, codigo, curso, dia, horas ) =>{

        // Damos de alta en la base de datos que el usuario solicitó su certificado
        // para guardar la hora, la fecha, el id usuario y el id curso que solicitó

        let data = {
            idUsuario: idUsuario,
            id_curso: idCurso
        }

        addCertificadoAgregado({values:data})
            .then(res=>{
                console.log(res)
            })
            .catch(err => console.log(err))

        let url = await PDFGeneratorCertificate({
            nombre: nombre,
            codigo: codigo,
            curso: curso,
            dia: dia,
            horas: horas
        })
        window.open(url)
    }

  return (promedioVisto < 1 ? 
    <div className="col-12 text-center mt-3" key={idCurso+'not'}>
        <img className="certificate-course-image" src={fotoCurso}/>
        <div className="mt-3">
            <p className="fs-5">{tituloCurso}.</p>
        </div>
        <div className="py-2">
            <CourseProgress progress={promedioVisto} />
        </div>
        <div className="mt-2">
            <p className="font-medium text-strong-gray-color fs-6">¡Ya estás cerca de conseguirlo!</p>
        </div>
        <div className="mt-3" onClick={addLastViewedCourse(idCurso)}>
            <Link to={`/mis-cursos/${nombreCursoUrl}/${lastClass}`}>
                <button className="btn certificate-continue-button">
                    Continua aprendiendo
                </button>
            </Link>
        </div>
        <hr/>
    </div>
    :
    <>
        <ModalVerificarDatosCertificado 
            nombre={usuario.nombre}
            onSubmit={onSubmitVerificarNombre}
            setModalShow={setModalShow}
            modalShow={modalShow}
        />

        <div className="col-12 text-center mt-3" key={idCurso+'finished'}>
            <img className="certificate-course-image" src={fotoCurso}/>
            <div className="mt-3">
                <p className="fs-5">{tituloCurso}</p>
            </div>
            <div className="py-2">
                <CourseProgress progress={promedioVisto} />
            </div>
            <div className="mt-2">
                <p className="font-medium text-strong-gray-color fs-6">¡Felicidades por haber finalizado el curso!</p>
            </div>
            <div className='d-flex justify-content-center'>
                {
                    obtuvoCertificado !== null &&
                        <button 
                            className='btn btn-verde d-flex align-items-center justify-content-center'
                            onClick={generateCertificate}    
                        >
                            <i className='fa-solid fa-file-arrow-down mr-3'></i>&nbsp;
                            Generar certificado
                        </button>
                }
                {
                    obtuvoCertificado === null &&
                        <button 
                            className='btn btn-verde text-aqua-color d-flex align-items-center justify-content-center'
                            onClick={()=>setModalShow(true)}
                        >
                            <i className='fa-solid fa-file-arrow-down mr-3'></i>&nbsp;
                            Generar certificado
                        </button>
                }
            </div>
            <hr/>
        </div>
    </>
  )
}

export default Certificate;