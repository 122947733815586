import React from 'react';

import './Materiales.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import {ResultadosItem} from '../ResultadosItem/ResultadosItem';

function Materiales(props){

    const state= {
        responsive:{
            0: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 4,
            },
            1200: {
                items: 5,
            },
        },
    }

    const stateClass= {
        responsive:{
            0: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 2,
            },
        },
    }

    return(
        <div className="infoCursoMateriales">

            <p className="bebas mt-4 mb-3 titulos">MATERIALES</p>

            {props.mats.length >=1 && <OwlCarousel className="owl-theme"
                items="0"
                margin={10}
                autoplay={false}
                loop={false}
                dots={false}
                touchDrag={true}
                animateIn={true}
                responsive={(props.isDescripcion === true) ? stateClass.responsive : state.responsive}
                center={false}
                >

                    {props.mats.map(material => (
                        <ResultadosItem
                            key={material.idProducto} 
                            clase={'verdeColor'}
                            tipo={'MATERIAL'}
                            url={material.imagen}
                            nombre={material.nombre}
                            cripto={material.precio}
                            fiat={material.precio}
                            divisa={material.divisa}
                            urlPage={material.urlPage}
                            type={'material'}
                        />
                    ))}

                </OwlCarousel>
            }
        </div>
    );
}

export {Materiales};