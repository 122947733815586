import React from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Swal from 'sweetalert2'
import ReactLoading from 'react-loading';

import getAddress from '../../services/getAddress';
import getCountries from '../../services/getCountries';
import deleteAddress from '../../services/deleteAddress';

import { ItemDireccion } from '../ItemDireccion/ItemDireccion';
import {ModalDireccion} from '../ModalDireccion/ModalDireccion.js';

import './DireccionesUser.css';

function DireccionesUser(props) {

    let objectValues;
    let idUsuario = localStorage.getItem('idUsuario');

    const [direcciones, setDirecciones] = React.useState([]);
    const [paises, setPaises] = React.useState([]);
    const [pais, setPais] = React.useState([]);
    const [modalShow, setModalShow] = React.useState(false);

    React.useEffect(() => {

        getPaises();
        getDatos();

    }, []);

    function getDatos() {

        let values = new FormData();

        values.append("idUsuario", idUsuario);

        objectValues = Object.fromEntries(values);

        getAddress({ values: objectValues }).then(response => {
            let _respuesta = JSON.parse(response);
            if (_respuesta.response === "error") {

                setDirecciones(false);

            } else {

                setPais(_respuesta.pais);
                setDirecciones(_respuesta.direcciones);

            }

        }).catch((error) => {
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    function getPaises() {

        getCountries().then(response => {
            let _respuesta = JSON.parse(response);
            if (_respuesta.response === "error") {

            } else {
                setPaises(_respuesta.paises);
            }

        }).catch((error) => {
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    function eliminarAddres(direccion) {

        Swal.showLoading();

        let values = new FormData();

        values.append("idUsuario", idUsuario);
        values.append("direccion", direccion);

        objectValues = Object.fromEntries(values);

        deleteAddress({ values: objectValues }).then(response => {
            let _respuesta = JSON.parse(response);
            if (_respuesta.response === "error") {

                Swal.fire({
                    icon: 'error',
                    title: 'Algo salió mal, intenta de nuevo.',
                    showConfirmButton: false,
                    timer: 1500
                })

            } else {

                let elemento = document.getElementById("direccionEnvio"+direccion);
                elemento.innerHTML = "";

                Swal.close();

            }

        }).catch((error) => {
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    const onRadiochange = value => {
        props.setValorDireccion(value);
    };

    return (
        direcciones && direcciones.length === 0 ? 
            
        <div className="d-flex align-items-center justify-content-center w-100 my-5">
            <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem" />
        </div>

            :

        <div className="my-4">

            <ModalDireccion
                show={modalShow}
                onHide={() => setModalShow(false)}
                pais={pais}
                paises={paises}
                getDatos={getDatos}
            />

            <span className="text-center btn-rojo py-1 px-2" onClick={() => setModalShow(true)}>
                <i className="fas fa-plus mr-2"></i>
                <span>Añadir dirección</span>
            </span>

            <p className="my-4">Tus direcciones:</p>

            {direcciones !== false ?
                <FormControl>
                    {props.setValorDireccion(direcciones[0].direccion)}
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={direcciones[0].direccion}
                        name="radio-buttons-group"
                    >

                        {direcciones.map(
                            address => (
                                <div id={"direccionEnvio"+address.direccion} key={address.direccion} className="d-flex align-items-center">
                                    <i className="fas fa-trash-alt eliminarDireccion" onClick={() => eliminarAddres(address.direccion)}></i>
                                    <FormControlLabel onClick={() => onRadiochange(address.direccion)} value={address.direccion} control={<Radio />} label={
                                    <ItemDireccion
                                        direccion={address.direccion}
                                        address={address.address}
                                        referencia={address.referencia}
                                        pais={address.pais}
                                        telefono={address.telefono}
                                        receptor={address.receptor}
                                    />
                                }
                                />
                                </div>
                            )
                        )
                        }

                    </RadioGroup>

                </FormControl>

                :

                <div className="text-center my-4 py-4">
                    <i className="fas fa-map-marker-alt iconDireccion"></i>
                    <p className="mt-3">No tienes ningúna dirección guardada</p>
                    <p>No es necesario añadir tu dirección para los cursos online</p>
                </div>
            }


        </div>
        
    );
}

export { DireccionesUser };