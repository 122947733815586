import $ from 'jquery/dist/jquery.min.js';

const showMenu = () => {
    if( $(".menu__icono.fa-bars").hasClass("d-none") ){
        // OCULTAR MENU
        $(".menu__icono.fa-bars").removeClass("d-none");
        $(".menu__icono.fa-times").addClass("d-none");

        document.getElementById("mySidenav").style.width = "0";
        //document.getElementById("main").style.marginRight= "0";
        $("#mySidenav").removeClass("active");
    } else {
        // MOSTRAR MENU
        $(".menu__icono.fa-bars").addClass("d-none");
        $(".menu__icono.fa-times").removeClass("d-none");

        document.getElementById("mySidenav").style.width = "100%";
        //document.getElementById("main").style.marginRight = "210px";
        $("#mySidenav").addClass("active");
    }
}

const showUsuarioMenu = (e) => {
    e.stopPropagation();
    if($(".desplieguePerfil").hasClass("hidden")){
        $(".desplieguePerfil").removeClass("hidden")
        $(".desplieguePerfil").removeClass("hide")
    } else {
        $(".desplieguePerfil").addClass("hidden")
        $(".desplieguePerfil").addClass("hide")
    }
}

export {
    showMenu, showUsuarioMenu
}