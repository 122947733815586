import React from 'react';
import { Link } from 'react-router-dom';
import './DentherumEarned.css';
import DentherumLogo from '../../assets/images/LogoDenthereumAmarillo.png';
import { useWeb3React } from '@web3-react/core'
import ButtonConnectWallet from '../ButtonConnectWallet';
import getInformacionTokenUsuario from './../../services/getInformacionTokenUsuario'

const DentherumEarned = (props) => {

  const formatBlanaces = Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2
  });

  const [tokensEntregados, setTokensEntregados] = React.useState(0)
  const { active } = useWeb3React()
  const idUsuario = localStorage.getItem('idUsuario')

  const renderSaldoTokens = () => {
    return(
      <>
        <div>
          <span className="font-regular">Saldo de tokens</span>
          <h2 className="font-bold m-0">
            {formatBlanaces.format(props.saldoTokens)}
            <small className='font-sm'> DTH</small>
          </h2>
        </div>

        <img src={DentherumLogo} className='img-fluid' alt="dentherum-logo" width="50px"/>
      </>
    )
  }

  const getInformacionTokens = () =>{
    let data = { idUsuario: idUsuario }
    getInformacionTokenUsuario({values:data})
      .then(respuesta => {
        let _respuesta = JSON.parse(respuesta)
        setTokensEntregados(_respuesta.tokensEntregados)
      })
  }

  React.useEffect(()=>{
    getInformacionTokens()
  },[])

  return (
    <div className="conainer progress-card progress-card--dark p-4">

      <h2 className="font-bebas color-azul">Recompensas</h2>

      <div className="d-flex align-items-center justify-content-between">
        { active && renderSaldoTokens() }

        {/* { !active && 
          
        } */}
        <div className={`w-100 ${active && 'hidden'}`}>
          <ButtonConnectWallet handleLogin={props.handleLogin}/> 
          <p className='font-sm mt-2'>Al conectarla, se vincualará con tu cuenta</p>
        </div>
      </div>
      
      <hr className="my-2" />

      <div className='d-flex mb-3'>
        <div className='w-50'>
          <span className="font-regular">Ingresos</span>
          <h5 className="font-bold m-0">
            {props.ingresoTokens}
            <small className='font-sm'> DTH</small>
          </h5>
        </div>

        <div className='w-50'>
          <span className="font-regular">Entregado</span>
          <h5 className="font-bold m-0">
            {tokensEntregados}
            <small className='font-sm'> DTH</small>
          </h5>
        </div>
      </div>

      <Link to="/denthereum" className="font-light text-what-is-dentherum">
          ¿Qué es Dentherum?
      </Link>
    </div>
  )
}

export default DentherumEarned;