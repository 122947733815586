import React from 'react';

import Modal from 'react-bootstrap/Modal'

import './ModalSeguirComprando.css';
import { Link } from 'react-router-dom';

function ModalSeguirComprando(props){

    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalSeguirComprando"
            >
                <Modal.Header closeButton className="bg-black text-white">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Agregaste a tu carrito
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-center'>
                        <img src={props.imagen} alt="imagenArticulo"/>
                        <div>
                            <h4>{(props.isProduct) ? "Producto:" : "Curso:"}</h4>
                            <p>{props.nombreArticulo}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-rojo w-100 btn-rojo" onClick={props.onHide}><i className="fas fa-shopping-bag mx-2"></i> Seguir comprando</button>
                    <Link to={"/pago-carrito"} className="w-100">
                        <button type="button" className="btn btn-outline-verde w-100 text-white" onClick={props.onHide}><i className="fas fa-shopping-cart mx-2"></i> Ver carrito</button>
                    </Link>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export {ModalSeguirComprando};