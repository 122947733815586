import React from 'react'
import "./QueEsperarDenthereum.css"

import SubtituloDenthereum from "../SubtituloDenthereum"
import ImagenesQueEsperarDenthereum from '../ImagenesQueEsperarDenthereum'
import DescripcionesQueEsperarDenthereum from '../DescripcionesQueEsperarDenthereum'

export default function QueEsperarDenthereum (){
    return(

        <div className="container-fluid noPadding mt-4">

            <div className="container queEsperar__container">

                <SubtituloDenthereum 
                    text="QUE ESPERAR DE DENTHEREUM" 
                    estilo="infoDenthereum__subtitulo" 
                />

                <div className="row">

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 noPadding-sm noPadding-md noPadding-lg">

                        <ImagenesQueEsperarDenthereum />

                    </div>

                    <div className="col-lg-6 noPadding-sm noPadding-md noPadding-lg">                
                        
                        <DescripcionesQueEsperarDenthereum />

                    </div>

                </div>

            </div> 

        </div>
    )
}