import React from 'react'
import "./DescripcionesQueEsperarDenthereum.css"

export default function DescripcionesQueEsperarDenthereum (){
    return (

            <ul className="infoDenthereum__descripcionDenthereum noPadding">

                <div className="row">

                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 noPadding-sm">

                    <li>

                        <div className="descripcionDenthereum__lista1"> 

                            <i className="fa-solid fa-circle lista2__iconoCircle"></i>
                            {/* <Icon className="lista1__iconoCircle" icon="fa-solid fa-circle" /> */}
                                    
                            <h3 className="lista1__subtitulo noMargin">

                                APRENDE Y GANA CRYPTO

                                <i className="fa-solid fa-hand-holding-dollar lista1__icono text-rojo"></i>

                            </h3>

                        </div>

                        <p className="descripcionDenthereum__parrafos">
                                        
                            Por cada curso que finalices en la plataforma ganarás Denthereum. Te lo enviaremos a tu wallet los primeros 5 días de cada mes.
                                    
                        </p>

                    </li>

                </div>

                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 noPadding-sm">

                    <li>

                        <div className="descripcionDenthereum__lista2"> 
                                        
                            <i className="fa-solid fa-circle lista2__iconoCircle"></i>
                                    
                            <h3 className="lista1__subtitulo noMargin">

                                GENERA VALOR

                                <i className="fa-solid fa-people-arrows-left-right lista2__icono text-verde"></i>

                            </h3>

                        </div>

                        <p className="descripcionDenthereum__parrafos">
                                        
                            Intercambia tus Denthereum por otra criptomoneda de valor, como BNB. También puedes enviarla a tus amigos y familiares.
                                    
                        </p>

                    </li>

                </div>

                </div>

                <div className="row">

                    <div className="col-xs-0 col-sm-2 col-md-3 col-lg-2 col-xl-2 noPadding-sm">

                    </div>

                    <div className="col-xs-12 col-sm-8 col-md-6 col-lg-8 col-xl-8 noPadding-sm">

                        <li>

                            <div className="descripcionDenthereum__lista3"> 
                                            
                                <i className="fa-solid fa-circle lista3__iconoCircle"></i>
                                        
                                <h3 className="lista1__subtitulo noMargin">

                                    BENEFICIOS EXCLUSIVOS

                                    <i className="fa-solid fa-crown lista3__icono text-amarillo"></i>

                                </h3>

                            </div>

                            <p className="descripcionDenthereum__parrafos">
                                            
                                Disfruta del 10% de descuento al inscribirte a un curso y el 5% de descuento en materiales pagando con Denthereum.
                                        
                            </p>

                        </li>

                    </div>

                    <div className="col-xs-0 col-sm-2 col-md-3 col-lg-2 col-xl-2 noPadding-sm">

                    </div>

                </div>

            </ul>

        
        
    )
}