import React from 'react'

import './ItemDireccion.css';

function ItemDireccion(props) {
    return (
        <div className="direccionContainer my-2">
            <p className="direccionContainer_receptor">Recibe: {props.receptor}</p>
            <p className="direccionContainer_telefono">Teléfono: {props.telefono}</p>
            <p className="direccionContainer_direccion">{props.address}</p>
            <p className="direccionContainer_direccion">Referencia: {props.referencia}</p>
        </div>
    );
}

export { ItemDireccion };