import React, { useState, useEffect } from 'react';

import './PlanEstudios.css';

import { ClasesItem } from '../ClasesItem/ClasesItem.js';

// const clases = [
//     { text: 'Presentacion', id: 1},
//     { text: '1. Introduccion', id: 2},
//     { text: '2. Materiales', id: 3},
//     { text: '3. Impresion del modelo', id: 4},
//     { text: '4. Diseno del protector bucal bajo impacto', id: 5},
//     { text: '4.1 Diseno del protector bucal bajo impacto', id: 6},
//     { text: '4.1 Diseno del protector bucal bajo impacto', id: 7},
//     { text: '4.1 Diseno del protector bucal bajo impacto', id: 8},
// ];

function PlanEstudios({ clases }) {
    // console.log(clases[0].tituloClase);
    const [active, setActive] = useState(false);

    useEffect(() => {
        showClasses(clases, active);
    }, []);

    function showClasses(clases, active) {

        switch (active) {
            case true:
                return (
                    <div className="infoCursoPlanEstudios-container py-2 px-3" id="show">
                        {clases.map(clase => (
                            <div key={clase.id_clase}>
                                <ClasesItem 
                                    idKey={clase.id_clase} 
                                    tituloClase={clase.tituloClase}
                                    duracionMin={clase.duracionMin}
                                    />
                            </div>
                        ))}
                        <div className="text-center" onClick={ onClickShow }>
                            <i className="fa fa-chevron-up floating infoCursoPlanEstudios-arrow"></i>
                        </div>
                    </div>
                )
            break;
            
            case false:
                return (
                    <div className="infoCursoPlanEstudios-container py-2 px-3" id="show">
                        {clases.map(clase => (
                            (clase.ordenClases < 8)? 
                            <div key={clase.id_clase}>
                                <ClasesItem 
                                    idKey={clase.id_clase} 
                                    tituloClase={clase.tituloClase}
                                    duracionMin={clase.duracionMin}
                                />
                            </div>
                            :
                            <div key={clase.id_clase}></div>
                        ))}
                        <div className="text-center" onClick={ onClickShow }>
                            <i className="fa fa-chevron-down floating infoCursoPlanEstudios-arrow"></i>
                        </div>
                    </div>
                )

            break;
        }

    }

    function onClickShow() {
        setActive(!active);
        document.getElementById('classes').scrollIntoView();
    }

    return(
        <div className="infoCursoPlanEstudios" id="classes">

            <p className="bebas titulos mb-3">PLAN DE ESTUDIOS</p>

            { showClasses(clases, active) }

        </div>
    );
}

export {PlanEstudios};