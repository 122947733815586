import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import DenthereumFoundingArtifact from "./contracts/artifacts/DenthereumFounding";
import { ethers } from "ethers";
const { address, abi } = DenthereumFoundingArtifact;

const useDenthereumFounding = () => {
    const { active, library, chainId } = useWeb3React();

    const denthereumFounding = useMemo(()=>{
        if(active){
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            return new ethers.Contract(address[56], abi, provider)
        }
        
    },[active, chainId])

    return denthereumFounding;
}

export default useDenthereumFounding;