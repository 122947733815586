import React from 'react';

import './MarketplaceCursos.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { CursosItem } from '../CursosItem/CursosItem.js';
import { Denthereum } from '../Denthereum/Denthereum.js';
import { MetodosPago } from '../MetodosPago/MetodosPago.js';
import { Membresia } from '../Membresia/Membresia';

import Swal from 'sweetalert2'

import getMarketplaceCourses from "./../../services/getMarketplaceCourses";

import ReactLoading from 'react-loading';

function MarketplaceCursos(props) {

    const [cursos, setCursos] = React.useState([]);

    React.useEffect(() => {
        getCursos()
    }, []);

    function getCursos() {
        getMarketplaceCourses().then(response => {
            let _respuesta = JSON.parse(response);
            if (_respuesta.response === "error") {
                Swal.fire(
                    'Error al cargar los cursos',
                    'Inténtalo de nuevo recargando la página.',
                    'warning'
                )
            } else {
                setCursos(_respuesta.cursos);
            }

        }).catch((error) => {
            // Swal.fire(
            //     'Error del servidor',
            //     'Inténtalo de nuevo en unos momentos.',
            //     'error'
            // )
            console.log(error)
        })
    }

    const state = {
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
    }

    return (
        <>

            {cursos.length === 0 ?

                <div className="circularBarProgressIndicator">
                    <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem" />
                </div> :
                <div className="marketplaceCursos">

                    <div className="marketplaceCursos-gradient container-fluid">

                        <div className="container">
                            <p className="bebas marketplaceCursos-titulo">CURSOS</p>
                            <p className="marketplaceCursos-subtitulo mb-2">Expertos en educación dental, aprende y gana Denthereum</p>
                        </div>

                    </div>

                    <div className="container">
                        {cursos.length >= 1 && cursos.map(curso => (
                            <div key={curso.titulo}>
                                <p className="bebas mt-5 mb-3 mx-2 titulos"><i className={`${curso.icono} marketplaceCursos-icono`}></i> {curso.titulo}</p>
                                <OwlCarousel className="owl-theme"
                                    autoplay={false}
                                    loop={true}
                                    dots={true}
                                    touchDrag={true}
                                    animateIn={true}
                                    responsive={state.responsive}
                                    center={false}
                                    stagePadding={30}
                                    margin={10}
                                >

                                    {
                                        curso.cursos.map(
                                            course => (
                                                <CursosItem
                                                    key={course.idCurso}
                                                    idCurso={course.idCurso}
                                                    titulo={course.tituloCurso}
                                                    precio={course.precio}
                                                    divisa={course.divisa}
                                                    url={course.url}
                                                    ponente={course.ponente}
                                                    categoria={course.categoria}
                                                    rate={course.rate}
                                                    alumnos={course.alumnos}
                                                    opiniones={course.numOpiniones}
                                                    urlCorta={course.urlCorto}
                                                    accesoMembresia={course.accesoMembresia}
                                                />
                                            )
                                        )
                                    }

                                    {/* {console.log(curso.cursos)} */}

                                </OwlCarousel>
                            </div>
                        ))}
                    </div>

                    <div className="container">

                        <hr />

                        {/* <MetodosPago /> */}

                        <Membresia login={props.login} setModalLogin={props.setModalLogin} />

                        <Denthereum />

                    </div>

                </div>
            }
        </>

    );
}

export { MarketplaceCursos };