import React from 'react';

// Librerías
import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import $ from 'jquery/dist/jquery.min.js'
import Swal from 'sweetalert2'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { NoEthereumProviderError } from '@web3-react/injected-connector'
import { UserRejectedRequestError } from '@web3-react/injected-connector'
import ReactLoading from 'react-loading'

// Estilos
import './App.css';

// Páginas
import PaginaPrincipal from './pages/PaginaPrincipal';
import { InfoCurso } from './pages/InfoCurso';
import { InfoMaterial } from './pages/InfoMaterial';
import { Marketplace } from './pages/Marketplace';
import Dashboard from './pages/Dashboard';
import MyCourses from './pages/MyCourses';
import CourseView from './pages/CourseView';
import MyOrders from './pages/MyOrders';
import DetalleCompra from './components/detalleCompra/DetalleCompra';
import BuyDentherum from './pages/BuyDentherum';
import MenuLateral from './pages/MenuLateral';
import Membresia from './pages/Membresia';
import {Checkout} from './pages/Checkout';
import {CheckoutCarrito} from './pages/CheckoutCarrito';
import MyProfile from './pages/MyProfile';
import Notifications from './pages/Notifications';
import DenthereumLanding from './pages/DenthereumLanding';

import {Resultados} from './pages/Resultados';
import { Carrito } from './pages/Carrito';
import {RecuperarPassword} from './pages/RecuperarPassword';

import PurchaseReceipt from './pages/PurchaseReceipt';
import FailedPayment from './pages/FailedPayment';

// Componentes
import Header from './components/Header';
import WhatsAppIcon from './components/WhatsAppIcon';
import { BrowserRouter, Route, Switch, Redirect, Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import DesplieguePerfil from './components/DesplieguePerfil';
import AvisoCookies from './components/AvisoCookies';
import LoginCursodontic from './components/LoginCursodontic';

// UTILIDADES
import { showMenu, showUsuarioMenu } from './utils/menu'
import  firebase  from './utils/Firebase/firebase.js';
import { connector } from './web3/config';
import useLoginWallet from './web3/useLoginWallet'
import useDenthereum from './web3/useDenthereum'
import getInformacionTokenUsuario from './services/getInformacionTokenUsuario'
import { sendPageView } from './utils/facebookPixelEvents';
import { Helmet } from 'react-helmet-async';
import Prometeo from './pages/Prometeo';
library.add(fas);

export default function App() {

    const [login, setLogin] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [ingresoTokens, setIngresoTokens] = React.useState(0)
    const [saldoTokens, setSaldoTokens] = React.useState(0)

    const denthereum = useDenthereum()

    const [modalLoginShow, setModalLogin] = React.useState(false);

    const { activate, deactivate, active, account, error } = useWeb3React()

    const isUnsupportedChain = error instanceof UnsupportedChainIdError;
    const isNoEthereumProviderError = error instanceof NoEthereumProviderError;
    const isUserRejectedRequestError = error instanceof UserRejectedRequestError;
    
    const history = useHistory()

    $(document).click(function(e) {
        // hide popup
        if(!$(".desplieguePerfil").hasClass("hidden")){
            $(".desplieguePerfil").addClass("hidden")
            $(".desplieguePerfil").addClass("hide")
        } 
    });

    const handleDisconnectWallet = () => {
        deactivate()
        localStorage.removeItem('previouslyConnected')
    }

    const handleLogout =() => {
        firebase.auth().signOut();
        try{
            localStorage.clear();
            setLogin(false)
            history.push("/")
            if(localStorage.getItem('previouslyConnected') === 'true')
                handleDisconnectWallet()
        } catch (err){

        }
    }

    const handleLogin = (data) => {

        if(data.response !== "error"){

            if(data.action === 'update'){
                Swal.fire({
                    icon: "success",
                    title: "Wallet vinculada",
                    text: "Tus recompensas se transferirán a la dirección: "+account,
                    showConfirmButton:false,
                    timer:2500
                });
                
            } else {

                if(data.idUsuario)
                    localStorage.setItem('idUsuario', data.idUsuario);
                if(data.tipoUsuario)
                    localStorage.setItem('tipoUsuario', data.tipoUsuario);
                if(data.nombre)
                    localStorage.setItem('nombre', data.nombre);
                if(data.statusMembresia)
                    localStorage.setItem('statusMembresia', data.statusMembresia);
                if(data.email)
                    localStorage.setItem('correoUser', data.email);
                if(data.fotoPerfil)
                    localStorage.setItem('fotoPerfil', data.fotoPerfil);
                
                setModalLogin(false)
                setLogin(true)
                Swal.close()
                return
            }
        } else {
            if(data.message === 'wallet_already_registered'){
                Swal.fire({
                    icon: "warning",
                    title: "Wallet pertenece a otro usuario",
                    text: "Detectamos que la dirección de la wallet ya está vinculada a un usuario de cursodontic, sí eres tu y quieres cambiarlo, contáctanos",
                });
                localStorage.removeItem('previouslyConnected')
                deactivate()
                return
            }
        }
        Swal.close()
    }

    const connectWallet = React.useCallback(()=>{
        console.log('que hay')
        useLoginWallet({account, handleLogin})
        setLoading(false)
        setLogin(true)
        activate(connector)
    }, [activate]) 

    const getMyBalanceDTH = async()=>{
        const _balanceDTH = await denthereum.balanceOf(account);
        setSaldoTokens((_balanceDTH/1e18).toFixed(2))
    }

    const getInformacionTokens = () =>{
        let idUsuario = localStorage.getItem('idUsuario')
        let data = { idUsuario: idUsuario }
        getInformacionTokenUsuario({values:data})
            .then(respuesta => {
                console.log(respuesta)
                let _respuesta = JSON.parse(respuesta)
                setIngresoTokens(_respuesta.ingresosTokens)
            })
            .catch(err =>{
                // La sospecha es que se hace la excepción porque el idUsuario está vencido
                // Y en el backend no sigue, entonces tienen que volver a iniciar sesión.
                console.error(err)
                handleLogout()
                Swal.fire({
                    title: "Tu sesión expiró",
                    text: "Accede de nuevo a tu cuenta",
                    icon: "info",
                    timer: 3000,
                    showConfirmButton:false
                });
            })
    }
        
    React.useEffect(() => {
        sendPageView();
    },[window.location]);

    React.useEffect(() => {
        // Obtenemos la data del idUsuario si es que existe
        const idUsuario = localStorage.getItem('idUsuario');
        const isWalletPreviouslyConnected = localStorage.getItem('previouslyConnected')
        // Primero nos preguntamos, se ha conectado previamente con su wallet?
        // Y si tiene un idUsuario registrado, eso significa que todo bien con su conexion a la blockchain
        if(isWalletPreviouslyConnected === 'true' && idUsuario){
            // Volvemos a conectar e inicializamos todo en esa funcion
            connectWallet()
        }

        if(isWalletPreviouslyConnected === 'true' && !idUsuario){
            // Volvemos a conectar e inicializamos todo en esa funcion
            activate(connector)
            setLoading(false)
            // setLogin(true)
        }
        
        // Si no se ha conectado previamente con la blockchain, aunque no haya tenido exito
        // pero tiene un idUsuario, significa que se logueo con medios tradicionales
        if(!isWalletPreviouslyConnected && idUsuario){
            setLogin(true)
            setLoading(false)
        }
        
        // Esto significa que previamente intentó conectarse con su wallet sin exito
        // Por la blockchain o no tener un proveedor de web3, en ese caso mostrar la página
        if((isWalletPreviouslyConnected == 'true' && !idUsuario) || (!isWalletPreviouslyConnected && !idUsuario))
            setLoading(false)


    }, []);

    React.useEffect(()=>{
        // Cada vez que se actualice la cuenta, verificamos si esta en una blockchain soportada
        // Swal.hideLoading()
        if(isUnsupportedChain){
            Swal.fire({
                icon: "info",
                title: "Blockchain no soportada",
                text: "Para utilizar Denthereum Token cambia a Binance Smart Chain",
                showConfirmButton: false,
                timer: 3000,
            });
            // deactivate()
        }

        if(isUserRejectedRequestError){
            Swal.fire({
                icon: "info",
                title: "Permisos rechazados",
                text: "Puedes intentarlo de nuevo para conectarte",
                showConfirmButton: false,
                timer: 2000,
            });
            deactivate()
        }
        if(isNoEthereumProviderError){
            Swal.fire({
                icon: "info",
                title: "No tienes un proveedor de wallet",
                text: "Te recomendamos instalar Metamask o Brave",
            });
            deactivate()
        }

    },[error])

    React.useEffect(()=>{
        if(active){
            getMyBalanceDTH()
        }
    },[account, active])

    React.useEffect(()=>{
        if(login){
            getInformacionTokens()
        }
    },[login])

    return (

        loading 
        ? 
            <div className="circularBarProgressIndicator">
                <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem" />
            </div> 
        :
            <BrowserRouter>

                <AvisoCookies />

                <DesplieguePerfil
                    showUsuarioMenu={ showUsuarioMenu } 
                    handleLogout={ handleLogout }
                    ingresoTokens={ ingresoTokens }
                    saldoTokens={ saldoTokens }
                />

                <MenuLateral showMenu={ showMenu } />
                
                <Header 
                    login={login}
                    handleLogin={handleLogin}
                    modalLoginShow={modalLoginShow}
                    setModalLogin={setModalLogin}
                />

                <WhatsAppIcon />

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Cursodontic | Educación Dental Profesional</title>
                    <link rel="canonical" href="https://cursodontic.com" />
                    <meta name="description" content="Cursodontic es una plataforma de educación dental profesional, donde podrás encontrar cursos, clases y material de estudio para mejorar tus habilidades dentales." />

                    <meta property="og:title" content="Cursodontic | Educación Dental Profesional" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
                    <meta property="og:url" content="https://cursodontic.com" />

                    <meta property="og:description" content="Cursodontic es una plataforma de educación dental profesional, donde podrás encontrar cursos, clases y material de estudio para mejorar tus habilidades dentales." />
                    <meta property="og:site_name" content="Cursodontic | Educación Dental Profesional" />
                    <meta name="twitter:image:alt" content="Cursodontic Educación Dental Profesional" />
                    {/* <meta name="keywords" content='Cursos Online, Odontología, Técico Dental' /> */}
                </Helmet>

                <div className="marginGeneral" id="sectionGeneral">
                    
                    <Switch>

                        <Route path="/mis-cursos/:nombre_curso/:id_clase" 
                            render={ ()=>{
                                return login ?
                                <CourseView 
                                    setIngresoTokens={setIngresoTokens}
                                    ingresoTokens={ingresoTokens}
                                />
                                :
                                <Redirect to="/"/>
                            } }>
                        </Route>

                        <Route path="/resultados/:busqueda" exact
                            render={ ()=><Resultados />}>
                        </Route> 

                        <Route path="/cursos/:nombre_curso" exact
                            render={ ()=><InfoCurso login={login} setLogin={setLogin} setModalLogin={setModalLogin} />}>
                        </Route> 
                        
                        <Route path="/materiales/:nombre_categoria/:nombre_producto" exact
                            render={ ()=><InfoMaterial login={login} setModalLogin={setModalLogin} />}>
                        </Route>

                        <Route path="/marketplace" exact>
                            <Marketplace vista="general" login={login} setModalLogin={setModalLogin} />
                        </Route>

                        <Route path="/cursos" exact>
                            <Marketplace vista="cursos" login={login} setModalLogin={setModalLogin} />
                        </Route>

                        <Route path="/materiales" exact>
                            <Marketplace vista="materiales" login={login} setModalLogin={setModalLogin} />
                        </Route>

                        <Route path="/membresia" exact>
                            <Membresia login={login} setModalLogin={setModalLogin} />
                        </Route>

                        <Route path="/login" exact
                            render={ ()=>{
                                return !login ?
                                <LoginCursodontic
                                    login={login} 
                                    handleLogin={handleLogin}
                                />
                                :
                                <Redirect to="/mis-cursos"/>
                                
                            }}>

                        </Route>

                        <Route path="/mis-cursos" exact
                            render={ ()=>{
                                return login ?
                                <MyCourses/>
                                :
                                <Redirect to="/"/>
                            } }>
                        </Route>

                        <Route path="/dashboard" exact
                            render={ ()=>{
                                return login ?
                                <Dashboard 
                                    handleLogin={handleLogin} 
                                    ingresoTokens={ ingresoTokens }
                                    saldoTokens={ saldoTokens }    
                                />
                                :
                                <Redirect to="/"/>
                            } }>
                        </Route>

                        <Route path="/checkout/:nombreCurso" exact>
                            <Checkout 
                                login={login} 
                                setLogin={setLogin} 
                                getMyBalanceDTH={getMyBalanceDTH}
                                handleLogin={handleLogin}
                                saldoTokens={saldoTokens}
                            />
                        </Route>

                        <Route path="/pago-carrito" exact
                            render={ ()=>{
                                return login ?
                                <CheckoutCarrito 
                                    getMyBalanceDTH={getMyBalanceDTH}
                                    handleLogin={handleLogin}
                                    saldoTokens={saldoTokens}
                                />
                                :
                                <Redirect to="/"/>
                            } }>
                        </Route>
                    
                        <Route path="/compra-dentherum" exact>
                            <BuyDentherum />
                        </Route>

                        {/* <Route path="/carrito" exact
                            render={ ()=>{
                                return login ?
                                <Carrito />
                                :
                                <Redirect to="/"/>
                            } }>
                        </Route> */}
                        
                        <Route path="/mis-pedidos" exact
                            render={ ()=>{
                                return login ?
                                <MyOrders />
                                :
                                <Redirect to="/"/>
                            } }>
                        </Route>

                        <Route path="/mis-pedidos/:orden" exact
                            render={ ()=>{
                                return login ?
                                <DetalleCompra />
                                :
                                <Redirect to="/"/>
                            } }>
                        </Route>
                        
                        <Route path="/mi-perfil" exact
                            render={ ()=>{
                                return login ?
                                <MyProfile login={login} />
                                :
                                <Redirect to="/"/>
                            } }>
                        </Route>
                        
                        <Route path="/notificaciones" exact
                            render={ ()=>{
                                return login ?
                                <Notifications />
                                :
                                <Redirect to="/"/>
                            } }>
                        </Route>

                        <Route path="/denthereum" exact>
                            {/* <PaginaPrincipal
                                login={ login }
                            /> */}
                            <DenthereumLanding/>
                        </Route>

                        <Route path="/denthereum/comprar" exact>
                            <BuyDentherum 
                                handleLogin={handleLogin}
                                login={login}
                                saldoTokens={ saldoTokens }
                                getMyBalanceDTH={ getMyBalanceDTH }
                            />
                        </Route>

                        <Route path="/gracias/:checkout_session_id/:tipo_membresia" exact>
                            <PurchaseReceipt />
                        </Route>

                        <Route path="/error-pago" exact>
                            <FailedPayment />
                        </Route>

                        <Route path="/recuperarPass/:aleatorio/:id_usuario" exact>
                            <RecuperarPassword />
                        </Route>

                        <Route path="/prometeo" exact>
                            <Prometeo />
                        </Route>

                        <Route path="/" exact>
                            <PaginaPrincipal
                                login={ login }
                                setModalLogin={ setModalLogin }
                            /> 
                        </Route>

                        <Redirect to="/"/>

                    </Switch>
                    
                </div>
            </BrowserRouter>

    );
}
