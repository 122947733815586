import React from 'react';

import {RecuperarPass} from '../components/RecuperarPass/RecuperarPass.js';

function RecuperarPassword(){

    React.useEffect(() => {
        window.scrollTo(0, 0);
    } , []);
    
    return(
        <div className="container pt-4">
            <RecuperarPass/>
        </div>
    );
}

export {RecuperarPassword};