import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router';
import config from '../../config';

import './classInfo.css';

function ClassInfo (props){

    const history = useHistory()

    let siguiente = parseInt(props.claseActual) +1;
    let anterior = parseInt(props.claseActual) -1;

    const handleChangeClass = (value)=>{
        if(value === 'anterior'){
            history.push("/mis-cursos/"+props.url+anterior)
        } else if(value === 'siguiente') {
            history.push("/mis-cursos/"+props.url+siguiente)
        }
    }

    return (
        <div className="class-info-container container py-4">
            <div className="d-flex justify-content-between">
                <p className='nombreCurso'>
                    {props.curso}
                </p>
                <ul className="pagination justify-content-center">
                    {
                        ((anterior >= 1))
                        &&
                        <li className="page-item">
                            <a className="left-arrow-pagination fs-2"
                                disabled={(siguiente >= props.numberOfClasses) && true}
                                onClick={()=>handleChangeClass('anterior')}
                            >
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </a>
                        </li>
                    }
                    {
                        (siguiente <= props.numberOfClasses)
                        &&
                        <li className="page-item">
                            <a className="right-arrow-pagination fs-2" 
                                onClick={()=>handleChangeClass('siguiente')}
                            >
                                <FontAwesomeIcon icon={faAngleRight} />
                            </a>
                        </li>
                    }
                </ul>
            </div>
            <div className="d-flex justify-content-between">
                <p className="font-semibold nombreClase">{props.nombreClase}</p>
                <p className="font-semibold class-number">{props.claseActual}/{props.temario}</p>
            </div>
        </div>
    )
}

export default ClassInfo;