import React from 'react';
import '../assets/css/MenuLateral.css';
import iconoLogo from '../assets/images/iconodtm2.png';
import { Link, useHistory, useLocation } from 'react-router-dom';

import {RecuperarPassModal} from '../components/RecuperarPassModal/RecuperarPassModal.js';

export default function MenuLateral({ showMenu }){
    const history = useHistory()

    React.useEffect(() => {
        window.scrollTo(0, 0);
    } , []);

    const realizarBusqueda = (e) => {

        e.preventDefault()
        showMenu()
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values)
        const busqueda = objectValues.q

        if(busqueda.trim().length > 0){
            history.push(`/resultados/${busqueda}`)
        }
    }

    return (
        <div id="mySidenav" className="container-fluid marketPlace noPadding sidenav">
        
            <div className="marketPlace__container">
                <form onSubmit={realizarBusqueda} className="marketPlace__input">
                    <i className="fa-solid fa-magnifying-glass input__icono"></i>
                    <input type="text" name="q" className="input__input" placeholder="Buscar en Cursodontic" autoComplete="off" />
                </form>
            </div>
            <hr className="marketPlace__separador noMargin" />

        {/* <!--MARKETPLACE LISTAS-->     */}

            <ul className="listaMarketPlace noPadding">
                <li>
                    <Link to="/prometeo" className="listaMarketPlace__lista" onClick={showMenu}>
                        <div className="listaMarketPlace__textoMarketPlace">
                            <i className="fa-solid fa-robot listaMarketPlace__icono"></i>
                            <span className="listaMarketPlace__texto">Prometeo</span>
                        </div>
                        <i className="fa-solid fa-chevron-right"></i>
                    </Link>
                </li>
                <li>
                    <Link to="/marketplace" className="listaMarketPlace__lista" onClick={showMenu}>
                        <div className="listaMarketPlace__textoMarketPlace">
                            <i className="fa-solid fa-tooth listaMarketPlace__icono"></i>
                            <span className="listaMarketPlace__texto">Marketplace</span>
                        </div>
                        <i className="fa-solid fa-chevron-right"></i>
                    </Link>
                </li>
                <li>
                    <Link to="/cursos" className="listaMarketPlace__lista" onClick={showMenu}>
                        <div className="listaMarketPlace__cursos">
                            <i className="fa-solid fa-video listaMarketPlace__icono"></i>
                            <span className="listaMarketPlace__texto">Cursos</span>
                        </div>
                        <i className="fa-solid fa-chevron-right"></i>
                    </Link>
                </li>
                <li>
                    <Link to="/denthereum" className="listaMarketPlace__lista" onClick={showMenu}>
                        <div className="listaMarketPlace__denthereum">
                            <img src={iconoLogo} className='referenciaCursodontic__img' />
                            <span className="listaMarketPlace__texto">Denthereum</span>
                        </div>
                        <i className="fa-solid fa-chevron-right"></i>
                    </Link>
                </li>
                <li>
                    <Link to="/membresia" className="listaMarketPlace__lista" onClick={showMenu}>
                        <div className="listaMarketPlace__membresia">
                            <i className="fa-solid fa-award listaMarketPlace__icono"></i>
                            <span className="listaMarketPlace__texto">Membresia</span>
                        </div>
                        <i className="fa-solid fa-chevron-right"></i>
                    </Link>
                </li>
            </ul>
            <hr />

            {/* <!--MARKETPLACE FOOTER---> */}

            <footer className="botonMarketPlace">
                <a href="https://api.whatsapp.com/send?phone=+525539892233&text=Hola%2C%20estoy%20aqu%C3%AD%20por%20..." className="botonMarketPlace__button">
                    <div className="buttonMarketPlace">
                        <i className="fa-brands fa-whatsapp buttonMarketPlace__icono"></i>
                        <span className="buttonMarketPlace__texto">Soporte via WhatsApp</span>
                    </div>
                </a>
            </footer>
        </div>
    )
}