// import getCookie from "../validators/getCookie";
// import $ from "jquery";
import firebase from "./Firebase/firebase";
// import config from "../config";
// const URL_API = config.urlApi + "controladores/fbpixel.app.php";
// let idUsuario = localStorage.getItem("idUsuario");

// Datos del curso
function firebaseCompraRealizada(currency, value, name, id) {
  firebase.analytics().logEvent("ecommerce_purchase", {
    currency: currency,
    value: value,
    items: [
      {
        id: id,
        name: name,
        quantity: 1,
        price: value,
      },
    ],
  });
}

function analyticsCompraRealizada(
  divisa,
  paymentIntentId,
  idCurso,
  tituloCurso,
  precioCurso,
  cupon
) {
  window.gtag("event", "purchase", {
    transaction_id: paymentIntentId,
    value: precioCurso,
    currency: divisa,
    tax: precioCurso * 0.16,
    shipping: 0,
    items: [
      {
        id: idCurso,
        name: tituloCurso,
        brand: "Cursodontic",
        variant: "Curso",
        quantity: 1,
        price: precioCurso,
        coupon: cupon,
      },
    ],
  });
}

function analyticsViewContent(idCurso, tituloCurso, precio) {
    window.gtag("event", "view_item", {
    items: [
      {
        id: idCurso,
        name: tituloCurso,
        brand: "Cursodontic",
        quantity: 1,
        price: precio,
      },
    ],
  });
}

function analyticsInicioPago(idCurso, tituloCurso, precio) {
    window.gtag("event", "begin_checkout", {
    items: [
      {
        id: idCurso,
        name: tituloCurso,
        brand: "Cursodontic",
        quantity: 1,
        price: precio,
      },
    ],
    coupon: "",
  });
}

function analyticsProcesoPago(idCurso, tituloCurso, precio) {
    window.gtag("event", "checkout_progress", {
    items: [
      {
        id: idCurso,
        name: tituloCurso,
        brand: "Cursodontic",
        quantity: 1,
        price: precio,
      },
    ],
    coupon: "",
  });
}

export { 
    firebaseCompraRealizada, 
    analyticsCompraRealizada, 
    analyticsViewContent, 
    analyticsInicioPago, 
    analyticsProcesoPago 
};
