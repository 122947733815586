import React from 'react';
import { useState } from 'react';
import AMEX from '../../assets/images/american-express.png';
import BTC from '../../assets/images/bitcoin.png';
import DENT from '../../assets/images/BasicLogoDenthereumAmarillo.png';
import ETH from '../../assets/images/ethereum.png';
import MC from '../../assets/images/mastercard.png';
import OXXO from '../../assets/images/oxxo1.png';
import PP from '../../assets/images/paypal.png';
import VISA from '../../assets/images/visa.png';

import './ComprarAhora.css';

import { Link, useHistory } from 'react-router-dom';

import PickColors from '../pickColors/PickColors.js';
import {InicioSesionModal} from '../InicioSesionModal/InicioSesionModal.js';
import {ModalSeguirComprando} from '../ModalSeguirComprando/ModalSeguirComprando.js';

import Swal from 'sweetalert2'
import $ from 'jquery';
import addItemCart from '../../services/addItemCart.js';
import inscribirCursoMembresia from '../../services/inscribirCursoMembresia.js';
import { sendAddToCart } from '../../utils/facebookPixelEvents';
import inscribirCursoGratis from '../../services/inscribirCursoGratis.js';

function ComprarAhora(props){
    let objectValues;
    let idUsuario = localStorage.getItem('idUsuario');
    let statusMembresia = localStorage.getItem('statusMembresia');

    const [modalShow, setModalShow] = React.useState(false);
    const history = useHistory();

    function onClickSendButton(e) {
        e.preventDefault();

        Swal.showLoading();

        if (props.isCurso === false) {

            if(props.isMixto !== true){
                var dataColors = [];
    
                let cantidadPaquetes = $('#selectColores').val();
    
                let cantidadAcetatos = cantidadPaquetes * 20;
    
                let dato = props.color + "-" + cantidadAcetatos;
    
                dataColors.push(dato);
    
                let values = new FormData();
    
                values.append("idUsuario", idUsuario);
                values.append("producto", props.producto);
                values.append("coloresAcetatos", JSON.stringify(dataColors));
    
                objectValues = Object.fromEntries(values);
                addItemToCart();
                sendAddToCart(props.producto, 'Producto', props.nombreArticulo);
            }

        } else {

            let values = new FormData();

            values.append("idUsuario", idUsuario);
            values.append("idCurso", props.idCurso);

            objectValues = Object.fromEntries(values);
            addItemToCart();
            sendAddToCart(props.idCurso, 'Curso', props.nombreArticulo);

        }

    }

    const handleInscribirCursoMembresia = () =>{
        let data = {
            "idUsuario":idUsuario,
            "idCurso":props.idCurso
        }

        inscribirCursoMembresia({values:data})
            .then((respuesta)=>{
                console.log(respuesta)
                let _respuesta = JSON.parse(respuesta)
                if(_respuesta.response === 'success'){
                    history.push(`/mis-cursos/${props.nombreCurso}/${props.clase}`)
                } else{
                    Swal.fire({
                        icon:'error',
                        text:'No se pudo inscribir al curso, membresia fallida'
                    })
                }
            })
            .catch((e)=>{
                console.log(e)
            })
    }

    const handleInscribirCursoGratis = () =>{
        let data = {
            "idUsuario":idUsuario,
            "idCurso":props.idCurso
        }
        inscribirCursoGratis({values:data})
            .then(respuesta=>{
                // console.log(respuesta)
                let _respuesta = JSON.parse(respuesta)
                if(_respuesta.response === 'success'){
                    history.push(`/mis-cursos/${props.nombreCurso}/${props.clase}`)
                } else{
                    Swal.fire({
                        icon:'error',
                        text:'No se pudo inscribir al curso, recarga la página e inténtalo de nuevo'
                    })
                }
            })
            .catch(e => console.log(e))
    }

    function showButton(cursoComprado) {

        // switch(cursoComprado) {
        //     case cursoComprado:
        //         return(
        //             <Link to={`/mis-cursos/${props.nombreCurso}/${props.clase}`}>
        //                 <button type="button" className="btn btn-light btn-lg w-100 py-2 btn-rojo my-2">VER CURSO</button>
        //             </Link>
        //         )
        //         break;

        //     case (!cursoComprado && props.accesoMembresia == 0):
        //         return(
        //             <Link to={`/checkout/${props.nombreCurso}`}>
        //                 <button type="button" className="btn btn-light btn-lg w-100 py-2 btn-rojo my-2">COMPRAR AHORA</button>
        //             </Link>
        //         )
        //         // if(!cursoComprado && statusMembresia === '1' && parseFloat(props.precio) !== 0) {
        //         //     return(
        //         //         <Link to={`/checkout/${props.nombreCurso}`}>
        //         //             <button type="button" className="btn btn-light btn-lg w-100 py-2 btn-rojo my-2">COMPRAR AHORA</button>
        //         //         </Link>
        //         //     )
        //         // } else {
        //         //     return(
        //         //         <button 
        //         //             type="button" 
        //         //             className="btn btn-light btn-lg w-100 py-2 btn-rojo my-2"
        //         //             onClick={handleInscribirCursoMembresia}
        //         //         >
        //         //             INSCRIBIRME AHORA
        //         //         </button>
        //         //     )
        //         // }
        //         break;
        //     case (!cursoComprado && statusMembresia !== '1' && parseFloat(props.precio) !== 0):
        //         return(
        //             <Link to={`/checkout/${props.nombreCurso}`}>
        //                 <button type="button" className="btn btn-light btn-lg w-100 py-2 btn-rojo my-2">COMPRAR AHORA</button>
        //             </Link>
        //         )
        //         break;
        //     case (!cursoComprado && statusMembresia !== '1' && parseFloat(props.precio) === 0):
        //         return(
        //             <button 
        //                 type="button"
        //                 className="btn btn-light btn-lg w-100 py-2 btn-rojo my-2"
        //                 onClick={idUsuario !== null && idUsuario !== undefined ? handleInscribirCursoGratis : () => props.setModalLogin(true)} 
        //             >
        //                 INSCRIBIRME AHORA
        //             </button>
        //         )
        //         break;
        // }
        if(cursoComprado){
            
            return(
                <Link to={`/mis-cursos/${props.nombreCurso}/${props.clase}`}>
                    <button type="button" className="btn btn-light btn-lg w-100 py-2 btn-rojo my-2">VER CURSO</button>
                </Link>
            )
        } 

        if(!cursoComprado && parseFloat(props.precio) === 0) {
            return(
                <button 
                    type="button"
                    className="btn btn-light btn-lg w-100 py-2 btn-rojo my-2"
                    onClick={idUsuario !== null && idUsuario !== undefined ? handleInscribirCursoGratis : () => props.setModalLogin(true)} 
                >
                    INSCRIBIRME AHORA
                </button>
            )
        }
        
        if(!cursoComprado && statusMembresia === '1' && parseFloat(props.precio) !== 0) {
            if(props.accesoMembresia == 1) {
                return(
                    <button 
                        type="button" 
                        className="btn btn-light btn-lg w-100 py-2 btn-rojo my-2"
                        onClick={handleInscribirCursoMembresia}
                    >
                        INSCRIBIRME AHORA
                    </button>
                )
            } else {
                return(
                    <Link to={`/checkout/${props.nombreCurso}`}>
                        <button type="button" className="btn btn-light btn-lg w-100 py-2 btn-rojo my-2">COMPRAR AHORA</button>
                    </Link>
                )
            }
        }

        if(!cursoComprado && statusMembresia !== '1' && parseFloat(props.precio) !== 0) {
            return(
                <Link to={`/checkout/${props.nombreCurso}`}>
                    <button type="button" className="btn btn-light btn-lg w-100 py-2 btn-rojo my-2">COMPRAR AHORA</button>
                </Link>
            )
        }
    }


    function addItemToCart(){
        addItemCart({values:objectValues}).then((response) => {
            let _response = JSON.parse(response);
            if(_response.response === "error"){
                Swal.fire(
                    'Warning',
                    'No se pudo agregar a tu carrito, inténtalo de nuevo',
                    'error'
                )
            } else if(_response.response === "existe"){
                Swal.fire({
                    icon: "info",
                    text: (props.isCurso === false) ? 'Este producto ya se encuentra en tu carrito' : 'Este curso ya se encuentra en tu carrito',
                    showConfirmButton: false,
                    timer: 2000,
                });
            }else if(_response.response === "success"){
                Swal.close();
                setModalShow(true);
            }
          }).catch((error) => {
            // Swal.fire(
            //     'Error del servidor',
            //     'Inténtalo de nuevo en unos momentos.',
            //     'error'
            // )
            console.log(error)
        })
    }

    return(
        <div className="comprarAhora">

            <ModalSeguirComprando
                show={modalShow}
                onHide={() => setModalShow(false)}
                nombreArticulo={props.nombreArticulo}
                imagen={props.imagen}
                isProduct={props.isProduct}
            />

            {props.isMixto === true ?
                <PickColors
                    btnCarrito={<button onClick={idUsuario !== null && idUsuario !== undefined ? onClickSendButton : () => props.setModalLogin(true)} type="button" className="btn btn-outline-verde w-100 p-2 mt-3">AGREGAR A MI CARRITO</button>}
                    producto={props.producto}
                    setModalShow={() => setModalShow(true)}
                />
                :
                <>
                    
                    {props.isProduct === false && showButton(props.cursoComprado)}

                    {
                    ( (!props.cursoComprado && statusMembresia !== '1' && parseFloat(props.precio) !== 0) ) &&
                        <button 
                            onClick={idUsuario !== null && idUsuario !== undefined ? onClickSendButton : () => props.setModalLogin(true)} 
                            type="button" 
                            className="btn btn-outline-verde w-100 p-2">
                                AGREGAR A MI CARRITO
                        </button>
                    }

                    {
                        (statusMembresia === '1' && props.isProduct === true) &&
                        <button 
                            onClick={idUsuario !== null && idUsuario !== undefined ? onClickSendButton : () => props.setModalLogin(true)} 
                            type="button" 
                            className="btn btn-outline-verde w-100 p-2">
                                AGREGAR A MI CARRITO
                        </button>
                    }
                </>
            }

            <hr className={props.clase}></hr>

            <div className={`text-center ${props.clase}`}>
                <i className="fa fa-lock"></i>
                <span className="mx-2">Pago seguro y protegido</span>
            </div>

            <div className="d-flex justify-content-between mt-4">

                <div className="text-center">
                    <img src={VISA} alt=""/>
                </div>

                <div className="text-center">
                    <img src={MC} alt=""/>
                </div>

                <div className="text-center">
                    <img src={AMEX} alt=""/>
                </div>
                    
                <div className="text-center">
                    <img src={PP} alt=""/>
                </div>

                {/* <div className="text-center">
                    <img src={BTC} alt=""/>
                </div>
                
                <div className="text-center">
                    <img src={ETH} alt=""/>
                </div> */}

                <div className="text-center">
                    <img src={OXXO} alt=""/>
                </div>

                <div className="text-center">
                    <img src={DENT} className='metodoPagoDenthereum' alt=""/>
                </div>
                
            </div>

        </div>
    );
}

export {ComprarAhora};