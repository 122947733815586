import React from 'react';
import { Link } from 'react-router-dom';
import './CheckoutCarritoStepper.css';

import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

import getCart from "../../services/getCart.js";
import deleteCartItem from "../../services/deleteCartItem.js";
import $ from 'jquery';
import { WrapperCarito } from '../PagoCarrito/PagoCarrito.js';

import config from '../../config.js';

import Swal from 'sweetalert2';

import ReactLoading from 'react-loading';

import { ProductosCheckout } from '../ProductosCheckout/ProductosCheckout';
import { DireccionesUser } from '../DireccionesUser/DireccionesUser.js';
import { sendInitiateCheckout } from '../../utils/facebookPixelEvents';
import { analyticsInicioPago } from '../../utils/firebaseAnalytics';
import { Helmet } from 'react-helmet-async';

const steps = [
    {
        label: 'Carrito',
        completed: false
    },
    {
        label: 'Dirección',
        completed: false
    },
    {
        label: 'Pago',
        completed: false
    },
];

function CheckoutCarritoStepper(props) {

    const [activeStep, setActiveStep] = React.useState(0);

    let idUsuario = localStorage.getItem('idUsuario');
    const [generalData, setGeneralData] = React.useState(null);
    const [total, setTotal] = React.useState([]);
    const [divisa, setDivisa] = React.useState([]);
    const [valorDireccion, setValorDireccion] = React.useState(false);
    const [isFinalized, setFinalized] = React.useState(false);
    const [existeMaterial, setExisteMaterial] = React.useState(false);

    const handleNext = () => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    let objectValues;

    React.useEffect(() => {

        // let titulo = document.getElementById("tituloWeb");
        // titulo.innerHTML = 'Carrito | Cursodontic';

        window.scrollTo(0, 0);
        let values = new FormData();

        values.append("idUsuario", idUsuario);

        objectValues = Object.fromEntries(values);

        getCartData();

    }, []);

    function getCartData() {

        getCart({ values: objectValues }).then((response) => {
            // console.log(response);
            let _response = JSON.parse(response);
            // console.log(_response);
            if (_response.response === "error") {
                setTotal(_response.total);
                setDivisa(_response.divisa);
                setGeneralData(_response.carrito);
            } else {
                setTotal(_response.total);
                setDivisa(_response.divisa);
                setGeneralData(_response.carrito);
                if(_response.existeMaterial === 'true'){
                    setExisteMaterial(true)
                }
                // Enviamos los eventos de pixel y google analytics
                // Ya que si lo enviamos fuera, se enviará siempre aunque
                // no exista un producto o curso en el carrito
                sendInitiateCheckout(_response.divisa, _response.total, _response.carrito[0].idCurso, _response.carrito[0].tituloCurso);
                analyticsInicioPago(_response.carrito[0].idCurso, _response.carrito[0].tituloCurso, _response.total);
            }
        }).catch((error) => {
            console.log(error)
            // Swal.fire(
            //     'Error del servidor',
            //     'Inténtalo de nuevo en unos momentos.',
            //     'error'
            // )
        })
    }

    const handleClick = event => {

        let carrito = event.currentTarget.id;

        if ($("#" + event.currentTarget.id).hasClass("hidden")) {
            $("#" + event.currentTarget.id).removeClass("hidden")
        } else {
            $("#" + event.currentTarget.id).addClass("hidden");
        }

        if ($("#load-" + event.currentTarget.id).hasClass("hidden")) {
            $("#load-" + event.currentTarget.id).removeClass("hidden")
        } else {
            $("#load-" + event.currentTarget.id).addClass("hidden");
        }

        setTimeout(function () {

            let valores;

            let values = new FormData();

            values.append("idUsuario", idUsuario);
            values.append("idCarrito", carrito);

            valores = Object.fromEntries(values);

            deleteCartItem({ values: valores }).then((response) => {
                let _response = JSON.parse(response);
                // console.log(_response);
                if (_response.response === "error") {
                    Swal.fire(
                        'Ocurrió un error',
                        'Intenta quitarlo del carrito nuevamente',
                        'info'
                    )
                } else {

                    const element = document.getElementById("elemento-" + carrito);
                    const elementPrecio = document.getElementById("precio-" + carrito).innerHTML;
                    setTotal(parseInt(total) - parseInt(elementPrecio));
                    element.remove();

                    const divProductos = document.getElementById("productosCarrito").innerHTML;

                    if (divProductos.trim().length === 0) {

                        window.location.reload(false);

                    }
                }
            }).catch((error) => {
                // Swal.fire(
                //     'Error del servidor',
                //     'Inténtalo de nuevo en unos momentos.',
                //     'error'
                // )
                console.log(error)
            })

        }, 1200);

    };

    const renderBotonCompra = () =>{

        return(
        <div className={`d-flex align-center-center justify-content-between my-4`}>
            {activeStep > 0 && isFinalized === false &&
            <Button
                className='btn btn-outline-verde'
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
            >
                Regresar
            </Button>
            }
            {activeStep === 0 &&
                <button type="button" onClick={handleNext} className="btn comprarAhora-botonCompraCheckout">
                    {activeStep === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                </button>
            }

            {
            (activeStep === 1 && existeMaterial === false) &&
                <button type="button" onClick={handleNext} className="btn comprarAhora-botonCompraCheckout">
                    {activeStep === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                </button>
            }
            {(activeStep === 1 && valorDireccion !== false && existeMaterial === true) &&
                <button type="button" onClick={handleNext} className="btn comprarAhora-botonCompraCheckout">
                    {activeStep === steps.length - 1 ? 'Finalizar' : 'Continuar'}
                </button>
            }
        </div>
        )
    }

    return (
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Mi carrito | Cursodontic</title>
                <meta name="description" content="Carrito de compras con tus productos seleccionados." />

                <meta property="og:title" content="Mi carrito | Cursodontic" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
                <meta property="og:url" content="https://cursodontic.com" />

                <meta property="og:description" content="Pago de los cursos y productos seleccionados." />
                <meta property="og:site_name" content="Mi carrito | Cursodontic" />
                <meta name="twitter:image:alt" content="Cursodontic" />
            </Helmet>

            {
            generalData ?
                generalData.length !== 0 ?
                    <div>

                        <div className="container-fluid">

                            <div className="row">

                                <div className="col-0 col-sm-0 col-md-1 col-lg-3 col-xl-3"></div>

                                <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 pl-0 divPrincipal">

                                    <Stepper nonLinear={true} activeStep={activeStep} orientation="horizontal" className='my-5'>
                                        {steps.map((step, index) => (
                                            <Step completed={step.completed} className="step-number noPadding font-bold" key={step.label}>
                                                <StepLabel >
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <span>
                                                            {step.label}
                                                        </span>
                                                    </div>
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                    
                                    {/* Renderizamos el boton de continuar */}
                                        {renderBotonCompra()}
                                    {/* <div className='col-md-0 col-sm-0 col-0 my-4'>
                                    </div> */}

                                    <Typography>
                                        {
                                            activeStep === 0 ? <ProductosCheckout carrito={generalData} handleClick={handleClick} /> :
                                                activeStep === 1 ? <DireccionesUser setValorDireccion={setValorDireccion}/> :
                                                    activeStep === 2 ? <WrapperCarito totalPago={total} proceso={''} divisa={divisa} idUsuario={idUsuario} direccion={valorDireccion} setFinalized={setFinalized} getMyBalanceDTH={props.getMyBalanceDTH} handleLogin={props.handleLogin} saldoTokens={props.saldoTokens} /> :
                                                        <div></div>
                                        }
                                    </Typography>

                                    {/* Renderizamos el boton de continuar */}
                                    {
                                        renderBotonCompra()  
                                    }
                                    {/* <div className='col-xl-0 col-lg-0'>
                                    </div> */}

                                </div>

                                <div className="col-0 col-sm-0 col-md-1 col-lg-3 col-xl-3"></div>

                            </div>

                        </div>
                    </div> 
                :
                <div className="text-center mt-4 divPrincipal" id="noExistenProductos">
                    <span className="iconoCarritoCompra">
                        🧐
                    </span>
                    <h2 className="my-3">No haz agregado nada en tu carrito</h2>
                    <p className="text-muted my-4">Visita nuestra tienda y agrega algo para continuar</p>
                    <Link to="/marketplace" className="btn btn-rojo btn-lg my-3">
                        Explorar ahora
                    </Link>
                </div>
            :
                <div className="circularBarProgressIndicator">
                    <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem" />
                </div>
            }

            <div className="container-fluid px-0 bg-black">
                <div className="py-5 footerCheckout px-5 container">
                    <p className="text-center txtAdquirir mb-5">¿Qué estás a punto de adquirir?</p>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div>
                                <div className="row mb-4">
                                    <div className="col-2 my-auto text-center"><i className="fas fa-desktop iconoFooterCheckout"></i></div>
                                    <div className="col pl-0">
                                        <span>Curso completo en HD, para que no pierdas ningún detalle.</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row mb-4">
                                    <div className="col-2 my-auto text-center"><i className="far fa-play-circle iconoFooterCheckout"></i></div>
                                    <div className="col pl-0">
                                        <span>Pdrás acceder al curso de manera ilimitada, así que podrás verlo las veces que tu quieras cuando quieras.</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row mb-4">
                                    <div className="col-2 my-auto text-center"><i className="fas fa-question-circle iconoFooterCheckout"></i></div>
                                    <div className="col pl-0">
                                        <span>Pregunta tus dudas dentro del curso y nuestro ponente la resolverá.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div>
                                <div className="row mb-4">
                                    <div className="col-2 my-auto text-center"><i className="fas fa-award iconoFooterCheckout"></i></div>
                                    <div className="col pl-0">
                                        <span>Envío de un certificado de finalización cuando completes las clases del curso al 100% y dejes una calificación del curso.</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row mb-4">
                                    <div className="col-2 my-auto text-center"><i className="fas fa-mobile-alt iconoFooterCheckout"></i></div>
                                    <div className="col pl-0">
                                        <span>Puedes ver el curso desde nuestra App.</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row mb-4">
                                    <div className="col-2 my-auto text-center"><i className="fas fa-users iconoFooterCheckout"></i></div>
                                    <div className="col pl-0">
                                        <span>Acceso a la comunidad de aprendizaje en línea para dentistas y técnicos dentales más grandes de América Latina.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export { CheckoutCarritoStepper };