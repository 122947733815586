import React from 'react'
import "./SubtituloDenthereum.css"

export default function SubtituloDenthereum ({
    text, estilo, icon, textSpan, estiloSpan
}){

    return(
        <h2 className={estilo}>

            { text }

            <span className={estiloSpan}>

                { textSpan } 

            </span>

            { (icon !== null || icon !== undefined) && <i className={ icon }></i> }

        </h2>

    )
}