/* global BigInt */
import React, { useEffect } from 'react';
import '../components/BuyDentherum/buyDentherum.css';

import cursodonticAmarillo from '../assets/images/LogoDenthereumAmarillo.png';
import imagenBNB from '../assets/images/bnb.png';
import { useWeb3React } from '@web3-react/core'
import ButtonConnectWallet from '../components/ButtonConnectWallet';

import useDenthereum from '../web3/useDenthereum';
import useDenthereumFounding from '../web3/useDenthereumFounding';
import DenthereumFoundingArtifact from '../web3/contracts/artifacts/DenthereumFounding';
import { ethers } from 'ethers';
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet-async';

const { address, abi } = DenthereumFoundingArtifact;

const BuyDentherum = (props) => {

    const formatBlanaces = Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2
    });

    const [balanceBNB, setBalanceBNB] = React.useState(0)
    const [availableDTH, setAvailableDTH] = React.useState(0)
    const [priceDTHinUSD, setPriceDTHinUSD] = React.useState(1)
    const [totalPurchaseBNB, setTotalPurchaseBNB] = React.useState(0)
    const [totalPurchaseDTH, setTotalPurchaseDTH] = React.useState(0)
    const [DTHPriceInBNB, setDTHPriceInBNB] = React.useState(0)
    
    const { active, account } = useWeb3React()

    const denthereum = useDenthereum()
    const denthereumFounding = useDenthereumFounding()

    const [loadingButtonSwap, setLoadingButtonSwap] = React.useState(false)

    const getAvailableDTH = React.useCallback(async ()=>{
        if(denthereum && active){
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const priceUsd = await denthereumFounding.dthPriceUSD()
            const _balanceBNB = await provider.getBalance(account)
            setPriceDTHinUSD(priceUsd.toString())
            setBalanceBNB((_balanceBNB/1e18).toFixed(4))

            props.getMyBalanceDTH()
            getAvailableTokens()
            getPriceDTHInBNB()
        }
    }, [active, account, denthereum])

    const handleAmountDTH = (e)=>{
        let value = parseFloat(e.target.value)
        if(value > availableDTH ){
            // setTotalPurchaseDTH(0)
        } else {
            if(isNaN(value)){
                setTotalPurchaseDTH(0)
                setTotalPurchaseBNB(0)
            } else {
                setTotalPurchaseDTH(value)
                let finalPrice = (DTHPriceInBNB/1e18) * value;
                setTotalPurchaseBNB((finalPrice).toFixed(8))
            }
        }
    }

    const handleSwapTokens = async ()=>{
        setLoadingButtonSwap(true)
        const _totalDth = BigInt(totalPurchaseDTH * 1e18);
        // console.log(_totalDth)
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const contract = new ethers.Contract(address[56], abi, signer)
        contract.buyDenthereum(_totalDth, {
            value: ethers.utils.parseEther(((DTHPriceInBNB/1e18) * totalPurchaseDTH).toString()),
            // gasLimit: 250000, // Gas limit
            gasPrice: ethers.utils.parseUnits('30.0','gwei')
        })
        .then(tr =>{
            // console.log(tr)
            // setLoadingButtonSwap(false)
            tr.wait().then(receipt=>{
                // console.log(receipt)
                Swal.fire({
                    icon:'success',
                    text:`${totalPurchaseDTH} DTH enviados a: ${account}`,
                    title:'Gracias por apoyar al proyecto'
                })
                getAvailableDTH()
                setTotalPurchaseDTH(0)
                setTotalPurchaseBNB(0)
                setLoadingButtonSwap(false)
            })
        })
        .catch(e=>{
            setLoadingButtonSwap(false)
        })
    }

    const getAvailableTokens = async ()=>{
        const availableTokens = await denthereumFounding.getAvailableDTH()
        setAvailableDTH((availableTokens/1e18).toFixed(2))
        // console.log(availableTokens)
    }

    const getPriceDTHInBNB = async ()=>{
        const priceDthInBnb = await denthereumFounding.dthPriceInBNB()
        setDTHPriceInBNB(priceDthInBnb)
    }

    useEffect(()=>{
        if(active)
            setInterval( async ()=>{
                // console.log(priceDthInBnb)
                getAvailableTokens()
                getPriceDTHInBNB()
            },15000)
    },[active, account])

    useEffect(() => {
        // let titulo = document.getElementById("tituloWeb");
        // titulo.innerHTML = 'Cursodontic | Comprar denthereum';
        window.scrollTo(0, 0);
        getAvailableDTH()
    } , [getAvailableDTH, account]);


  return (

    <div className="container-fluid background-div-buy-denthereum py-4 px-0">

        <Helmet>
            <meta charSet="utf-8" />
            <title>Comprar denthereum | Cursodontic</title>
            <meta name="description" content="Convierte dinero en Denthereum en unos cuantos clics." />

            <meta property="og:title" content="Comprar denthereum | Cursodontic" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
            <meta property="og:url" content="https://cursodontic.com" />

            <meta property="og:description" content="Convierte dinero en Denthereum en unos cuantos clics." />
            <meta property="og:site_name" content="Comprar denthereum | Cursodontic" />
            <meta name="twitter:image:alt" content="Comprar denthereum" />
        </Helmet>

        <div className="container vh-100">
            
            <div className="buy-denthereum-card">

                <div className="buy-denthereum-title">

                    <h1 className="buy-denthereum-title__text">
                            
                        INTERCAMBIO
                            
                    </h1>

                    <i className="fa-solid fa-clock-rotate-left history-icon-buy-denthereum"></i>

                </div>

                <div className="buy-denthereum-subtitle">
                        
                    <h2 className="buy-denthereum-subtitle__text">
                        Intercambia tus BNB por&nbsp;
                        <span className="buy-denthereum-subtitle__span">Denthereum</span>
                    </h2>

                </div>

                <div className="info-buy-denthereum py-3">

                    <div className="info-buy-denthereum__myWallet">

                        <div className="myWallet__conexion">

                            <img src={ cursodonticAmarillo } className="conexion__logoDenthereum" alt="logo cursodontic" />
                            
                            <div>
                                <span className="conexion__text">
                                    Denthereum
                                    <small> (DTH)</small>
                                </span>
                                {
                                    active && props.login &&
                                        <p className="misDenthereum__text">
                                            Saldo: ~ { formatBlanaces.format(props.saldoTokens.toString()) }
                                            <small> DTH</small>
                                        </p>
                                }
                            </div>

                            {/* <i className="fa-solid fa-copy conexion__walletCrypto"></i>

                            <img src={ metamask } className="conexion__walletMeta" alt="metamask" /> */}

                        </div>

                    </div>

                    <input 
                        className="denthereum-amount-input form-control"
                        id="denthereum-amount" 
                        placeholder='0' 
                        onChange={handleAmountDTH} 
                        value={totalPurchaseDTH} 
                        type='tel' 
                        pattern='[0-9]+([\,|\.][0-9]+)?'
                    />

                    <div className="datosCompraCrypto">

                        <p className="font-bold">Precio:</p>
                            
                        <p className="font-light">
                            1
                            <small> DTH </small>
                            = {priceDTHinUSD} 
                            <small> USD </small>
                        </p>

                    </div>

                    <div className="datosCompraCrypto">

                        <p className="font-bold">Disponible:</p>
                            
                        <p className="font-light">
                            ~ {formatBlanaces.format(availableDTH)}
                            <small> DTH</small>
                        </p>

                    </div>
                        
                </div>

                <div className="info-buy-denthereum__datosCompraCrypto py-3"> 
                    
                    {
                    active && props.login &&
                    <>
                    <div className="datosCompraCrypto">

                        <p className="font-bold">BNB Balance:</p>
                            
                        <div className='d-flex align-items-center'>
                            <p className="font-light">
                                ~ {balanceBNB}
                                {/* <small> BNB</small> */}
                            </p>
                            <img src={ imagenBNB } className="conexion__logoBnb" alt="logo cursodontic" />
                        </div>

                        </div>

                        <div className="datosCompraCrypto">

                        <p className="font-bold">Total a cambiar:</p>
                            
                        <div className='d-flex align-items-center'>
                            <p className="font-light">
                                ~ {totalPurchaseBNB}
                                <small> BNB</small>
                            </p>
                            <img src={ imagenBNB } className="conexion__logoBnb" alt="logo cursodontic" />
                        </div>

                    </div>
                    <div className="datosCompraCrypto__button d-flex justify-content-center">

                        <button className="button-comprar shadow-sm w-75"
                            onClick={handleSwapTokens}
                            disabled={totalPurchaseDTH===0 || loadingButtonSwap || balanceBNB<totalPurchaseBNB ? true : false}
                        >
                            {/* { !loadingButtonSwap && balanceBNB > 0 || balanceBNB>=totalPurchaseBNB && 'SWAP' } */}
                            { !loadingButtonSwap && (totalPurchaseBNB == 0 ) && (totalPurchaseDTH == 0) && balanceBNB == 0 && 'SWAP' }
                            { !loadingButtonSwap && (balanceBNB > 0 || totalPurchaseBNB == 0 ) && (balanceBNB>totalPurchaseBNB && totalPurchaseDTH >= 0) && 'SWAP' }
                            { !loadingButtonSwap && balanceBNB===0 && totalPurchaseBNB >= 0 && 'SIN FONDOS' }
                            { !loadingButtonSwap && balanceBNB<totalPurchaseBNB && totalPurchaseBNB > 0 && 'BNB INSUFICIENTE' }

                            { loadingButtonSwap &&
                                <ReactLoading type="spin" color="#FFF" height={'25px'} width={'25px'} className="circularBarProgressIndicatorItem" />
                            }
                        </button>

                    </div>
                    </>
                    }

                    <div className={`${active && props.login && 'hidden'}`}>
                        <p className='text-center'>
                            Para continuar conecta tu wallet:
                        </p>
                        <ButtonConnectWallet 
                            handleLogin={props.handleLogin}
                        />
                    </div>

                </div>
            </div>      
        </div>

    </div>

    
    
  )
}

export default BuyDentherum;