import React from 'react'
import "./InicioDenthereum.css"
import LogoAmarillo from '../../assets/images/LogoDenthereumAmarillo.png';

import TituloDenthereum from "../TituloDenthereum"
import SubtituloDenthereum from "../SubtituloDenthereum"

export default function InicioDenthereum (){
    return(

        <div className="container-fluid vistaPrincipal noPadding">

            <div className="container">

            <div className="row pt-5 noPadding-xs">

                <div className="col-xs-12 col-sm-12 col-md-7 noPadding-sm noPadding-md">

                    <div className="vistaPrincipal__textPrincipal mt-xs">

                        <TituloDenthereum 
                            text="La criptomoneda del mundo dental" 
                            estilo="textPrincipal__titulo"  
                        />

                        <SubtituloDenthereum 
                            text="La economía del conocimiento dental ahora en una criptomoneda: Denthereum"
                            estilo="textPrincipal__descripcion" 
                        />

                    </div>

                </div>

                <div className="col-xs-12 col-sm-12 col-md-5 noPadding-sm noPadding-md">

                    <div className="vistaPrincipal__videoPrincipal">

                        <img src={ LogoAmarillo } alt="" className="dimensionImg animacionBounce" />

                    </div>

                </div>

            </div>
       
            </div>

        </div>
    )
}