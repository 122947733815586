import React from 'react'
import "./AprendeYGanaDenthereum.css"
import { Link } from 'react-router-dom';
import imagenBNB from '../../assets/images/bnb.png';
import Logo from '../../assets/images/BasicLogoDenthereumAmarillo.png';

import SubtituloDenthereum from '../SubtituloDenthereum'

export default function AprendeYGanaDenthereum (){
    return(

        <div className="container-fluid noPadding my-5">

            <div className="container">

                <div className="row">

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                        {/* <SubtituloDenthereum 
                            text="APRENDE Y GANA CRYPTO, " 
                            estilo="infoDenthereum__subtitulo3" 
                            textSpan="¡AHORA!" 
                            estiloSpan="subtitulo3__azul" 
                            icon="fa-solid fa-graduation-cap subtitulo3__icono" /> 
                    
                        <p className="subtitulo3Parrafo">
                            
                            Toma alguno de nuestros cursos disponibles y <span className="subtitulo3Parrafo__span">genera tu primer 1 DTH <img src={Logo} alt="" className="subtitulo3Parrafo__logo" /> para usar como desees. </span>

                        </p> */}

                        <SubtituloDenthereum 
                            text="APÓYANOS COMPRANDO DENTHEREUM" 
                            estilo="infoDenthereum__subtitulo3"
                            icon="fa-solid fa-handshake-angle subtitulo3__icono" /> 
                    
                        <p className="subtitulo3Parrafo">
                            Pusimos a la venta sólo 60,000 Denthereum a $1 dólar cada uno.
                        </p>
                        <p className="subtitulo3Parrafo">
                            Puedes apoyar el proyecto comprando con BNB <img src={ imagenBNB } className="conexion__logoBnb noMargin" alt="logo cursodontic" />
                        </p>
                        <p className="subtitulo3Parrafo">
                            Esto nos ayudará a producir más cursos y crear nuestro primer evento de capacitación presencial 🦷
                        </p>

                        <Link to='/denthereum/comprar' className="btn btn-amarillo font-weight-bold text-dark w-100">
                            Quiero apoyar a Cursodontic 🖤
                        </Link>

                    </div>

                    {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 noPadding-sm">

                        <div className="row">

                            <div className="col-xs-0 col-sm-1 col-md-0 col-lg-2 col-xl-2">

                            </div>

                            <div className="col-xs-12 col-sm-10 col-md-12 col-lg-8 col-xl-8">

                                <div className="infoDenthereum__card">
                    
                                    <div className="cursositem mb-2">
                                        
                                        <div className="cursositem-img">
                                                    
                                            <span className="cursositem-img-categoria">Habilidades empresariales</span>
                                                    
                                            <i className="fa-solid fa-circle-play cardBusqueda__play"></i>
                                            
                                        </div>
                                                
                                        <div className=" cursositem-contenido">
                                            
                                            <div className="cursositem-contenido_titulo">
                                                        
                                                <p>Cómo integrar criptomonedas en tu negocio dental</p>
                                                    
                                            </div>
                                                    
                                            <p className="cursositem-contenido_ponente">Un curso de Ricardo Acuña Sandoval</p>
                                            
                                            <div className="cursositem-contenido_datos">
                                                        
                                                <div className="cursositem-contenido_datos-star">
                                                            
                                                    <i className="fas fa-star"></i>
                                                            
                                                    <span className="star__span">4.8(22)</span>
                                                        
                                                </div>
                                                        
                                                <div className="cursositem-contenido_datos-students">
                                                            
                                                    <i className="fas fa-user-graduate"></i>
                                                            
                                                    <span className="students__span">357</span>
                                                        
                                                </div>
                                                
                                            </div>
                                                    
                                            <div className="cursositem__contenidoPrecio ">
                                                    
                                                <p className="cursositem__precio">Precio:</p>
                                                        
                                                <span className="cursositem-cripto">GRATIS</span>
                                                    
                                            </div>
                                                    
                                            <button className="cursositem__buttonInscribir btnPrimario">
                                                        
                                                <p className="buttonInscribir__text">INSCRIBIRME AHORA</p>
                                                    
                                            </button>
                                                
                                        </div>
                                    
                                    </div>

                                </div>

                            </div>

                            <div className="col-xs-0 col-sm-1 col-md-0 col-lg-2 col-xl-2">

                            </div>

                        </div>

                    </div> */}

                </div>

            </div>

        </div>
    )
}