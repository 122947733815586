import React from 'react';

import './ClasesItem.css';

function ClasesItem({ idKey, tituloClase, duracionMin }) {
    // console.log(idKey);
    // console.log(tituloClase);
    // console.log(duracionMin);
    return(
        <div className="d-flex align-items-top justify-content-between my-4" key={ idKey }>
            <div className="d-flex align-items-top">
                <i className="fa fa-video clasesItem-icono_video mt-1"></i>
                <span className="mx-3">{ tituloClase }</span>
            </div>
            <span>{ `${duracionMin}:00` }</span>
        </div>
    );
}

export {ClasesItem};