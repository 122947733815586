import React, { useState, useEffect } from "react";
import "./classCommentMade.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faReply } from "@fortawesome/free-solid-svg-icons";
import { AiFillHeart } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { HiDotsHorizontal } from "react-icons/hi";

import ReplyComment from "../replyComment/ReplyComment";
import LeaveComment from "../leaveComment/LeaveComment";

import addClassAnswer from "./../../services/addClassAnswer.js";

import { Avatar } from "@mui/material";

import $ from "jquery";
import Swal from "sweetalert2";

import commentLikes from "../../services/commentLikes";
import ComplaintUserStepper from "../ComplaintUserStepper/ComplaintUserStepper";
import ComplaintCommentStepper from "../complaintCommentStepper/ComplaintCommentStepper";

function ClassCommentMade(props) {

  let idUsuario = localStorage.getItem("idUsuario");

  const [likeIcon, setLikeIcon] = useState(props.liked);

  const [likesAmount, setLikesAmount] = useState(props.likes);

  const [list, commentsList] = useState([]);

  useEffect(() => {
    handleCommentLikes(likeIcon);
  }, [likeIcon]);

  const onSubmitAnswer = (e) => {
    e.preventDefault();

    Swal.showLoading();

    let values = new FormData(e.target);
    let comentario = $("#textAreaRespuestaClase-" + props.idComentario).val();
    values.append("contenido", comentario);
    values.append("idUsuario", idUsuario);
    values.append("idClase", props.idClase);
    values.append("idComentario", props.idComentario);
    values.append("accion", "agregarRespuesta");
    let objectValues = Object.fromEntries(values);

    if (comentario.trim().length > 0) {
      addClassAnswer({ values: objectValues })
        .then((response) => {
          let _response = JSON.parse(response);
          if (_response.response === "success") {
            $("#textAreaRespuestaClase-" + props.idComentario).val("");

            commentsList(
              list.concat(
                <ReplyComment
                  key={_response[0].idComentario}
                  idComentario={_response[0].idComentario}
                  nombre={_response[0].nombre}
                  foto={_response[0].fotoPerfil}
                  respuesta={comentario}
                  fecha={"un momento"}
                />
              )
            );

            Swal.fire({
              icon: "success",
              title: "Respuesta agregada",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "info",
              title: "Ocurrió un error",
              text: "Comentario no agregado, inténtalo más tarde.",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          console.log(error)
          // Swal.fire({
          //   icon: "error",
          //   title: "Error del servidor",
          //   text: "Inténtalo de nuevo en unos momentos.",
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Ingresa tu respuesta",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  function likeComment(idUsuario, idComentario) {
    let formData = new FormData();

    formData.append("accion", "insertarLikes");
    formData.append("idUsuario", idUsuario);
    formData.append("idComentario", idComentario);

    commentLikes(formData)
      .then((response) => {
        if (!response) {
          Swal.fire({
            icon: "warning",
            title: "No se pudo registrar tu like",
            text: "Por favor recarga la página e inténtalo nuevamente.",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          setLikeIcon(!likeIcon);

          setLikesAmount(likesAmount + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function dislikeComment(idUsuario, idComentario) {
    let formData = new FormData();

    formData.append("accion", "eliminarLikes");
    formData.append("idUsuario", idUsuario);
    formData.append("idComentario", idComentario);

    commentLikes(formData)
      .then((response) => {
        if (!response) {
          Swal.fire({
            icon: "warning",
            title: "No se pudo registrar tu like",
            text: "Por favor recarga la página e inténtalo nuevamente.",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          setLikeIcon(!likeIcon);

          if (likesAmount >= 1) {
            setLikesAmount(likesAmount - 1);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleCommentLikes(likes) {
    if (likeIcon) {
      return (
        <div
          className="likes-commets text-red-color"
          onClick={() => dislikeComment(idUsuario, props.idComentario)}
        >
          <div className="col-12">
            <AiFillHeart />
          </div>
          <div className="col-12">
            <span>{likes}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="likes-commets text-red-color"
          onClick={() => likeComment(idUsuario, props.idComentario)}
        >
          <div className="col-12">
            <AiOutlineHeart />
          </div>
          <div className="col-12">
            <span>{likes}</span>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="comment-made-container my-4">
      <div className="row">
        <div className="col-2 d-flex justify-content-center">
          <Avatar
            src={"https://cursodontic.com/asset/img/fotosPerfil/" + props.foto}
            sx={{ width: 50, height: 50 }}
          />
        </div>
        <div className="col-8 name-time-comment">
          <p className="font-bold mb-2">{props.nombre}
            {/* Block/Hide comment or user button */}
            <span className="mx-1 complaint__modal-bullets" data-toggle="modal" data-target={"#optionsUser-" + props.usuario}>
              <HiDotsHorizontal />
            </span>
          </p>
          <p className="text-gray-color font-light">Hace {props.fecha}</p>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-2 text-center p-3 main-likes-comments">
          {handleCommentLikes(likesAmount)}
        </div>
        <div className="col-10 pt-2">
          <p className="font-size-s mb-3">
            {props.comentario}
            <span className="mx-1 complaint__modal-bullets" data-toggle="modal" data-target={"#optionsComment-" + props.idComentario}
            >
              <HiDotsHorizontal />
            </span>
          </p>
          <div className="reply-comment d-flex flex-row align-items-center text-red-color pt-0">
            <FontAwesomeIcon
              icon={faReply}
              data-toggle="modal"
              data-target="#modalResponderComentario"
            />
            <p
              className="reply-comment font-size-s"
              data-toggle="modal"
              data-target={"#modalResponderComentario-" + props.idComentario}
            >
              Responder
            </p>
          </div>
          <div className="reply-answer mt-2">
            {props.respuestas.map((respuesta) => (
              <ReplyComment
                key={respuesta.idRespuesta}
                nombre={respuesta.nombreUsuario}
                foto={respuesta.foto}
                respuesta={respuesta.respuesta}
                fecha={respuesta.fecha}
                idRespuesta={respuesta.idRespuesta}
                idComentario={respuesta.idComentario}
                idUsuario={idUsuario}
                usuario={respuesta.usuario}
              />
            ))}
            {list}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id={"modalResponderComentario-" + props.idComentario}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <LeaveComment
              foto={props.fotoUsuarioActual}
              comentario={props.idComentario}
              clase={props.clase}
              onSubmitComment={onSubmitAnswer}
              showBtnCancel={true}
              nombre={props.nombre}
            />
          </div>
        </div>
      </div>

      {/* Block/Hide user modal */}
      <div className="modal fade complaint__modal" id={"optionsUser-" + props.usuario} tabIndex="-1" role="dialog" aria-labelledby="optionsUserTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Reportar un problema con {props.nombre}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ComplaintUserStepper idReporting={idUsuario} idReported={props.usuario}/>
            </div>
          </div>
        </div>
      </div>
      
      {/* Block/Hide comment modal */}
      <div className="modal fade complaint__modal" id={"optionsComment-" + props.idComentario} tabIndex="-1" role="dialog" aria-labelledby="optionsUserTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Reportar el comentario de {props.nombre}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ComplaintCommentStepper idReporting={idUsuario} idComentario={props.idComentario}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassCommentMade;
