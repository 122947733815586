import React from 'react';

import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';

function ItemCursoCarrito(props) {

    return (
        <div id={"elemento-" + props.id_carrito} className="cardProductoCarrito row">

            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">

                <img src={props.imagenCurso} alt={props.tituloCurso} className="img-fluid" />

            </div>

            <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">

                <h5 className="font-weight-bold">
                    <Link to={"/cursos/" + props.urlCorta} className="noEstiloEnlace">
                        {props.tituloCurso}
                    </Link>
                </h5>

                <small className="text-muted">NUEVO</small>

                <div className="row mt-3">

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                        <p className="text-danger font-weight-bold txt-grande">$<span id={"precio-" + props.id_carrito}>{props.precio}</span>
                            <small className="txt-pequeno mx-2">
                                {props.divisa}
                                <span className="text-muted ml-1 mx-2"></span>
                            </small>
                        </p>
                    </div>


                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right">
                        <button id={props.id_carrito} onClick={props.handleClick} className="text-danger mt-2 btnEliminar btn noPadding">
                            <i className="fas fa-trash-alt"></i>
                            <span className="text-danger ml-2">Eliminar</span>
                        </button>
                        <div id={"load-" + props.id_carrito} className="d-flex align-items-center mt-2 hidden btnEliminar btn noPadding">
                            <ReactLoading type="spin" color="#F65F5D" height={'3%'} width={'3%'} className="circularBarProgressIndicatorItem" />
                            <span className="text-danger mx-2">Eliminando</span>
                        </div>
                    </div>

                </div>

            </div>

            <hr />

        </div>
    );

}

export { ItemCursoCarrito };