import { PinDropSharp } from '@material-ui/icons';
import React from 'react';

import Modal from 'react-bootstrap/Modal'

import './ModalDireccion.css';

import addAddress from '../../services/addAddress';

import $ from 'jquery';
import Swal from 'sweetalert2'

function ModalDireccion(props) {

    let objectValues;
    let idUsuario = localStorage.getItem('idUsuario');

    function validarFormulario(e) {

        e.preventDefault();

        Swal.showLoading();

        var nombreReceptor = $(".nombreReceptor").val();
        var calleDireccion = $(".calleDireccion").val();
        var numExteriorDireccion = $(".numExteriorDireccion").val();
        var numInteriorDireccion = $(".numInteriorDireccion").val();
        var codigoPostalDireccion = $(".codigoPostalDireccion").val();
        var numeroCelularDireccion = $(".numeroCelularDireccion").val();
        var estadoDireccion = $(".estadoDireccion").val();
        var delegacionDireccion = $(".delegacionDireccion").val();
        var coloniaDireccion = $(".coloniaDireccion").val();
        var referenciaDireccion = $(".referenciaDireccion").val();
        var paisDireccion = $(".paisDireccion").val();

        numInteriorDireccion = (numInteriorDireccion.length > 0) ? numInteriorDireccion : "";
        referenciaDireccion = (referenciaDireccion.length > 0) ? referenciaDireccion : "";

        let values = new FormData();

        values.append("idUsuario", idUsuario);
        values.append("nombreReceptor", nombreReceptor);
        values.append("calleDireccion", calleDireccion);
        values.append("numExteriorDireccion", numExteriorDireccion);
        values.append("numInteriorDireccion", numInteriorDireccion);
        values.append("codigoPostalDireccion", codigoPostalDireccion);
        values.append("numeroCelularDireccion", numeroCelularDireccion);
        values.append("estadoDireccion", estadoDireccion);
        values.append("delegacionDireccion", delegacionDireccion);
        values.append("coloniaDireccion", coloniaDireccion);
        values.append("referenciaDireccion", referenciaDireccion);
        values.append("paisDireccion", paisDireccion);

        objectValues = Object.fromEntries(values);

        if (paisDireccion !== "1") {

            agregarAddress();

        } else {
            Swal.fire(
                'Datos incorrectos',
                'Asgurate de elegir tu país.',
                'error'
            )
        }

    }

    function agregarAddress() {

        addAddress({ values: objectValues }).then(response => {
            let _respuesta = JSON.parse(response);
            console.log(_respuesta);
            if (_respuesta.response === "error") {

                Swal.fire({
                    icon: 'error',
                    title: 'Algo salió mal, intenta de nuevo.',
                    showConfirmButton: false,
                    timer: 1500
                })

            } else {
                
                props.getDatos();
                Swal.close();
                props.onHide();
                Swal.fire({
                    icon: 'success',
                    title: 'Dirección agregada',
                    showConfirmButton: false,
                    timer: 1500
                })

            }

        }).catch((error) => {
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalSeguirComprando"
            >
                <Modal.Header closeButton className="bg-black text-white">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Nueva dirección
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form id="registrarDireccion" onSubmit={validarFormulario}>
                        {props.pais === "MX" &&
                            <>
                                <div className="row">

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">

                                        <p className="mb-1">¿Quién lo recibe?</p>

                                        <input type="text" className="form-control nombreReceptor inputDireccionNueva" required placeholder="Escribe un nombre" />

                                    </div>

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">

                                        <p className="mb-1">Calle</p>

                                        <input type="text" className="form-control calleDireccion inputDireccionNueva" required placeholder="Escribe tu calle" />

                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 pt-sm pt-md pt-xs">

                                        <p className="mb-1">N° Exterior</p>

                                        <input type="number" className="form-control numExteriorDireccion inputDireccionNueva" required onkeypress="return valida(event)" max="9999999" placeholder="#" />

                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 pt-sm pt-md pt-xs">

                                        <p className="mb-1">N° Interior <small>(opcional)</small></p>

                                        <input type="number" className="form-control numInteriorDireccion inputDireccionNueva" onkeypress="return valida(event)" max="9999999" placeholder="#" />

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">

                                        <p className="mb-1 pt-4">Código Postal</p>

                                        <input type="number" className="form-control codigoPostalDireccion inputDireccionNueva" required onkeypress="return valida(event)" max="99999" placeholder="00000" />

                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">

                                        <p className="mb-1 pt-4">Número celular:</p>

                                        <input type="number" className="form-control numeroCelularDireccion inputDireccionNueva" required onkeypress="return valida(event)" max="999999999999999" placeholder="0000000000" />

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">

                                        <p className="mb-1 pt-4">Estado</p>

                                        <input type="text" className="form-control estadoDireccion inputDireccionNueva" required placeholder="Escribe tu estado" />

                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">

                                        <p className="mb-1 pt-4">Delegación</p>

                                        <input type="text" className="form-control delegacionDireccion inputDireccionNueva" required placeholder="Escribe tu delegación" />

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                                        <p className="mb-1 pt-4">Colonia</p>

                                        <input type="text" className="form-control coloniaDireccion inputDireccionNueva" required placeholder="Escribe tu colonia" />

                                    </div>

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">

                                        <p className="mb-1 pt-4">Instrucciones especiales: <small>(opcional)</small></p>

                                        <input type="text" className="form-control referenciaDireccion inputDireccionNueva" />

                                    </div>

                                </div>
                            </>
                        }

                        {props.pais !== "MX" &&
                            <>
                                <div className="row">

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">

                                        <p className="mb-1">¿Quién lo recibe?</p>

                                        <input type="text" className="form-control mb-4 nombreReceptor inputDireccionNueva" required placeholder="Escribe un nombre" />

                                        <p className="mb-1">Pais</p>

                                        <select id="paisDireccion" className="form-control paisDireccion inputDireccionNueva" required>
                                            <option value="1">Selecciona tu país...</option>
                                            {props.paises.length >= 1 &&
                                                props.paises.map(
                                                    pais => (
                                                        <option value={pais.codigoPais}>{pais.pais}</option>
                                                    )
                                                )
                                            }

                                        </select>

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">

                                        <p className="mb-1">Calle</p>

                                        <input type="text" className="form-control calleDireccion inputDireccionNueva" required placeholder="Nombre de tu calle" />

                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 pt-sm pt-md pt-xs">

                                        <p className="mb-1">N° Exterior</p>

                                        <input type="number" className="form-control numExteriorDireccion inputDireccionNueva" required onkeypress="return valida(event)" max="9999999" placeholder="#" />

                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 pt-sm pt-md pt-xs">

                                        <p className="mb-1">N° Interior <small>(opcional)</small></p>

                                        <input type="number" className="form-control numInteriorDireccion inputDireccionNueva" onkeypress="return valida(event)" max="9999999" placeholder="#" />

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">

                                        <p className="mb-1 pt-4">Código Postal</p>

                                        <input type="number" className="form-control codigoPostalDireccion inputDireccionNueva" required onkeypress="return valida(event)" max="99999" placeholder="00000" />

                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">

                                        <p className="mb-1 pt-4">Número celular:</p>

                                        <input type="number" className="form-control numeroCelularDireccion inputDireccionNueva" required onkeypress="return valida(event)" max="999999999999999" placeholder="0000000000" />

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">

                                        <p className="mb-1 pt-4">Estado</p>

                                        <input type="text" className="form-control estadoDireccion inputDireccionNueva" required placeholder="Nombre de tu estado" />

                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">

                                        <p className="mb-1 pt-4">Ciudad</p>

                                        <input type="text" className="form-control delegacionDireccion inputDireccionNueva" required placeholder="Nombre de tu ciudad" />

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                                        <p className="mb-1 pt-4">Barrio</p>

                                        <input type="text" className="form-control coloniaDireccion inputDireccionNueva" required placeholder="Nombre de tu barrio" />

                                    </div>

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">

                                        <p className="mb-1 pt-4">Instrucciones especiales: <small>(opcional)</small></p>

                                        <input type="text" className="form-control referenciaDireccion inputDireccionNueva" />

                                    </div>

                                </div>
                            </>
                        }

                        <button type="submit" className="btn btn-rojo w-100"><i className="fas fa-shopping-bag mx-2"></i> Guardar dirección</button>
                    </form>
                </Modal.Body>

            </Modal>

        </>
    );
}

export { ModalDireccion };