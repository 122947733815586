import React, { useState, useEffect } from "react";
import "./detalleCompra.css";
import "../reciboCompras/reciboCompras.css";
import oxxoImage from "./../../assets/images/oxxo.png"
// import "../myOrders/myOrders.css";
import ReactLoading from 'react-loading';

import { MdLocalShipping } from "react-icons/md";
import getOrderDetails from "../../services/getDetallesCompra";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";
import { Helmet } from "react-helmet-async";

const DetalleCompra = () => {
  let idUsuario = localStorage.getItem("idUsuario");

  let nombreCursoUrl = window.location.href.split("/");
  let idPedido = nombreCursoUrl[4];

  // let titulo = document.getElementById("tituloWeb");
  // titulo.innerHTML = 'Cursodontic | Pedido-'+idPedido;

  const [orderDetail, setOrderDetail] = useState([]);
  const [productsOrder, setProductsOrder] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    getDetallesCompra();
  }, []);

  function getDetallesCompra() {
    var formData = new FormData();

    formData.append("idUsuario", idUsuario);
    formData.append("pedido", idPedido);

    getOrderDetails(formData)
      .then((response) => {
        let _respuesta = JSON.parse(response);
        if (_respuesta.response === "success") {
          setOrderDetail(_respuesta.datos);
          setProductsOrder(_respuesta.productos);

          // console.log(_respuesta);
        }
      })
      .catch((error) => {
        // Swal.fire(
        //   "Error del servidor",
        //   "Inténtalo de nuevo en uno momentos.",
        //   "error"
        // );
        console.log(error)
      });
  }

  function handleOrderColors(colores) {
    return (
      <ul className="listaColoresRecibo">
        {colores.map((color) => (
          <li key={color.id_color}>
            <div
              className="colorAcetato"
              data-toggle="popover"
              data-content={`${color.color}`}
              data-placement="top"
              data-trigger="hover"
              style={{ backgroundColor: `${color.hexColor}` }}
            ></div>
            x<span className="numeroDeAcetatos">{` ${color.cantidad}`}</span>
          </li>
        ))}
      </ul>
    );
  }

  function handlePaymentOption(paymentOption) {
    switch (paymentOption) {
      case "card":
        return (
          <div>
            <div className="mt-4">
              Tarjeta de crédito o débito{" "}
              <i className="fas fa-credit-card mx-1 text-danger"></i>{" "}
              <i className="fab fa-cc-mastercard ml-2 txtMastercard"></i>{" "}
              <i className="fab fa-cc-visa ml-2 txtVisa"></i>
            </div>
            <small className="pt-5 text-muted">
              Todos los pagos se procesan desde Stripe
            </small>
          </div>
        );
        break;

      case "oxxo":
        return (
          <div>
            <div className="mt-4">
              Depósito en{" "}
              <img
                src={oxxoImage}
                className="oxxoPago"
                alt="Metodos de pago"
              />
            </div>
            <small className="pt-5 text-muted">
              Todos los pagos se procesan desde Stripe
            </small>
          </div>
        );
        break;

      case "paypal":
        return (
          <div>
            <div className="mt-4">
              Paypal <i className="fa-brands fa-cc-paypal"></i>
            </div>
            <small className="pt-5 text-muted">
              Este pago fue procesado con PayPal
            </small>
          </div>
        );
        break;

      default:
        return (
          <div>
            <div className="mt-4">
              Tarjeta de crédito o débito{" "}
              <i className="fas fa-credit-card mx-1 text-danger"></i>{" "}
              <i className="fab fa-cc-mastercard ml-2 txtMastercard"></i>{" "}
              <i className="fab fa-cc-visa ml-2 txtVisa"></i>
            </div>
            <small className="pt-5 text-muted">
              Todos los pagos se procesan desde Stripe
            </small>
          </div>
        );
    }
  }

  function handleOrderStatus(data) {
    switch (data.statusAviso) {
      case "preparando":
        return (
          <div className="alert alert-info text-center statusPedido">
            <i className="fas fa-spinner fa-spin mr-2"></i> Preparando el envío de
            tu paquete
          </div>
        );
        break;

      case "esperaOxxo":
        return (
          <div>
            <a href={data.linkOxxo} target="_blank">
              <div
                className="alert alert-warning statusPedido text-center"
                data-status="pendientePagoOXXO"
              >
                PAGO PENDIENTE: Clic para ver tu recibo de pago de{" "}
                <b>{`$${parseFloat(data.montoPagoOxxo).toFixed(2)}`}</b>{" "}
                <i className="fab fa-wpforms ml-2"></i>
              </div>
            </a>
          </div>
        );
        break;

      case "revision":
        return (
          <div className="alert alert-warning statusPedido text-center">
            Estamos verificando tu pago, puede tardar hasta 24 hrs.{" "}
            <i className="fas fa-clock ml-3"></i>
          </div>
        );
        break;

      case "cancelado":
        return (
          <div className="alert alert-danger text-center statusPedido">
            Pedido cancelado <i className="fas fa-ban ml-3"></i>
          </div>
        );
        break;

      case "rastreo":
        return (
          <div>
            <div className="alert alert-info text-center statusPedido">
              <i className="fas fa-truck mr-2"></i> # Rastreo: {` ${data.rastreo}`}
            </div>
            {!data.calificacionProductoPedido ? (
              <div
                className="alert alert-success text-center statusPedido calificarPedido"
                data-status="calificarPedido"
              >
                Calificar pedido <i className="far fa-star ml-2"></i>
              </div>
            ) : (
              <div className="alert alert-success text-center ordenCalificada">
                Calificación: {data.calificacionProductoPedido} de 5{" "}
                <i className="fas fa-star ml-2"></i>
              </div>
            )}
          </div>
        );
        break;

      case "entregado":
        return (
          <div>
            <div className="alert alert-success text-center statusPedido">
              <i className="fas fa-check-circle mr-2"></i>{" "}
              <i className="fas fa-truck mr-2"></i> Entregado
            </div>
            {!data.calificacionProductoPedido ? (
              <div
                className="alert alert-success text-center statusPedido calificarPedido"
                data-status="calificarPedido"
              >
                Calificar pedido <i className="far fa-star ml-2"></i>
              </div>
            ) : (
              <div className="alert alert-success text-center ordenCalificada">
                Calificación: {data.calificacionProductoPedido} de 5{" "}
                <i className="fas fa-star ml-2"></i>
              </div>
            )}
          </div>
        );
        break;
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Detalle de compra | Cursodontic</title>
        <link rel="canonical" href="https://cursodontic.com" />
        <meta name="description" content="Detalle de tu pedido en Cursodontic." />

        <meta property="og:title" content="Detalle de compra | Cursodontic" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
        <meta property="og:url" content="https://cursodontic.com" />

        <meta property="og:description" content="Detalle de tu pedido en Cursodontic." />
        <meta property="og:site_name" content="Cursodontic | Educación Dental Profesional" />
        <meta name="twitter:image:alt" content="Cursodontic Educación Dental Profesional" />
      </Helmet>
      {orderDetail.length === 0 ? (
        <div className="circularBarProgressIndicator">
          <ReactLoading
            type="spin"
            color="#F65F5D"
            height={"4%"}
            width={"4%"}
            className="circularBarProgressIndicatorItem"
          />
        </div>
      ) : (
        <div className="container-fluid titulo separacionEnorme">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Detalle de compra | Cursodontic</title>
            <link rel="canonical" href="https://cursodontic.com" />
            <meta name="description" content="Detalle de tu pedido en Cursodontic." />

            <meta property="og:title" content="Detalle de compra | Cursodontic" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
            <meta property="og:url" content="https://cursodontic.com" />

            <meta property="og:description" content="Detalle de tu pedido en Cursodontic." />
            <meta property="og:site_name" content="Cursodontic | Educación Dental Profesional" />
            <meta name="twitter:image:alt" content="Cursodontic Educación Dental Profesional" />
          </Helmet>
          <div className="container noPadding-xs noPadding-sm">

            <div className="col-12 col-sm-12 col-md-12 col-lg-0 col-xl-0 pt-4">
              {handleOrderStatus(orderDetail)}
            </div>

            <div id="detallesGeneralesPedido">
              {/* <!--SE MUESTRA LA DIRECCIÓN A DONDE SE MANDARÁ EL PAQUETE, JUNTO A QUIEN SE DIRIGE Y APROXIMADAMENTE CUANDO LLEGA--> */}

              <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                  <div className="detallesEntrega rounded px-3 py-3">
                    <p># Pedido: {idPedido}</p>
                    <div id="perfil" className="perfil">
                      <h2 className="font-bebas text-strong-gray-color">
                        DETALLES DE ENTREGA
                      </h2>
                    </div>

                    <hr className="singleline" />

                    <div className="container detalleEntregaRecibo">
                      <div className="row fechaRecibo">
                        <div className="col-2 col-md-1 col-lg-1 col-xl-1">
                          <i className="fas fa-truck-loading iconosDetallesRecibo"></i>
                        </div>
                        <div className="col-10 col-md-11 col-lg-11 col-xl-11">
                          <p>Llega aproximadamente entre el:</p>
                          <p className="texto-negritas">{` ${orderDetail.llegaEntre} y el ${orderDetail.yEntre}`}</p>
                        </div>
                      </div>

                      <div className="row usuarioRecibo entregaRecibo">
                        <div className="col-2 col-md-1 col-lg-1 col-xl-1">
                          <i className="fas fa-user iconosDetallesRecibo alineacionIconosRecibo"></i>
                        </div>

                        <div className="col-10 col-md-11 col-lg-11 col-xl-11">
                          <p>Para:</p>
                          <p className="texto-negritas">
                            {orderDetail.nombreReceptor}
                          </p>
                        </div>
                      </div>

                      <div className="row entregaRecibo">
                        <div className="col-2 col-md-1 col-lg-1 col-xl-1">
                          <i className="fas fa-map-marker-alt iconosDetallesRecibo alineacionIconosRecibo"></i>
                        </div>

                        <div className="col-10 col-md-11 col-lg-11 col-xl-11">
                          <p>Entrega en:</p>
                          <p className="texto-negritas">
                            {`${orderDetail.calle} ${orderDetail.numExterior} ${orderDetail.numInterior}, ${orderDetail.colonia}, ${orderDetail.cp}, ${orderDetail.delegacion}, ${orderDetail.pais}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="productosPedidos  rounded px-3 py-3">
                    <h5 className="font-weight-bold my-5">PRODUCTOS</h5>

                    <div className="cardPedido rounded-lg">
                      <small>
                        <i className="fas fa-calendar-check text-info mr-2 mb-3"></i>
                        {` ${orderDetail.txtComprado}`}
                      </small>
                      <span className="px-3 font-weight-bold col-0">|</span>
                      <br className="col-lg-0 col-xl-0" />
                      <small>
                        Pedido: #
                        <span className="font-weight-bold">{idPedido}</span>
                      </small>
                      <span className="px-3 font-weight-bold">|</span>
                      <small className="text-dark">
                        Total:
                        <span className="font-weight-bold">{` $${parseFloat(orderDetail.costoTotalMP).toFixed(2)}`}</span>
                      </small>
                      {productsOrder.map((producto, index) => (
                        <div className="row py-3 d-flex align-items-center" key={index}>

                          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4 form-inline pr-3">
                            <a>
                              <img
                                src={producto.imagenPrincipal}
                                className="img-fluid img mr-1"
                              />
                            </a>
                          </div>

                          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8 mt-2">
                            <div className="mb-3">
                              <Link
                                to={`/materiales/acetatos/${producto.rutaNombreProducto}`}
                              >
                                {producto.nombreProducto}
                              </Link>
                            </div>

                            <div className="form-inline coloresPedido">
                              {handleOrderColors(producto.colores)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 pt-md pt-sm pt-xs pricing-card">
                  <div className="resumenPagoPedido cssanimation sequence fadeInBottom fade-in border rounded-lg px-3 py-3 mx-2">
                    <div className="col-0 col-sm-0 col-md-0">
                      {handleOrderStatus(orderDetail)}
                    </div>

                    <p className="text-muted subtotal d-flex justify-content-between">
                      Subtotal{`(${orderDetail.totalProductos}):`}
                      <span className="float-right text-danger font-weight-bold">
                        {` $${parseFloat(orderDetail.costoTotalMP).toFixed(2)} `}
                        <small>{orderDetail.monedaMP}</small>
                      </span>
                    </p>

                    <hr />

                    <p className="text-muted envio d-flex justify-content-between">
                      Envío:
                      <span className="float-right text-success font-weight-bold">
                        GRATIS
                      </span>
                    </p>

                    <hr className="col-md-0 col-sm-0 col-0" />

                    <h5 className="font-weight-bold d-flex justify-content-between">
                      TOTAL:
                      <span className="float-right text-danger">
                        {` $${parseFloat(orderDetail.costoTotalMP).toFixed(2)} `}
                        <small>{orderDetail.monedaMP}</small>
                      </span>
                    </h5>
                  </div>

                  <div className="metodoPagoPedido cssanimation sequence fadeInBottom fade-in border rounded-lg px-3 py-3 mx-2 mt-4">
                    <h5 className="font-weight-bold">MÉTODO DE PAGO</h5>
                    {handlePaymentOption(orderDetail.metodoPago)}
                  </div>

                  <div className="cssanimation sequence fadeInBottom fade-in my-5 px-3">
                    <small>
                      ¿Necesitas ayuda con tu pedido? Envía un correo a{" "}
                      <span className="font-bold">
                        <a href="mailto:soporte@cursodontic.com">
                          soporte@cursodontic.com
                        </a>
                      </span>{" "}
                      con el folio:{" "}
                      <i className="font-bold">{`#${idPedido}`}</i>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetalleCompra;
