import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2'
import $ from 'jquery'

// ESTILOS
import './Header.css';
import iconoLogo from './../../assets/images/iconodtm2.png';

// COMPONENTES
import BuscadorHeader from './../BuscadorHeader';
import ButtonSession from './../ButtonSession';
import {InicioSesionModal} from './../InicioSesionModal/InicioSesionModal.js';

// UTILIDADES
import { showMenu, showUsuarioMenu } from './../../utils/menu'

export default function Header(props){

    const location = useLocation()

    React.useEffect(()=>{
        if(location.pathname !== "/prometeo"){
            $("#header").removeClass("hidden")
            $("#sectionGeneral").addClass("marginGeneral")
            $("body").removeClass("bg-gris")
        }
        
        if(location.pathname === "/prometeo"){
            $("#header").addClass("hidden")
            $("#sectionGeneral").removeClass("marginGeneral")
            $("body").addClass("bg-gris")
        }

    },[location.pathname])

    return (
        (location.pathname !== "/prometeo" ) && 
        <>
            <header className="conteiner-fluid cabezote fixed-top" id="header">

                <div className="cabezote__menu">
                    <button className="menu__exit col-xl-0 col-lg-0" onClick={ showMenu }>
                        <FontAwesomeIcon className="menu__icono" icon={["fas", "fa-bars"]} />
                        <FontAwesomeIcon className="menu__icono d-none" icon={["fas", "fa-times"]} />
                    </button>
                    <Link to="/"  className="menu__logo">
                        <img src="https://cursodontic.com/vista/assets/images/logoBlanco.png" alt="Logo denthereum" className="logo__imagen" />        
                        {/* <p className="logo__texto">ursodontic</p> */}
                    </Link>
                    
                    <BuscadorHeader />
                </div>

                <div className="cabezoteInfo">

                    <ul className="cabezoteInfo__listaCabezote col-md-0 col-sm-0 col-xs-0">

                        <li>

                            <Link to="/prometeo" className="listaCabezote__link">

                                <div className="listaCabezote__referenciaCursodontic">

                                    <i className="fa-solid fa-robot referenciaCursodontic__icono"></i>
                                    <span className="referenciaCursodontic__text">Prometeo</span>

                                </div>
                            
                            </Link>

                        </li>

                        <li>

                            <Link to="/cursos" className="listaCabezote__link">

                                <div className="listaCabezote__referenciaCursodontic">

                                    <i className="fa-solid fa-video referenciaCursodontic__icono"></i>
                                    <span className="referenciaCursodontic__text">Cursos</span>

                                </div>
                            
                            </Link>

                        </li>

                        <li>

                            <Link to="/denthereum" className="listaCabezote__link">

                                <div className="listaCabezote__referenciaCursodontic">

                                    <img src={iconoLogo} className='referenciaCursodontic__img' />
                                    <span className="referenciaCursodontic__text">Denthereum</span>

                                </div>
                            
                            </Link>

                        </li>

                        <li>

                            <Link to="/membresia" className="listaCabezote__link">

                                <div className="listaCabezote__referenciaCursodontic">

                                    <i className="fa-solid fa-award referenciaCursodontic__icono"></i>
                                    <span className="referenciaCursodontic__text">Membresia</span>

                                </div>
                            
                            </Link>

                        </li>

                    </ul>

                    {
                        props.login 
                        ?
                            <ButtonSession 
                                onClick={ showUsuarioMenu }
                            />
                        :
                            <>
                                <button 
                                    className="boton-rojo" 
                                    onClick={() => props.setModalLogin(true)}
                                >
                                    Acceder
                                </button>

                            </>
                    }

                </div>

            </header>

            <InicioSesionModal
                show={props.modalLoginShow}
                setModalLogin={props.setModalLogin}
                handleLogin={props.handleLogin}
            />
        </>
    );
}