import React from 'react'
import "./GuiaRapidaDenthereum.css"

import SubtituloDenthereum from '../SubtituloDenthereum'

export default function GuiaRapidaDenthereum (props){
    return(

        <div className="container-fluid guiaRapida noPadding">

            <div className="container">

                <div className="infoDenthereum__guiaRapida">
                        
                    <div className="guiaRapida__text">

                        <SubtituloDenthereum 
                            text="Comienza en segundos" 
                            estilo="guiaRapida__parrafo1" 
                        />
                            
                        <p className="guiaRapida__parrafo2">
                                
                            Sé parte de la comunidad dental digital que utiliza tecnologías del futuro (blockchain y web3) 💛.
                            
                        </p>
                        
                    </div>
                    
                </div> 

            </div>

        </div>
    )
}