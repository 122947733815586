import React from "react";
import "./LateralMenu.css";
// import logo from "./../../assets/images/";
import { useLocation, Link } from 'react-router-dom';
import iconoLogo from './../../assets/images/iconodtm2.png';
// import $ from 'jquery/dist/jquery.min.js';

function LateralMenu({children}){

    return(
        <nav className="menu__side menu__side_move" id="menu_side">

            <div className="name__page">
                <img src="https://cursodontic.com/vista/assets/images/logoBlanco.png" alt="Cursodontic" />
            </div>

            <hr className='my-1' />

            <div className="options__menu">

                <Link to={"/"} className="option_enlace Link">
                    <div className="option">
                        <i className="fas fa-home referenciaCursodontic__img" title="Dashboard"></i>
                        <p className="ml-3">Inicio</p>
                    </div>
                </Link>

                <Link to={"/cursos"} className="option_enlace Link">
                    <div className="option">
                        <i className="fas fa-video referenciaCursodontic__img" title="Dashboard"></i>
                        <p className="ml-3">Cursos</p>
                    </div>
                </Link>

                <Link to={"/marketplace"} className="option_enlace Link">
                    <div className="option">
                        <i className="fas fa-tooth referenciaCursodontic__img" title="Dashboard"></i>
                        <p className="ml-3">Marketplace</p>
                    </div>
                </Link>

                <Link to={"/membresia"} className="option_enlace Link">
                    <div className="option">
                        <i className="fas fa-award referenciaCursodontic__img" title="Dashboard"></i>
                        <p className="ml-3">Membresia</p>
                    </div>
                </Link>

                <Link to={"/denthereum"} className="option_enlace Link">
                    <div className="option">
                        <img src={iconoLogo} className='referenciaCursodontic__img' />
                        <p className="ml-3">Denthereum</p>
                    </div>
                </Link>
                
                { children }

            </div>
        </nav>
    )
}

export default LateralMenu;