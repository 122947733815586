import React from 'react'

import {ItemCursoCarrito} from '../ItemCursoCarrito/ItemCursoCarrito';
import {ItemProductoCarrito} from '../ItemProductoCarrito/ItemProductoCarrito';

function ProductosCheckout(props) {
    return (
        <div id="productosCarrito">
            {props.carrito.map(carrito => (
                (carrito.tipo === "curso") ?

                    <ItemCursoCarrito
                        key={carrito.id_carrito}
                        id_carrito={carrito.id_carrito}
                        imagenCurso={carrito.imagenCurso}
                        tituloCurso={carrito.tituloCurso}
                        urlCorta={carrito.urlCorta}
                        precio={carrito.precio}
                        divisa={carrito.divisa}
                        handleClick={props.handleClick}
                    />

                    :

                    <ItemProductoCarrito
                        key={carrito.id_carrito}
                        id_carrito={carrito.id_carrito}
                        UrlImagenPrincipal={carrito.UrlImagenPrincipal}
                        nombreProducto={carrito.nombreProducto}
                        urlCorta={carrito.urlCorta}
                        precioProducto={carrito.precioProducto}
                        divisa={carrito.divisa}
                        colores={carrito.colores}
                        llegaEntre={carrito.llegaEntre}
                        yEntre={carrito.yEntre}
                        handleClick={props.handleClick}

                    />

            ))
            }
        </div>
    );
}

export { ProductosCheckout };