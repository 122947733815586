import React from 'react';
import './temary.css';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import config from '../../config';
import { useHistory, useParams } from 'react-router';

export default function Temary(props) {

  const { nombre_curso, id_clase } = useParams();
  const history = useHistory()

  let clase = id_clase;
    
  const [activeStep, setActiveStep] = React.useState([]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setActiveStep(parseInt(clase) -1 );
  } , []);

  const handleNext = (index) => {
    let claseChange = index +1;
    history.push('/mis-cursos/'+props.urlCurso+claseChange)
  };

  return (
    <div className="container" sx={{ maxWidth: 400 }}>
      {props.temario.length >=1 && 
        <Stepper nonLinear={true} activeStep={activeStep} orientation="vertical">
        {props.temario.map((step, index) => (
            <Step completed={step.vista} onClick={() => handleNext(index)} className="step-number font-bold" key={step.clase}>
                <StepLabel >
                  <div className='d-flex justify-content-between align-items-center'>
                    <span>
                      {step.tituloClase}
                    </span>
                    <p className="font-light">{step.duracion} min.</p>
                  </div>
                </StepLabel>
            </Step>
            ))}
        </Stepper>
        }
    </div>
    );
}