import React from 'react';
import '../components/failedPayment/failedPayment.css';

import { FiAlertOctagon } from 'react-icons/fi';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const FailedPayment = () => {
  return (
    <div className="main-failed-payment">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Error al pagar | Cursodontic</title>
            <meta name="description" content="Ha ocurrido un error al procesar tu pago, inténtalo nuevamente." />

            <meta property="og:title" content="Error al pagar | Cursodontic" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
            <meta property="og:url" content="https://cursodontic.com" />

            <meta property="og:description" content="Ha ocurrido un error al procesar tu pago, inténtalo nuevamente." />
            <meta property="og:site_name" content="Error al pagar | Cursodontic" />
            <meta name="twitter:image:alt" content="Cursodontic" />
        </Helmet>

        <div className="container-fluid failed-payment-container">
            <div className="container">
                <section className="container text-center py-2">
                    <div className="check-icon d-flex justify-content-center">
                        <FiAlertOctagon />
                    </div>
                    <div className="col-12 my-3">
                        <p className="text-white-color font-regular">Algo salió mal...</p>
                        <p className="text-white-color font-semibold">Tu tarjeta rechazó el pago</p>
                    </div>
                    {/* <Link to="/carrito">
                        <button className="failed-payment-button">Intentarlo nuevamente</button>
                    </Link> */}
                </section>
            </div>
        </div>
        <div className="container-fluid">
            <div className="container try-again-card p-0">
                <section className="p-4">
                    <p className="font-regular font-semibold mt-2">¿Qué puedo hacer?</p>
                    <p className="font-regular mt-3">Utiliza otra tarjeta u otro método de pago.</p>
                    <Link to="/carrito">
                        <button className="failed-payment-button mt-4 mb-2">Intentarlo nuevamente</button>
                    </Link>
                </section>
            </div>
            <div className="container try-again-back text-white-color px-0">
                <Link to="/">
                    <button className="try-again-back-button py-4 d-flex align-items-center">
                        <IoIosArrowBack />Volver al sitio
                    </button>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default FailedPayment;