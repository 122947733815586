import React, { useCallback } from 'react';
import './ButtonSession.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWeb3React } from '@web3-react/core'
import useTruncatedAddress from './../../web3/useTruncatedAddress'
import imageProfile from './../../assets/images/image_profile_predetermined.jpeg'

export default function ButtonSession(props){
    const { account, active, library } = useWeb3React()
    // const [fotoPerfil, setFotoPerfil] = React.useState(imageProfile)

    const renderButtonLogin = useCallback(()=>{
        if(active){
            // console.log(library)
            return(
                <div className="cabezote__login" id="btnDespliegue">
                    <button className="cabezote__btnLogin" onClick={ props.onClick }> 
                        <div className="login__circulo">
                            <FontAwesomeIcon className="login__icono" icon={["fas", "fa-wallet"]} />
                        </div>
                        <div className="login__rectangulo">
                            <span className="rectangulo__texto">{
                                (account) && useTruncatedAddress(account)
                            }</span>
                            <FontAwesomeIcon className="rectangulo__icono" icon={["fas", "fa-chevron-down"]} />
                        </div>
                    </button>
                </div>
            )
        } else {
            // console.log(localStorage.getItem('fotoPerfil'))
            let _fotoPerfil = localStorage.getItem('fotoPerfil');
            if(( _fotoPerfil !== 'null' && 
            _fotoPerfil !== 'undefined' && 
            _fotoPerfil !== undefined && 
            _fotoPerfil !== null )){
                return(
                    <button className="cabezote__btnLogin" onClick={ props.onClick }>
                        <img className="fotoUsuarioHead ml-3" src={_fotoPerfil}></img>
                    </button>
                ) 
            } else {
                return (
                    <button className="cabezote__btnLogin" onClick={ props.onClick }>
                        <img className="fotoUsuarioHead ml-3" src={imageProfile}></img>
                    </button>
                )
            }
            // if(fotoPerfil !== null){
            // }
        }

    },[active])
    
    return(
        renderButtonLogin()
    );
}