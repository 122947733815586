import $ from 'jquery';
import config from '../config';
const URL_API = config.urlApi+'controladores/loginByApp.php';

export default function loginByApp(values){
    console.log("values => ",values);
    return $.ajax({
        url: URL_API,
        type: 'POST',
        data: values,
        contentType: false,
        processData: false,
    })
}