import React from "react"
import "./TituloDenthereum.css"

export default function TituloDenthereum({
    text, estilo, icon
}){

    return(
        <h1 className={estilo}>
            { text }
            { (icon !== null || icon !== undefined) && <i className={ icon }></i> }
        </h1>    
    )
}