import React from 'react';

import '../assets/css/Marketplace.css';

import {MarketplaceGeneral} from '../components/MarketplaceGeneral/MarketplaceGeneral.js';
import {MarketplaceCursos} from '../components/MarketplaceCursos/MarketplaceCursos.js';
import {MarketplaceMateriales} from '../components/MarketplaceMateriales/MarketplaceMateriales.js';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function Marketplace(props){

    React.useEffect(() => {
        // let titulo = document.getElementById("tituloWeb");
        // titulo.innerHTML = 'Cursodontic | Marketplace';
        window.scrollTo(0, 0);
    } , []);

    return (
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Marketplace | Cursodontic</title>
                <meta name="description" content="Encuentra los cursos y materiales dentales que estás buscando." />
                <meta name="keywords" content="Productos cursodontic" />
                <meta name="author" content="Cursodontic" />

                <meta property="og:title" content="Marketplace| Cursodontic" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://cursodontic.com/asset/img/share-banner.jpg" />
                <meta property="og:url" content="https://cursodontic.com" />
                <meta name="twitter:card" content="Cursodontic" />

                <meta property="og:description" content="Encuentra los cursos y materiales dentales que estás buscando." />
                <meta property="og:site_name" content="Encuentra los cursos y materiales dentales que estás buscando." />
            </Helmet>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                    <button className={`nav-link ${(props.vista=="general") && 'active'}`} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                        <Link to="/marketplace">Vista general</Link>
                    </button>
                </li>
                <li className="nav-item">
                    <button className={`nav-link ${(props.vista=="cursos") && 'active'}`} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                        <Link to="/cursos">Cursos</Link>
                    </button>
                </li>
                <li className="nav-item">
                    <button className={`nav-link ${(props.vista=="materiales") && 'active'}`} id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
                        <Link to="/materiales">Materiales</Link>
                    </button>
                </li>
            </ul>

            <div className="tab-content" id="myTabContent">
                <div className={`tab-pane fade ${(props.vista === "general") && 'show active'}`} id="home" role="tabpanel" aria-labelledby="home-tab">
                    <MarketplaceGeneral 
                        login={props.login}
                        setModalLogin={props.setModalLogin}
                    />
                </div>
                <div className={`tab-pane fade ${(props.vista === "cursos") && 'show active'}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <MarketplaceCursos 
                        login={props.login}
                        setModalLogin={props.setModalLogin}
                    />
                </div>
                <div className={`tab-pane fade ${(props.vista === "materiales") && 'show active'}`} id="contact" role="tabpanel" aria-labelledby="contact-tab">
                    <MarketplaceMateriales 
                        login={props.login}
                        setModalLogin={props.setModalLogin}
                    />
                </div>
            </div>

        </div>
    );
}

export {Marketplace};