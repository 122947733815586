import React from 'react';
import './unhideComments.css';
import { AiOutlineEye } from "react-icons/ai";
import unhideComment from '../../services/unhideComment';
import Swal from 'sweetalert2';

const UnhideComments = ({ complainComment }) => {
    const idUsuario = localStorage.getItem('idUsuario');

    const handleUnhideComment = async (e, idComentarioClase, idUsuario) => {
        e.preventDefault();
        let values = new FormData();
        values.append("idUsuario", idUsuario);
        values.append("idComentarioClase", idComentarioClase);

        try {
            const response = await unhideComment(values);
            const _respuesta = JSON.parse(response);

            if (_respuesta.response === "success") {
                Swal.fire({
                    icon: 'success',
                    title: 'Comentario visible',
                    text: 'El comentario ahora podrá ser visible dentro del curso y clase correspondiente.',
                    showConfirmButton: false,
                    timer: 1500,
                }).then(() => {
                    deleteCommentHiden(idComentarioClase, idUsuario);
                });
            } else {
                Swal.fire({
                    title: "Error",
                    text: "Ha ocurrido un error al mostrar el comentario, inténtalo nuevamente.",
                    icon: "error",
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    function deleteCommentHiden(idComentarioClase, idUsuario) {
        let commentId = 'div-' + idComentarioClase + idUsuario;
        const div = document.getElementById(commentId);
        div.classList.add('fadeOut');
        setTimeout(() => {
          div.remove();
          const closeUnhideModal = document.querySelector("#unhideComment__modal-close-"+idComentarioClase);
          closeUnhideModal.click();
        }, 500);
    }

  return (
    <>
        <div className="row my-3 d-flex text-center align-items-center" id={"div-"+complainComment.id_comentariosClases+idUsuario}>
            <div className="col-12 col-xl-2 col-lg-2 col-md-2 col-sm-12 my-3">
                <img src={"https://cursodontic.com/asset/img/fotosPerfil/" + complainComment.fotoPerfil} className="unlockUsersReports__userImg" alt={`Foto de ${complainComment.nombre}`}/>
            </div>
            <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <p className="font-bold mb-2">{complainComment.nombre}</p>
                <p className="commentsTab__text"><span className="fw-bold color-azul">Comentario: </span>{complainComment.contenido}</p>
            </div>
            <div className="col-12 col-xl-2 col-lg-2 col-md-2 col-sm-12">
                <button className="btn certificate-continue-button my-3" data-toggle="modal" data-target={"#unhideComment-" + complainComment.id_comentariosClases+complainComment.id_usuario}>Mostrar <AiOutlineEye /></button>
            </div>
            <hr className="mt-3"/>
        </div>

        {/* Modal */}
        <div className="modal fade" id={"unhideComment-" + complainComment.id_comentariosClases+complainComment.id_usuario} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-bs-dismiss="offcanvas">            
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Mostrar comentario de {complainComment.nombre}</h5>
                        <button id={"unhideComment__modal-close-"+complainComment.id_comentariosClases} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h2 className='font-bebas color-azul text-center'>MOSTRAR COMENTARIO</h2>
                        <p className='m-3 text-center'>Al dar click en confirmar aceptas ver nuevamente el comentario hecho por {complainComment.nombre}.</p>
                        <button type="button" className="cursositem__buttonInscribir btnPrimario mt-5" onClick={(e) => handleUnhideComment(e,complainComment.id_comentariosClases,idUsuario)}>
                            Aceptar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default UnhideComments;