import React from 'react';

import './MarketplaceMateriales.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Denthereum } from '../Denthereum/Denthereum.js';
import { MetodosPago } from '../MetodosPago/MetodosPago.js';
import { ResultadosItem } from '../ResultadosItem/ResultadosItem';

import Swal from 'sweetalert2'

import getMarketplaceShops from "../../services/getMarketplaceShops";
import getMarketplaceMaterialPopular from "../../services/getMarketplaceMaterialPopular";

import ReactLoading from 'react-loading';

const materiales = [
    { id: 1, urlPage: "/materiales/acetatos/libre-eleccion", color: 'verdeColor', url: 'https://cursodontic.com/asset/img/tiendas/proplay/VERDE_ELECTRICO1.jpg', nombre: 'Paquete de acetatos 20 piezas de color aleatorio', cripto: 24.891, fiat: 409 },
    { id: 2, urlPage: "/materiales/acetatos/libre-eleccion", color: 'verdeColor', url: 'https://cursodontic.com/asset/img/tiendas/proplay/PROTECTORES_MIXTO.jpg', nombre: 'Paquete de acetatos 20 piezas de color aleatorio', cripto: 24.891, fiat: 409 },
    { id: 3, urlPage: "/materiales/acetatos/libre-eleccion", color: 'verdeColor', url: 'https://cursodontic.com/asset/img/tiendas/proplay/UVA.jpg', nombre: 'Paquete de acetatos 20 piezas de color aleatorio', cripto: 24.891, fiat: 409 },
    { id: 4, urlPage: "/materiales/acetatos/libre-eleccion", color: 'verdeColor', url: 'https://cursodontic.com/asset/img/tiendas/proplay/AZUL_TRASLUCIDO1.jpg', nombre: 'Paquete de acetatos 20 piezas de color aleatorio', cripto: 24.891, fiat: 409 },
    { id: 5, urlPage: "/materiales/acetatos/libre-eleccion", color: 'verdeColor', url: 'https://cursodontic.com/asset/img/tiendas/proplay/VERDE_ELECTRICO1.jpg', nombre: 'Paquete de acetatos 20 piezas de color aleatorio', cripto: 24.891, fiat: 409 },
    { id: 6, urlPage: "/materiales/acetatos/libre-eleccion", color: 'verdeColor', url: 'https://cursodontic.com/asset/img/tiendas/proplay/PROTECTORES_MIXTO.jpg', nombre: 'Paquete de acetatos 20 piezas de color aleatorio', cripto: 24.891, fiat: 409 },
    { id: 7, urlPage: "/materiales/acetatos/libre-eleccion", color: 'verdeColor', url: 'https://cursodontic.com/asset/img/tiendas/proplay/UVA.jpg', nombre: 'Paquete de acetatos 20 piezas de color aleatorio', cripto: 24.891, fiat: 409 },
    { id: 8, urlPage: "/materiales/acetatos/libre-eleccion", color: 'verdeColor', url: 'https://cursodontic.com/asset/img/tiendas/proplay/AZUL_TRASLUCIDO1.jpg', nombre: 'Paquete de acetatos 20 piezas de color aleatorio', cripto: 24.891, fiat: 409 }
];

function MarketplaceMateriales() {

    const [tiendasAll, setShops] = React.useState([]);
    const [paginaActual, setPage] = React.useState(1);
    const [populares, serPopulars] = React.useState([]);

    React.useEffect(() => {
        getShops();
        getPopulars();
    }, []);

    function getShops() {
        getMarketplaceShops().then(response => {
            let _respuesta = JSON.parse(response);
            console.log(_respuesta);
            if (_respuesta.response === "error") {
                Swal.fire(
                    'Error al cargar las tiendas',
                    'Inténtalo de nuevo recargando la página.',
                    'warning'
                )
            } else {
                setShops(_respuesta.seccionesTiendas);
            }

        }).catch((error) => {
            // Swal.fire(
            //     'Error del servidor',
            //     'Inténtalo de nuevo en unos momentos.',
            //     'error'
            // )
            console.log(error)
        })
    }

    function getPopulars() {
        getMarketplaceMaterialPopular().then(response => {
            let _respuesta = JSON.parse(response);
            console.log(_respuesta);
            if (_respuesta.response === "error") {
                Swal.fire(
                    'Error al cargar productos populares',
                    'Inténtalo de nuevo recargando la página.',
                    'warning'
                )
            } else {
                serPopulars(_respuesta.populares);
            }

        }).catch((error) => {
            // Swal.fire(
            //     'Error del servidor',
            //     'Inténtalo de nuevo en unos momentos.',
            //     'error'
            // )
            console.log(error)
        })
    }

    const state = {
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 4,
            },
            1200: {
                items: 5,
            },
        },
    }

    function backPage() {
        if (paginaActual < 1) {
            setPage(paginaActual - 1);
            console.log(paginaActual);
        }
        console.log(paginaActual);
    }

    function nextPage() {
        console.log(paginaActual);
        if (paginaActual < tiendasAll.length) {
            setPage(paginaActual + 1);
        }
    }


    return (
        <>
            {tiendasAll.length === 0 && populares.length === 0 ?

                <div className="circularBarProgressIndicator">
                    <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem" />
                </div> :
                <div className="marketplaceMateriales">

                    <div className="marketplaceMateriales-gradient container-fluid">

                        <div className="container">
                            <p className="bebas marketplaceMateriales-titulo">MATERIALES</p>
                            <p className="marketplaceMateriales-subtitulo mb-2">Consigue lo necesario para practicar tus cursos</p>
                        </div>

                    </div>

                    <div className="container">

                        {/* <div className="marketplaceMateriales-tiendas mt-5">
                            <div className="d-flex justify-content-between mt-2">
                                <b className="mx-3">Tienda</b>
                                <b className="mx-3">Productos</b>
                            </div>
                            <hr />
                            {tiendasAll.length >= 1 &&
                                <OwlCarousel className="owl-theme"
                                    items="1"
                                    margin={10}
                                    autoplay={false}
                                    loop={false}
                                    nav={true}
                                    dots={false}
                                    touchDrag={true}
                                    animateIn={true}
                                    center={false}

                                    //NO CAMBIAR A className, SI SE CAMBIA NO SALEN LAS FLECHAS DE NAVEGACIÓN PARA LAS PÁGINAS

                                    //IMPORTANTEEEEEEEEEEEEEE
                                    navText={[
                                        `<div onClick=${backPage} className="marketplaceMateriales_arrowIcon1"><i className="fa fa-angle-left"></i></div>`,
                                        `<div onClick=${nextPage} className="marketplaceMateriales_arrowIcon2"><i className="fa fa-angle-right"></i></div>`,
                                    ]}
                                >

                                    {tiendasAll.map(shops => (
                                        <div className="item marketplaceMateriales_card" key={shops.imagen}>

                                            {shops.map(shop => (
                                                <div>

                                                    <div className="d-flex justify-content-between">

                                                        <div className="d-flex align-items-center">
                                                            <img src={shop.imagen} alt="imagenTienda" />
                                                            <span className="marketplaceMateriales_card-tienda">{shop.tienda}</span>
                                                        </div>

                                                        <div>
                                                            <span className="marketplaceMateriales_card-cantidad">{shop.productos} <i className="fa fa-angle-right"></i></span>
                                                        </div>

                                                    </div>

                                                    <hr />

                                                </div>
                                            ))}

                                        </div>
                                    ))}

                                </OwlCarousel>
                            }

                            <div className="text-center pb-3"><p>Página {paginaActual} de {tiendasAll.length}</p></div>
                        </div> */}


                        <p className="bebas mt-5 mb-2 titulos">POPULARES</p>

                        {populares.length >= 1 &&

                            <OwlCarousel className="owl-theme"
                                autoplay={false}
                                loop={true}
                                dots={true}
                                touchDrag={true}
                                animateIn={true}
                                responsive={state.responsive}
                                center={false}
                                stagePadding={30}
                                margin={10}
                            >

                                {populares.map(mat => (
                                    <div className="item">
                                        <ResultadosItem
                                            key={mat.idProducto}
                                            clase={'verdeColor'}
                                            tipo={'MATERIAL'}
                                            url={mat.imagen}
                                            nombre={mat.nombreProducto}
                                            // cripto={24.891}
                                            fiat={mat.precio}
                                            divisa={mat.divisa}
                                            urlPage={mat.urlPage}
                                            type="material"
                                        />
                                    </div>
                                ))}

                            </OwlCarousel>

                        }

                        <hr />

                        {/* <MetodosPago /> */}

                        <Denthereum />

                    </div>

                </div>
            }
        </>

    );
}

export { MarketplaceMateriales };