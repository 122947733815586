import React from 'react';

export default function ElementoBeneficios({ icono, descripcion }){
    return(
        <div className="beneficios__elemento">
            <span className="beneficios__icono">
                { icono }
            </span>
            <span className="beneficios__nombre">
                { descripcion }
            </span>
        </div>
    )
}