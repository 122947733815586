import React from 'react';
import { useState } from 'react';

import Modal from 'react-bootstrap/Modal'

import recoveryPass from "./../../services/recoveryPass.js";
import Swal from 'sweetalert2'
import $ from 'jquery';

import './RecuperarPassModal.css';

function RecuperarPassModal(){

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const recuperarPass = (e) => {

        e.preventDefault();

        Swal.showLoading();

        let values = new FormData();

        let correo = $("#inputRecuperarPass").val();

        values.append("correo", correo);

        let objectValues = Object.fromEntries(values);

        recoveryPass({values:objectValues}).then((response) => {

            Swal.fire({
                title: "Listo!",
                text: 'Revisa tu correo para ver las instrucciones que te enviamos',
                icon: "success"
            }).then(function() {
                handleClose();
            });
            
        }).catch((error) => {
            // Swal.fire(
            //     'Error del servidor',
            //     'Inténtalo de nuevo en unos momentos.',
            //     'error'
            // )
        })
    }

    return (
        <div>

            <p className="modalInicioSesion_body-password my-3" onClick={handleShow}>¿Olvidaste tu contraseña?</p>

            <Modal show={show} onHide={handleClose}>

                <Modal.Header className="modalRecuperarPass_header titulo bebas" closeButton>

                    <p className="bebas modalRecuperarPass_header-titulo">RESTABLECER CONTRASEÑA</p>

                </Modal.Header>

                <Modal.Body className="modalRecuperarPass_body">
                    
                    <div className='modalRecuperarPass_body-form p-3'>

                        <p className="text-center">Te enviaremos un email con un enlace para que puedas restablecer tu contraseña.</p>

                        <form id="recuperarPassForm" onSubmit={recuperarPass}>

                            <div className="form-group my-3">
                                <input id="inputRecuperarPass" type="email" className="form-control" aria-describedby="email" placeholder="Email" required/>
                            </div>

                            <button type="submit" className="btn btn-light btn-lg w-100 py-2 modalRecuperarPass_body-botonCompra my-2">Restablecer contraseña</button>

                        </form>

                    </div>

                </Modal.Body>

                <Modal.Footer className="modalRecuperarPass_footer justify-content-center">

                    <div className="text-center">
                        <p>¿Ya recordaste tu contraseña? <span className="modalRecuperarPass_body-terminos">Inicia sesión aquí</span></p>
                    </div>
                </Modal.Footer>

            </Modal>

        </div>
    );
}

export {RecuperarPassModal};