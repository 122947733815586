import { config } from 'dotenv'

config()

export default {
    
    // urlApi: 'https://api.kinesiuz.com/',
    urlApi: process.env.REACT_APP_URL_API,
    // urlWeb: "https://cursodontic-web3.vercel.app/"
    urlWeb: process.env.REACT_APP_URL_WEB
};