import React from 'react'
import { useHistory } from 'react-router-dom';
export default function BuscadorHeader(){
    const history = useHistory()

    const realizarBusqueda = (e) => {

        e.preventDefault();
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values)
        const busqueda = objectValues.q;

        if(busqueda.trim().length > 0){
            history.push(`/resultados/${busqueda}`)
        }
    }

    return(
        <form  onSubmit={ realizarBusqueda } className="cabezoteInfo__buscador col-md-0 col-sm-0 col-xs-0">
            <i className="fa-solid fa-magnifying-glass buscador__icono"></i>
            <input id="busquedaHeader" type="search" name="q" className="buscador__input" placeholder="Buscar en Cursodontic" autoComplete="off" />
        </form>
    )
}