import React from 'react';

import './DescripcionMaterial.css';

function DescripcionMaterial({descripcionProducto}) {
    let parrafos = descripcionProducto.toString().split('. ');
    // console.log(descripcionProducto.toString().split('. '));
    return(
        <div className="infoMaterialDescripcion">

            <p className="bebas pt-4 titulos mb-3">DESCRIPCIÓN</p>

            <p>{descripcionProducto}</p>
            {/* {parrafos.map (parrafo =>
                <p>{parrafo}.</p>
            )} */}

        </div>

    );
}

export {DescripcionMaterial};