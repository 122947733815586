import loginWalletService from './../services/loginWalletService'

export default function useLoginWallet({account, handleLogin}){

    let idUsuario = localStorage.getItem('idUsuario')
    let data = {}
    if(idUsuario !== null && idUsuario !== undefined)
        data = { account, idUsuario };
    else 
        data = { account}

    let respuesta = true;
    loginWalletService({values:data})
        .then((response)=>{
            // console.log(response)
            let jsonResponse = JSON.parse(response)
            localStorage.setItem('previouslyConnected','true')
            handleLogin(jsonResponse)
        }).catch((err)=>console.log(err))
    
    return respuesta;
}