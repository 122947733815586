import React from 'react'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { NoEthereumProviderError } from '@web3-react/injected-connector'
import { UserRejectedRequestError } from '@web3-react/injected-connector'
import './ButtonConnectWallet.css'
import useLoginWallet from './../../web3/useLoginWallet'

import { connector } from './../../web3/config'

export default function ButtonConnectWallet(props){
    const { activate, deactivate, active, account, error } = useWeb3React()

    const isUnsupportedChain = error instanceof UnsupportedChainIdError;
    const isNoEthereumProviderError = error instanceof NoEthereumProviderError;
    const isUserRejectedRequestError = error instanceof UserRejectedRequestError;

    const [verifyConnect, setVerifyConnect] = React.useState(false)

    const connect = ()=>{
        Swal.showLoading()
        activate(connector)
        .then(()=>{
                setVerifyConnect(true)
            })    
            .catch(err=>console.log({err}))   
    }

    const handleLoginWallet = () => {

        if(isUserRejectedRequestError){
            Swal.fire({
                icon: "info",
                title: "Permisos rechazados",
                text: "Puedes intentarlo de nuevo para conectarte",
                showConfirmButton: false,
                timer: 2000,
            });
            deactivate()
            return
        }
        if(isUnsupportedChain){
            Swal.fire({
                icon: "info",
                title: "Blockchain no soportada",
                text: "Para utilizar Denthereum Token cambia a Binance Smart Chain",
            });
            deactivate()
            return
        }
        if(isNoEthereumProviderError){
            Swal.fire({
                icon: "info",
                title: "No tienes un proveedor de wallet",
                text: "Te recomendamos instalar Metamask o Brave",
            });
            deactivate()
            return
        }

        console.log(account, active)
        if(!isUnsupportedChain && !isNoEthereumProviderError && !isUserRejectedRequestError){
            if(account){
                useLoginWallet({account: account, handleLogin: props.handleLogin})
                // if(!respuesta)
                //     deactivate()
            } else {
                deactivate()
                // Swal.close()
                setVerifyConnect(false)
                // Swal.fire({
                //     icon: "info",
                //     // title: "Permite el acceso a tu wallet",
                //     text: "Permite el acceso a tu wallet",
                // });
            }
            setVerifyConnect(false)
        }

    }

    React.useEffect(()=>{
        if(verifyConnect){
            handleLoginWallet()
        }
    },[verifyConnect])


    return(
        <button 
            type="button" 
            className="btn btn-light w-100 modalInicioSesion_body-meta mt-2 shadow-sm" 
            onClick={connect}
            disabled={isUnsupportedChain}
        >
            <FontAwesomeIcon className='modalInicioSesion_body-icono' icon={["fas", "fa-wallet"]} />
            <span>
                {isUnsupportedChain ? "Red blockchain no soportada" : "Conectar wallet"}
            </span>
        </button>
    )
}